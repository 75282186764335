import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@mui/material";

const useLedgerUploadListing = () => {
  const { RunAPI } = UseRunAPI();
  const { ws_scenarios, currentItem } = useSelector((state) => state);
  const { version } = currentItem;
  const [data, setData] = useState([{}]);
  const [updateIds, setUpdateIds] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const dispatch = useDispatch();

  const [columns, setColumns] = useState([
    { title: "Entity", field: "entity_desc" },
    { title: "Description", field: "jv_desc" },
    { title: "Currency", field: "currency_code" },
    { title: "jv_id", field: "jv_id" },
    { title: "Month", field: "month" },
    { title: "User", field: "user_name" },
    { title: "Date", field: "day" },
  ]);

  const showTime = (timestamp) => {
    //   const timestamp = '2023-04-17T20:25:30.000Z';
    const now = new Date();
    const date = new Date(timestamp);
    const diff = now - date;
    const diffInDays = Math.round(diff / (1000 * 60 * 60 * 24));
    let newDate = "";

    if (diffInDays < 1) {
      const diffInHours = Math.round(diff / (1000 * 60 * 60));
      const diffInMinutes = Math.round(diff / (1000 * 60));
      //
      if (diffInHours >= 1) {
        //   console.log(`${diffInHours} hours ago`);
        newDate = `${diffInHours} hours ago`;
      } else {
        //   console.log(`${diffInMinutes} minutes ago`);
        newDate = `${diffInMinutes} minutes ago`;
      }
    } else if (diffInDays <= 3) {
      const options = { day: "numeric", month: "short" };
      //  console.log(
      //   `${diffInDays} days ago (${date.toLocaleDateString("en-US", options)})`
      // );
      newDate = `${diffInDays} days ago`;
    } else {
      const options = { day: "numeric", month: "short", year: "numeric" };
      // console.log(date.toLocaleDateString("en-US", options));
      newDate = date.toLocaleDateString("en-US", options);
    }
    return newDate;
  };

  const getData = async () => {
    try {
      let json = {
        area: "ledger",
        action: "get_ledger_parents",
      };

      const awsURL = process.env.REACT_APP_URL_LOAD;
      const response = await RunAPI(json, awsURL);

      console.log("RESPONSE:", response);
      let ndata = response["data"];

      let filter = `scenario_flag_${version}`;
      console.log("FILTER:", filter);

      setSelectionModel(
        ndata
          .filter((fitem) => fitem[filter] === 1)
          .map((row) => row["jv_id_period"])
      );
      console.log("SELECTION MODEL:", selectionModel);

      ndata.map((row) => {
        // row["day"] = row["last_update"].substring(0, 10);
        //row["time"] = row["last_update"].substring(11, 20);
        row["day"] = showTime(row["last_update"]);
        row["jv_type"] = "Miscellaneous";
        return row;
      });

      setData(ndata);
    } catch (error) {
      console.error(error);
    }
  };

  const loadCube = async () => {
    try {
      let json = {
        area: "ledger",
        action: "Load_cube",
        jv_id_periods: updateIds,
      };

      const awsURL = process.env.REACT_APP_URL_LOAD;
      const response = await RunAPI(json, awsURL);

      console.log("RESPONSE:", response);
      let ndata = response["data"];

      ndata.map((row) => {
        row["day"] = row["last_update"].substring(0, 10);
        row["time"] = row["last_update"].substring(11, 20);
        row["scenario_flag_0"] = <Checkbox checked={row["scenario_flag_0"]} />;
        row["scenario_flag_1"] = <Checkbox checked={row["scenario_flag_1"]} />;
        row["scenario_flag_2"] = <Checkbox checked={row["scenario_flag_2"]} />;
        row["scenario_flag_3"] = <Checkbox checked={row["scenario_flag_3"]} />;
        row["scenario_flag_4"] = <Checkbox checked={row["scenario_flag_4"]} />;
        row["scenario_flag_5"] = <Checkbox checked={row["scenario_flag_5"]} />;
        row["scenario_flag_6"] = <Checkbox checked={row["scenario_flag_6"]} />;
        row["scenario_flag_7"] = <Checkbox checked={row["scenario_flag_7"]} />;
        row["jv_type"] = "Miscellaneous";
        return row;
      });

      setData(ndata);
    } catch (error) {
      console.error(error);
    }
  };

  const updateEntity = async (entity) => {
    dispatch({
      type: "SET_CURRENT_ENTITIES",
      payload: [entity],
    });
  };

  const updateVersion = async (val) => {
    dispatch({
      type: "SET_CURRENT_VERSION",
      payload: val,
    });
  };

  return {
    getData,
    data,
    columns,
    ws_scenarios,
    updateIds,
    setUpdateIds,
    updateEntity,
    currentItem,
    updateVersion,
    loadCube,
    selectionModel,
    setSelectionModel,
  };
};

export default useLedgerUploadListing;
