import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";

//import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Typography from "@mui/material/Typography";
//import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Items = ({ item, handleStaticCheckChange, index }) => {
  const handleCheck = (e, id) => {
    handleStaticCheckChange(e, id);
  };

  return (
    <>
      <ListItem
        sx={{
          rm: 20,
          width: 280,
          height: 37,
        }}
      >
        <Checkbox
          checked={item.picked}
          onChange={(e) => handleCheck(e, item.id)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography variant="body2" color="text.secondary">
          {item.value}
        </Typography>
      </ListItem>
    </>
  );
};

const ColumPicker = ({ handleStaticCheckChange, items }) => {
  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: "240px",
          border: "solid 1px #e0e0e0",
          bgcolor: "background.paper",
        }}
      >
        {items.map((item, index) => (
          <Items
            key={item.key + "static"}
            item={item}
            index={index}
            handleStaticCheckChange={handleStaticCheckChange}
          />
        ))}
      </List>
    </>
  );
};

export default ColumPicker;
