import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Card,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSelector } from "react-redux";
import useByAccountView from "./useByAccountView";
import ExportButton from "src/components/saveAsCsv/ExportButton";
import FilterHeader from "src/components/filterHeader";
//import useWindowSize from "./useWindowSize";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #dadde9",
  },
}));

const formats = {
  1: {
    marginLeft: "0px",
    fontWeight: "400",
    fontSize: "12px",
  },
  2: {
    marginLeft: "0px",
    fontWeight: "400",
    fontSize: "12px",
  },
  3: {
    marginLeft: "0px",
    fontWeight: "400",
    fontSize: "12px",
  },
  4: {
    marginLeft: "0px",
    fontWeight: "400",
    fontSize: "12px",
  },
  5: {
    marginLeft: "0px",
    fontWeight: "400",
    fontSize: "12px",
  },
  6: {
    marginLeft: "0px",
    fontWeight: "400",
    fontSize: "12px",
  },
  7: {
    marginLeft: "30px",
    fontWeight: "600",
    fontSize: "13px",
  },
  8: {
    marginLeft: "0px",
    fontWeight: "500",
    fontSize: "12px",
  },
  9: {
    marginLeft: "0px",
    fontWeight: "500",
    fontSize: "12px",
  },
  10: {
    marginLeft: "0px",
    fontWeight: "500",
    fontSize: "12px",
  },
  11: {
    marginLeft: "20px",
    fontWeight: "600",
    fontSize: "13px",
  },
  12: {
    marginLeft: "30px",
    fontWeight: "600",
    fontSize: "13px",
  },
  99: {
    marginLeft: "0px",
    fontWeight: "800",
    fontSize: "13px",
    borderLeft: "1px solid #dadde9",
  },
};

const DisplayCell = ({ row, col, thousands }) => {
  const { nbrFormat } = useByAccountView();

  if (col === "Account") {
    return <div style={formats[row["act_type"]]}>{row[col]}</div>;
  } else {
    return (
      <div style={formats[row["act_type"]]}>
        {nbrFormat(row, col, thousands)}
      </div>
    );
  }
};

const ByAccountTable = ({ openMe, setOpenMe }) => {
  const { currentItem } = useSelector((state) => state);
  const { data, getData, columns, handleCellClick } = useByAccountView();

  useEffect(() => {
    console.log("ByAccountTable useEffect");
    getData();
  }, [
    currentItem.versions,
    currentItem.entities,
    currentItem.beg,
    currentItem.end,
    currentItem.accounts,
    currentItem.columns,
    currentItem.missing,
  ]);

  return (
    <div>
      <FilterHeader showMissing={true} showThousand={false} />
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <ExportButton
            title={"By Account Report"}
            data={data}
            refresh={getData}
            showFirstRow={false}
          />
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ height: "100%", pb: 2 }}>
            <TableContainer
              sx={{ border: "solid 1 black", height: "800px" }}
              component={Paper}
            >
              <PerfectScrollbar>
                <Table
                  aria-label="simple table"
                  size="small"
                  style={{ paddingBottom: "30px" }}
                >
                  <TableBody>
                    {data.map((row, ind) => (
                      <TableRow
                        key={ind + "hh"}
                        hover={true}
                        selected={row.selected}
                        sx={
                          row.act_type === 99
                            ? {
                                position: "sticky",
                                top: 0,
                                borderBottom: "solid 2px",
                                borderCollapse: "black",
                                backgroundColor: "#f5f5f9",
                              }
                            : {}
                        }
                      >
                        {columns
                          .filter(
                            (citm) =>
                              ![
                                "act_id",
                                "nbr_format",
                                "act_type",
                                "check",
                              ].includes(citm)
                          )
                          .map((col, i) => (
                            <TableCell
                              key={i + "ppp" + ind}
                              onDoubleClick={() => {
                                handleCellClick(row, col);
                              }}
                              style={
                                i === 0
                                  ? {
                                      position: "sticky",
                                      left: 0,
                                      minWidth: "240px",
                                      backgroundColor: "#f5f5f9",
                                    }
                                  : {
                                      textAlign: "right",
                                    }
                              }
                            >
                              <DisplayCell
                                row={row}
                                col={col}
                                thousands={currentItem.thousands}
                              />
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div style={{ height: "30px" }}>{""}</div>
              </PerfectScrollbar>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ByAccountTable;
