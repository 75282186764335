import { Container, Grid } from "@mui/material";
import WorkspaceCard from "./WorkspaceCard";
import UserDataLogic from "src/components/cognito/UserDataLogic";
import { useSelector } from "react-redux";

const WorkSpacesView = () => {
  const { getUserAndWsData } = UserDataLogic();
  const { workspaces, user, currentItem } = useSelector((state) => state);
  const { isAuthenticated } = user;

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} style={{ marginTop: "25px" }}>
        {workspaces.length > 0
          ? workspaces.map((itm) => (
              <Grid item lg={3} md={3} xs={12} key={itm.ws_id + "grid"}>
                <WorkspaceCard
                  getUserAndWsData={getUserAndWsData}
                  key={itm.ws_id}
                  item={itm}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </Container>
  );
};

export default WorkSpacesView;
