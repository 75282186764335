import {
  Button,
  Typography,
  List,
  ListItem,
  Tab,
  Tabs,
  Box,
  MenuItem,
  FormControl,
  Select,
  LinearProgress,
  TextField,
} from "@mui/material";
import ExportButton from "src/components/saveAsCsv/ExportButton";

import { useRef, useState } from "react";

import SimpleTable from "src/components/tables/simpleTable/SimpleTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BottomButtons = ({ step, setStep }) => {
  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          p: 2,
        }}
      >
        <Button
          onClick={() => {
            setStep(step - 1);
          }}
          variant="contained"
          color="primary"
          sx={{ height: 40 }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setStep(step + 1);
          }}
          variant="contained"
          color="primary"
          sx={{ ml: 3, height: 40 }}
        >
          Continue
        </Button>
      </Box>
    </div>
  );
};

const LoadForm = (props) => {
  const {
    titles,
    updateColumns,
    loadColumns,
    columns,
    rows,
    makeLoadData,
    returnData,
    vloadColumns,
    setStep,
    step,
    selectSheet,
    loading,
    sheetName,
    showFile,
    journalTypes,
    currentJournalType,
    setCurrentJournalType,
    workbook,
    currencyTypes,
    currentCurrencyType,
    setCurrentCurrencyType,
    currentEntity,
    setCurrentEntity,
    ws_entities,
    journalName,
    setJournalName,
    journalDesc,
    setJournalDesc,
  } = props;

  const [value, setValue] = useState(false);

  const inputEl = useRef(null);

  const uploadMe = () => {
    inputEl.current.click();
  };

  const handleChange = (event) => {
    selectSheet(event.target.value);
  };

  const handleColumnChange = (event, i) => {
    updateColumns(event.target.value, i);
  };

  const handleChangeSegment = (event, i) => {
    setCurrentJournalType(event.target.value);
  };

  const handleChangeCurrency = (event, i) => {
    setCurrentCurrencyType(event.target.value);
  };

  const handleChangeEntity = (event, i) => {
    setCurrentEntity(event.target.value);
  };

  const handleSubmitData = () => {
    setStep(step + 1);
    makeLoadData("validate");
  };

  const handleSubmitDataLoad = () => {
    setStep(step + 1);
    makeLoadData("update");
  };

  const dataCallback = (data) => {
    console.log(data);
  };

  const handleChangeTabs = () => {
    setValue(!value);
  };

  return (
    <div>
      <Box sx={{ width: "100%", bgc: "grey" }}>
        {loading && <LinearProgress />}
      </Box>
      <div>
        <Box
          sx={{
            width: "100%",
            p: 1,
            borderWidth: "1px",
            borderColor: "primary.dark",
            borderStyle: "solid",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {titles[0]}
          </Typography>

          <Box sx={{ minWidth: 120, width: 300, margin: "10px" }}>
            <FormControl fullWidth>
              <Select
                disabled={false}
                id="uyxx6"
                value={currentEntity}
                onChange={handleChangeEntity}
                size="small"
              >
                {ws_entities.map((item, index) => (
                  <MenuItem key={"jssnt" + index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, width: 300, margin: "10px" }}>
            <FormControl fullWidth>
              <Select
                disabled={false}
                id="uy6"
                value={currentJournalType}
                onChange={handleChangeSegment}
                size="small"
              >
                {journalTypes.map((item, index) => (
                  <MenuItem key={"jnt" + index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, width: 300, margin: "10px" }}>
            <FormControl fullWidth>
              <Select
                disabled={false}
                id="uycurr6"
                value={currentCurrencyType}
                onChange={handleChangeCurrency}
                size="small"
              >
                {currencyTypes.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, width: 300, margin: "10px" }}>
            <FormControl fullWidth>
              <TextField
                width="100%"
                id="standard-multiline-static"
                label="Journal Name"
                rows={4}
                defaultValue={journalName}
                onChange={(e) => {
                  setJournalName(e.target.value);
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, width: 300, margin: "10px" }}>
            <FormControl fullWidth>
              <TextField
                width="100%"
                id="standard-multiline-static"
                label="Journal Description"
                multiline
                rows={4}
                defaultValue={journalDesc}
                onChange={(e) => {
                  setJournalDesc(e.target.value);
                }}
              />
            </FormControl>
          </Box>

          <BottomButtons step={step} setStep={setStep} />
        </Box>
      </div>
      <div>
        <Box
          sx={{
            width: "100%",
            p: 1,
            borderWidth: "1px",
            borderColor: "primary.dark",
            borderStyle: "solid",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {titles[1]}
          </Typography>

          <input
            id="gggg"
            type="file"
            onChange={(e) => showFile(e)}
            ref={inputEl}
            style={{ display: "none", margin: "10px" }}
          />

          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => {
              uploadMe();
            }}
            sx={{ m: 4 }}
          >
            Upload File
          </Button>

          <Box sx={{ minWidth: 120, width: 300, margin: "10px" }}>
            <FormControl fullWidth>
              <Select
                disabled={false}
                id="uy6"
                value={sheetName}
                onChange={handleChange}
                size="small"
              >
                {workbook["SheetNames"] &&
                  workbook["SheetNames"].map((item, index) => (
                    <MenuItem key={"gsdgs" + index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <BottomButtons step={step} setStep={setStep} />
        </Box>
      </div>

      <div>
        <Box
          sx={{
            width: "100%",
            p: 1,
            borderWidth: "1px",
            borderColor: "primary.dark",
            borderStyle: "solid",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {titles[2]}
          </Typography>
          <Typography>Load Fields</Typography>
          <List dense={true}>
            {loadColumns.map((itm, loadIndex) => (
              <ListItem key={loadIndex + "lcs"}>
                <Box sx={{ width: 300, mr: 2, ml: 2 }}>
                  <Typography>{itm.title}</Typography>
                </Box>

                <Select
                  sx={{ width: 300 }}
                  key={itm.field + "9sd"}
                  onChange={(e) => handleColumnChange(e, loadIndex)}
                  value={loadColumns[loadIndex].field}
                  size="small"
                >
                  <MenuItem key="s1df34" value={""}>
                    <em>None</em>
                  </MenuItem>
                  {columns.map((item, index) => (
                    <MenuItem key={itm.field + index} value={item.field}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>

                <Box sx={{ width: 200, mr: 2, ml: 2 }}>
                  <Typography
                    sx={
                      loadColumns[loadIndex].field.length > 0
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {itm.required ? <CheckCircleIcon /> : null}
                  </Typography>
                </Box>
                <Box sx={{ width: 300, mr: 2, ml: 2 }}>
                  <Typography></Typography>
                </Box>
              </ListItem>
            ))}
          </List>
          <BottomButtons step={step} setStep={setStep} />
        </Box>
      </div>

      <div>
        <Box
          sx={{
            width: "100%",
            p: 1,
            borderWidth: "1px",
            borderColor: "primary.dark",
            borderStyle: "solid",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {titles[3]}
          </Typography>
          ROWS
          <Box>
            <SimpleTable
              title="Returned Data"
              rows={rows.filter((item, i) => i < 100)}
              columns={loadColumns}
              dataCallback={dataCallback}
            />
          </Box>
          <BottomButtons step={step} setStep={setStep} />
        </Box>
      </div>

      <div>
        <Box
          sx={{
            width: "100%",
            p: 1,
            borderWidth: "1px",
            borderColor: "primary.dark",
            borderStyle: "solid",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {titles[4]}
          </Typography>
          {loading && <LinearProgress />}
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => {
              handleSubmitData();
            }}
            sx={{ m: 4 }}
          >
            Validated Data
          </Button>
          <BottomButtons step={step} setStep={setStep} />
        </Box>
      </div>

      <div>
        <Box
          sx={{
            width: "100%",
            p: 1,
            borderWidth: "1px",
            borderColor: "primary.dark",
            borderStyle: "solid",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {titles[5]}
          </Typography>
          <Tabs
            value={value}
            onChange={handleChangeTabs}
            aria-label="basic tabs example"
          >
            <Tab label="All Data" />
            <Tab label="Errors Only" />
          </Tabs>
          <Box>
            ff
            <SimpleTable
              title="Upload Preview"
              rows={returnData}
              columns={vloadColumns}
              dataCallback={dataCallback}
            />
          </Box>
          <Box>
            <Box>
              <ExportButton
                title={"Error"}
                data={returnData.filter(
                  (item) => item.MSG_OVERALL !== "SUCCESS"
                )}
                refresh={() => console.log("refresh")}
                showFirstRow={true}
              />
              <SimpleTable
                title="Errors Only"
                rows={returnData.filter(
                  (item) => item.MSG_OVERALL !== "SUCCESS"
                )}
                columns={vloadColumns}
                dataCallback={dataCallback}
              />
            </Box>
          </Box>
        </Box>
      </div>

      <div>
        <Box
          sx={{
            width: "100%",
            p: 1,
            borderWidth: "1px",
            borderColor: "primary.dark",
            borderStyle: "solid",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {titles[6]}
          </Typography>
          {loading && <LinearProgress />}

          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => {
              handleSubmitDataLoad();
            }}
            sx={{ m: 4 }}
          >
            Upload Data
          </Button>

          <BottomButtons step={step} setStep={setStep} />
        </Box>
      </div>
    </div>
  );
};

export default LoadForm;
