import { Chip, Typography, Card, CardContent, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const Index = ({ multiple = true }) => {
  const { currentItem, ws_entities } = useSelector((state) => state);

  const dispatch = useDispatch();

  const updateVersions = async (val) => {
    if (multiple) {
      let entities = currentItem.entities;

      if (currentItem.entities.includes(val)) {
        if (entities.length > 0) {
          entities.splice(entities.indexOf(val), 1);
          // console.log('REMOVE ITEM');
          dispatch({
            type: "SET_CURRENT_ENTITIES",
            payload: [...entities],
          });
        }
      } else {
        //versions.push(val.vers_id);
        dispatch({
          type: "SET_CURRENT_ENTITIES",
          payload: [...entities, val],
        });
      }
    } else {
      dispatch({
        type: "SET_CURRENT_ENTITY",
        payload: val,
      });
    }
  };

  const deleteEntity = async (val) => {
    let entities = currentItem.entities;
    if (currentItem.entities.includes(val)) {
      if (entities.length > 1) {
        entities.splice(entities.indexOf(val), 1);
        dispatch({
          type: "SET_CURRENT_ENTITIES",
          payload: [...entities],
        });
      }
    }
  };

  return (
    <Card raised={true} sx={{ borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h6" id="tableTitle" sx={{ mr: 2 }} component="div">
          Entities
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ flexWrap: "wrap", gap: 2 }}
          spacing={2}
        >
          {multiple &&
            ws_entities.map((value, index) => {
              return (
                <Chip
                  sx={{ mr: 1, mb: 1 }}
                  size="medium"
                  key={value.id}
                  label={value.name}
                  id={value.id}
                  clickable
                  variant={
                    currentItem.entities.includes(value.id)
                      ? "default"
                      : "outlined"
                  }
                  color="primary"
                  onDelete={() => deleteEntity(value.id)}
                  onClick={() => updateVersions(value.id)}
                />
              );
            })}
          {!multiple &&
            ws_entities.map((value, index) => {
              return (
                <Chip
                  sx={{ mr: 1, mb: 1 }}
                  size="medium"
                  key={value.id}
                  label={value.name}
                  id={value.id}
                  clickable
                  variant={
                    currentItem.entity === value.id ? "default" : "outlined"
                  }
                  color="primary"
                  onClick={() => updateVersions(value.id)}
                />
              );
            })}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Index;
