import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";

const Layout2 = (props) => {
  const { tabLink, title, level } = props;

  return (
    <div>
      <TopBar tabLink={tabLink} title={title} level={level} />
      <Outlet />
    </div>
  );
};

export default Layout2;
