import { Card, CardHeader } from "@mui/material/";
import { useState } from "react";

import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate } from "react-router-dom";
import SimpleTable from "src/components/tables/simpleTable/SimpleTable";
import useReports from "src/views/reporting/useReports";

const Index = () => {
  const navigate = useNavigate();
  const [columns, setColumns] = useState([
    {
      field: "rep_name",
      numeric: false,
      disablePadding: true,
      title: "Report Name",
      searchable: true,
    },

    {
      field: "update_user",
      numeric: false,
      disablePadding: true,
      title: "Report Name",
      searchable: true,
    },
    {
      field: "report_type",
      numeric: false,
      disablePadding: true,
      title: "Report type",
      searchable: true,
    },
    {
      field: "update_user",
      numeric: false,
      disablePadding: true,
      title: "Owner",
      searchable: true,
    },
  ]);

  const { sharedReports, reportPick } = useReports(navigate);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const dataCallback = (col, row) => {
    console.log("dataCallback", col, row);
    reportPick(row);
  };

  return (
    <PerfectScrollbar>
      <div>
        <Card>
          <CardHeader
            title="Shared Reports"
            sx={{
              marginTop: "1px",
              paddingVertical: "4px",
            }}
          />
          {/* <div>{JSON.stringify(sharedReports)}</div> */}
          <SimpleTable
            title=""
            rows={sharedReports}
            columns={columns}
            dataCallback={dataCallback}
          />
        </Card>
      </div>
    </PerfectScrollbar>
  );
};

export default Index;
