import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const PeriodPicker = ({ type, title }) => {
  const { currentItem } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("SET_BEG_DATE");
  const [year, setYear] = useState("2021-01");
  const [month, setMonth] = useState("2021-12");

  useEffect(() => {
    if (type === "END") {
      setAction("SET_END_DATE");
      setYear(currentItem.end.slice(0, 4));
      setMonth(currentItem.end.slice(5, 7));
    } else {
      setAction("SET_BEG_DATE");
      setYear(currentItem.beg.slice(0, 4));
      setMonth(currentItem.beg.slice(5, 7));
    }
  }, [currentItem.end, currentItem.beg]);

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
    dispatch({
      type: action,
      payload: year + "-" + event.target.value,
    });
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
    dispatch({
      type: action,
      payload: event.target.value + "-" + month,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const yrs = [
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
  ];

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={handleClickOpen}
      >
        {year + "/" + month}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <FormControl>
            <InputLabel style={{ padding: "12px" }}>Year</InputLabel>
            <Select
              style={{ padding: "14px", marginRight: "10px" }}
              key={"y" + type}
              onChange={handleChangeYear}
              input={<Input id="demo-dialog-native" />}
              value={year}
            >
              {yrs.map((itm) => (
                <MenuItem key={itm} value={itm}>
                  {itm}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel style={{ padding: "12px" }}>Month</InputLabel>

            <Select
              style={{ padding: "14px" }}
              key={"m" + type}
              onChange={handleChangeMonth}
              value={month}
              input={<Input />}
              size="small"
            >
              {months.map((itm) => (
                <MenuItem key={itm} value={itm}>
                  {itm}
                </MenuItem>
              ))}{" "}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PeriodPicker;
