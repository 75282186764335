import { colors } from "@mui/material";

export const t0 = {
  palette: {
    mode: "light",
    primary: { main: "#2e7d32" },
    secondary: { main: "#2e7d32" },
  },
};
export const t1 = {
  palette: {
    mode: "light",
    primary: { main: "#212121" },
    secondary: { main: "#212121" },
  },
};

export const t2 = {
  palette: {
    mode: "light",
    primary: { main: "#212121" },
    secondary: { main: "#2e7d32" },
  },
};

export const t3 = {
  palette: {
    palette: {
      mode: "light",
      primary: { main: "#2e7d32" },
      secondary: { main: "#212121" },
    },
  },
};
