import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material/";

import { useDispatch, useSelector } from "react-redux";

import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate } from "react-router-dom";

const ReportListings = () => {
  const navigate = useNavigate();
  const { uploadList } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handlePick = async (item) => {
    try {
      let cc = {};
      cc["report_id"] = "";
      cc["report_name"] = "";
      cc["report_type"] = item.page_type;
      cc["report_dashboard"] = false;
      await dispatch({
        type: "POV_OPEN_UPLOAD",
        payload: cc,
      });
      //
      navigate("/workspace/update");
      //
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <PerfectScrollbar>
      <div>
        <Card>
          <CardHeader
            title="Upload Methods"
            style={{
              marginTop: "1px",
              paddingVertical: "4px",
            }}
          />
          <CardContent>
            <TableContainer>
              <Table stickyHeader aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {uploadList.map((row, i) => (
                    <TableRow
                      key={"keyAxA" + i}
                      hover={true}
                      onClick={() => handlePick(row)}
                    >
                      <TableCell>{row.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    </PerfectScrollbar>
  );
};

export default ReportListings;
