import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
import { useSelector, useDispatch } from "react-redux";

const noData = [
  {
    Account: "No Data",
    aaa0: "Total",
    act_id: "",
    act_type: "",
    formula: "COLUMN",
    indent: "3",
    sort: "",
  },
  {
    Account: "No Data",
    aaa0: 0.0,
    act_id: 2,
    act_type: 1,
    formula: "Direct Input",
    indent: 1,
    sort: 1,
    userFlag: true,
  },
];

const useByAccountView = () => {
  const [data, setData] = useState(noData);
  const [data_grp, setData_grp] = useState(noData);

  const { RunAPI } = UseRunAPI();
  const { currentItem, ws_scenarios, ws_dim } = useSelector((state) => state);
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();

  const getData_grp = async (obj) => {
    try {
      let json = {
        area: "dataout",
        action: "get_pivot_data",
        versions: obj.versions,
        dim1: obj.dim1,
        dim2: obj.dim2,
        dim3: obj.dim3,
        accounts: obj.accounts,
        columns: obj.columns,
        report_id: obj.report_id,
      };
      setData(noData);

      const awsURL = process.env.REACT_APP_URL_DATAOUT;
      const response = await RunAPI(json, awsURL);

      console.log("RESPONSE BY ACCOUNT:", response);

      let ndata = response["pivot_data"];

      if (currentItem.missing) {
        const toRemove = [
          "Account",
          "indent",
          "sort",
          "formula",
          "act_type",
          "act_id",
          "userFlag",
          "formula_code",
          "nbr_format",
        ];

        const myArray = Object.keys(response["pivot_data"][0]).filter(function (
          el
        ) {
          return !toRemove.includes(el);
        });

        console.log(myArray);
        ndata = ndata.map((item, i) => {
          let ee = 0;
          myArray.map((im) => (ee = ee + Math.abs(item[im])));
          //console.log('check No: ', item['Account'], ee);
          item.check = ee;
          return item;
        });

        ndata = response["pivot_data"].filter(
          // fitem => fitem['check'] !== 0 && fitem['aaa01'] !== null
          (fitem, i) => fitem["check"] > 0 || i < currentItem.columns.length + 1
        );
      }

      // setColumns(Object.keys(response["pivot_data"][0]));

      //  setData_grp(ndata);
      return ndata;
    } catch (error) {
      console.error(error);
      return { error: error };
    }
  };

  const getData = async () => {
    try {
      let json = {
        area: "dataout",
        action: "get_pivot_data",
      };
      setData(noData);

      const awsURL = process.env.REACT_APP_URL_DATAOUT;
      const response = await RunAPI(json, awsURL);

      console.log("RESPONSE BY ACCOUNT:", response);

      let ndata = response["pivot_data"];

      if (currentItem.missing) {
        const toRemove = [
          "Account",
          "indent",
          "sort",
          "formula",
          "act_type",
          "act_id",
          "userFlag",
          "formula_code",
          "nbr_format",
        ];

        const myArray = Object.keys(response["pivot_data"][0]).filter(function (
          el
        ) {
          return !toRemove.includes(el);
        });

        console.log(myArray);
        ndata = ndata.map((item, i) => {
          let ee = 0;
          myArray.map((im) => (ee = ee + Math.abs(item[im])));
          //console.log('check No: ', item['Account'], ee);
          item.check = ee;
          return item;
        });

        ndata = response["pivot_data"].filter(
          // fitem => fitem['check'] !== 0 && fitem['aaa01'] !== null
          (fitem, i) => fitem["check"] > 0 || i < currentItem.columns.length + 1
        );
      }

      setColumns(Object.keys(response["pivot_data"][0]));
      //setColumns(Object.keys(ndata[0]));

      setData(ndata);
    } catch (error) {
      console.error(error);
    }
  };

  const nbrFormat = (row, col, thousands) => {
    let nbr = row[col];
    try {
      if (thousands) {
        nbr = nbr / 1000;
        return nbr.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      } else {
        return nbr.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      }
    } catch (error) {
      return row[col];
    }
  };

  const getScenarioId = (scenarioName) => {
    console.log("getScenarioid", scenarioName);
    return ws_scenarios
      .filter((item) => item.name === scenarioName)
      .map((i) => i.id);
  };

  const handleCellClick = async (row, col) => {
    if (row.act_type !== 5) {
      return;
    }

    if (currentItem.missing) {
      return null;
    }

    console.log("ROW: ", row);
    console.log("COLUMN: ", col);
    console.log("COLUMNS : ", currentItem.columns);
    console.log("VALUE : ", row[col]);
    //

    // const setDim = (dim, value) => {};

    //let yy = ["month", "scenario", "dim1_code","dim2_code","dim3_code"   ];

    if (currentItem.columns.includes("month")) {
      try {
        let bb = currentItem.columns.indexOf("month");

        let cc = data[bb][col];
        console.log("MONTH: ", cc);
        if (cc) {
          dispatch({
            type: "SET_BEG_DATE",
            payload: cc,
          });
          dispatch({
            type: "SET_END_DATE",
            payload: cc,
          });
        }
      } catch (error) {}
    }

    if (currentItem.columns.includes("scenario")) {
      try {
        let bb = currentItem.columns.indexOf("scenario");

        let cc = data[bb][col];
        console.log("SCENARIO: ", cc);

        let ss = ws_scenarios.find((item) => item.name === cc).id;

        console.log("SCENARIO ID: ", ss);
        if (ss) {
          dispatch({
            type: "SET_CURRENT_VERSION",
            payload: ss,
          });
        }
      } catch (error) {}
    }

    if (currentItem.columns.includes("dim1_code")) {
      try {
        let bb = currentItem.columns.indexOf("dim1_code");
        let cc = data[bb][col];

        let dd = ws_dim[1].find((item) => item.dim_code === cc).id;
        console.log("DIM1: ", cc, dd);

        console.log("DIM1: ", dd);

        dispatch({
          type: "UPDATE_CURRENT_DIMENSION",
          payload: { dimNbr: 1, value: [dd] },
        });
      } catch (error) {}
    }

    if (currentItem.columns.includes("dim1_id")) {
      try {
        let bb = currentItem.columns.indexOf("dim1_id");

        let cc = data[bb][col];
        console.log("DIM1: ", cc);

        dispatch({
          type: "UPDATE_CURRENT_DIMENSION",
          payload: { dimNbr: 1, value: [cc] },
        });
      } catch (error) {}
    }

    if (currentItem.columns.includes("dim2_id")) {
      try {
        let bb = currentItem.columns.indexOf("dim2_id");

        let cc = data[bb][col];
        console.log("DIM2: ", cc);

        dispatch({
          type: "UPDATE_CURRENT_DIMENSION",
          payload: { dimNbr: 2, value: [cc] },
        });
      } catch (error) {}
    }

    if (currentItem.columns.includes("dim3_id")) {
      try {
        let bb = currentItem.columns.indexOf("dim3_id");

        let cc = data[bb][col];
        console.log("DIM3: ", cc);

        dispatch({
          type: "UPDATE_CURRENT_DIMENSION",
          payload: { dimNbr: 3, value: [cc] },
        });
      } catch (error) {}
    }

    dispatch(
      {
        type: "SET_CURRENT_ACCOUNTS",
        payload: [row.act_id],
      },
      []
    );

    let hh = currentItem.columns.map((item, i) => {
      try {
        return { value: data[i][col], column: item };
      } catch (error) {
        return { value: "", column: item };
      }
    });

    console.log("hh: ", hh);

    hh.forEach((item, i) => {
      if (item.column === "scenario") {
        hh[i].id = getScenarioId(item.value);
      }

      let cc = {};
      cc["report_id"] = "";
      cc["report_name"] = "";
      cc["report_type"] = 3;
      cc["report_dashboard"] = false;

      dispatch({
        type: "POV_OPEN_STD",
        payload: cc,
      });
    });

    console.log("hh: ", hh);

    console.log("ws_scenarios: ", ws_scenarios);
  };

  return {
    handleCellClick,
    getData,
    data,
    columns,
    nbrFormat,
    setData_grp,
    getData_grp,
  };
};

export default useByAccountView;
