import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import PeriodPicker from "./PeriodPicker";

const BegEndMonths = () => {
  return (
    <Card raised={true} sx={{ height: "100%", borderRadius: 2 }}>
      <CardContent sx={{ display: "inline-flex", pt: 4 }}>
        <Typography variant="h6" id="tableTitle" sx={{ mr: 2 }} component="div">
          Months
        </Typography>

        <PeriodPicker type={"BEG"} title={"START MONTH"} />
        <Typography
          style={{ marginLeft: "6px", marginRight: "6px" }}
          color="textSecondary"
          variant="h6"
        >
          {" - "}
        </Typography>
        <PeriodPicker type={"END"} title={"END MONTH"} />
      </CardContent>
    </Card>
  );
};

export default BegEndMonths;
