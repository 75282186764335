import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UseRunAPI from "src/components/UseRunAPI";

const UserDataLogic = () => {
  const { email } = useSelector((state) => state);
  const { RunAPI } = UseRunAPI();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const getUserAndWsData = async (ws, link) => {
    dispatch({
      type: "SET_CURRENT_WS_ID",
      payload: ws,
    });

    let json = {
      area: "get_user_workspace_settings",
      workSpaceId: ws,
      action: "get_data",
    };

    const awsURL = process.env.REACT_APP_URL_USERDATA;
    const response = await RunAPI(json, awsURL);

    console.log("WS_SETTINGS_UPDATE:", response);

    if (response.status === "success") {
      dispatch({
        type: "WS_SETTINGS_UPDATE",
        payload: response,
      });
    } else {
      console.log("ERROR", response);
      alert("Error getting user data");
    }

    console.log("USER_WS_SETTINGS:", response);

    if (link > "") {
      navigate(link);
    } else {
      navigate("/workspace/home");
    }
  };

  const getWsData = async () => {
    // get user workspaces
    try {
      let json = {
        area: "get_user_workspaces",
        action: "get_data",
      };
      const awsURL = process.env.REACT_APP_URL_USERDATA;
      const response = await RunAPI(json, awsURL);
      console.log("USER_WS:", response);

      dispatch({
        type: "WS_LIST_UPDATE",
        payload: response,
      });
      return response;
    } catch (error) {
      console.error(error);
      return { data: [] };
    }
  };

  const runMeCheck = async () => {
    let test = await getWsData();
    if (test["data"].length > 0) {
      navigate("/workspaces/spaces");
    } else {
      navigate("/login");
    }

    return;
  };

  return {
    getUserAndWsData,
    runMeCheck,
  };
};

export default UserDataLogic;
