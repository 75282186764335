import useUploadAccounts from "./useUploadAccounts";
import LoadForm from "./LoadForm";

const UploadAccounts = () => {
  const {
    updateColumns,
    loadColumns,
    columns,
    loading,
    sheetName,
    workbook,
    rows,
    makeLoadData,
    loadData,
    returnData,
    vloadColumns,
    step,
    setStep,
    showFile,
    selectSheet,
    titles,
  } = useUploadAccounts();

  return (
    <div>
      <LoadForm
        updateColumns={updateColumns}
        loadColumns={loadColumns}
        columns={columns}
        loading={loading}
        sheetName={sheetName}
        workbook={workbook}
        rows={rows}
        makeLoadData={makeLoadData}
        loadData={loadData}
        returnData={returnData}
        vloadColumns={vloadColumns}
        selectSheet={selectSheet}
        step={step}
        setStep={setStep}
        showFile={showFile}
        titles={titles}
      />
    </div>
  );
};

export default UploadAccounts;
