//
import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
//import UseRunAPI from 'src/components/UseRunAPI';

import SimpleTable from "src/components/tables/simpleTable/SimpleTable";
import ExportButton from "src/components/saveAsCsv/ExportButton";

const ExcelLoaderQBOJV = (props) => {
  const { ws, currentItem } = useSelector((state) => state);

  const [fixedData, setFixedData] = useState([{}]);

  const inputEl = useRef(null);
  const [jsonData, setJsonData] = useState();
  const [columnx, setColumnsx] = useState([
    { title: "line", field: "line" },
    { title: "Date", field: "Date" },
    { title: "Transaction Type", field: "Transaction Type" },
    { title: "Name", field: "Name" },
    { title: "Num", field: "Num" },
    { title: "Vendor", field: "Vendor" },
    { title: "Memo/Description", field: "Memo/Description" },
    { title: "Account #", field: "Account #" },
    { title: "Department", field: "Department" },
    { title: "Class", field: "Class" },
    { title: "Account", field: "Account" },
    { title: "Debit", field: "Debit" },
    { title: "Credit", field: "Credit" },
  ]);

  const [columns, setColumns] = useState([
    { title: "Date", field: "Date" },
    { title: "Transaction Type", field: "Transaction Type" },
    { title: "Num", field: "Num" },
    { title: "Num2", field: "No." },
    { title: "Vendor", field: "Vendor" },
    { title: "Vendor2", field: "Supplier" },
    { title: "MemoDescription", field: "Memo/Description" },
    { title: "Account", field: "Account" },
    { title: "Account2", field: "Account No." },
    { title: "Department", field: "Department" },
    { title: "Department2", field: "Location" },
    { title: "Last_Modified", field: "Last_Modified" },
    { title: "modified_by", field: "modified_by" },
    { title: "Class", field: "Class" },
    { title: "Debit", field: "Debit" },
    { title: "Credit", field: "Credit" },
  ]);

  const uploadMe = () => {
    inputEl.current.click();
  };

  const showFile = (e) => {
    console.log("showFile", e.target.files[0]);
    const f = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: "binary" });
      var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];

      var data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
      console.log(data);
      test(data);
    };
    reader.readAsBinaryString(f);
  };

  const test = async (bbb) => {
    let bb = bbb.filter((x, i) => i > 3);
    console.log("bb", bb);

    let cc = await formatData(bb);
    //  let dd = await selectRows(cc);
    // let ee = await makeGroup(dd);
    //   console.log(ee);
    // setFixedData(ee);
    console.log("FORMAT DATA", cc);
    setFixedData(cc);
    return "done";
  };

  const formatData = async (data) => {
    let bb = {};
    let lastbb = {};
    let newData = [];
    let lastAct = "";
    let lastGroup = "";
    let lastDate = "";
    let lastTransactionType = "";

    let db_i = data[0].indexOf("Debit");
    let cr_i = data[0].indexOf("Credit");

    let noRepeatItems = [
      "Debit",
      "Credit",
      "Account #",
      "Department",
      "Class",
      "Memo/Description",
      "Vendor",
    ];

    data
      .filter((fitem, i) => i > 0 && fitem[db_i] !== fitem[cr_i])
      .map((item, i) => {
        data[0].map((col, i) => {
          if (item[i] > "") {
            lastbb[col] = item[i];
          }
          //if (col !== 'Debit' && col !== 'Credit') {
          if (!noRepeatItems.includes(col)) {
            bb[col] = lastbb[col];
          } else {
            bb[col] = item[i];
          }
        });
        bb["line"] = i;

        newData.push(bb);
        bb = {};
      });
    //console.log(newData);
    return newData;
  };

  const selectRows = async (data) => {
    let ee = data
      .filter(
        (fitem) =>
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(
            fitem["act"].slice(0, 1)
          ) && !isNaN(fitem["amount"])
      )
      .map((item) => item);

    return ee;
  };

  const makeGroup = async (data) => {
    let ee = data.map((item) => {
      if (item.act.slice(0, 1) === "1") {
        let aa = item.act.split("-")[1];
        let bb = item.act.split("-")[5];
        return {
          line: item.line,
          month: item.month,
          act: aa,
          group: bb,
          amount: item.amount,
        };
      } else {
        return item;
      }
    });

    return ee;
  };

  const dataCallback = async (data) => {
    console.log("dataCallback", data);
  };

  return (
    <>
      <input
        hidden={true}
        id="gggg"
        type="file"
        onChange={(e) => showFile(e)}
        ref={inputEl}
      />

      <Button
        sx={{ marginTop: "20px", marginBottom: "20px", marginLeft: "5px" }}
        color="primary"
        size="small"
        variant="contained"
        onClick={() => {
          uploadMe();
        }}
      >
        Upload File
      </Button>
      <div>
        <ExportButton
          title={"QBO JV Export"}
          data={fixedData}
          refresh={dataCallback}
          showFirstRow={true}
        />
        <SimpleTable
          title="QBO Journal Entries"
          rows={fixedData}
          columns={columns}
          dataCallback={dataCallback}
        />
      </div>
    </>
  );
};

export default ExcelLoaderQBOJV;
