const Logo = () => {
  return (
    <div style={{ height: "50px", margin: "5px" }}>
      <img
        src="../../nibeaLogo.svg"
        alt=""
        style={{ height: "100%", width: "auto" }}
      />
    </div>
  );
};

export default Logo;
