import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { createContext } from "react";
//https://github.com/aws-amplify/amplify-js/tree/main/packages/amazon-cognito-identity-js
import { useDispatch } from "react-redux";
import Pool from "src/components/cognito/UserPool";

// USER AUTHENTICATION

const AccountContext = createContext();

const Account = (props) => {
  const dispatch = useDispatch();

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject(err);
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  resolve(results);
                }
              });
            });
            // console.log("EMAIL", attributes.email);
            dispatch(
              {
                type: "SET_COGNITO",
                payload: attributes.email,
              },
              []
            );

            resolve({ user, ...session, ...attributes });
          }
        });
      } else {
        reject();
      }
    });
  };

  // const resendVerifyEmail = async () => {
  //   return await new Promise((resolve, reject) => {
  //     const user = Pool.getCurrentUser();
  //     if (user) {
  //       user.resendConfirmationCode(function (err, result) {
  //         if (err) {
  //           alert(err.message || JSON.stringify(err));
  //           return;
  //         }
  //         console.log("call result: " + result);
  //       });
  //     } else {
  //       reject();
  //     }
  //   });
  // };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        mfaRequired: function (codeDeliveryDetails) {
          var verificationCode = prompt("Please input verification code", "");
          user.sendMFACode(verificationCode, this);
        },
        onSuccess: (data) => {
          dispatch(
            {
              type: "SET_COGNITO",
              payload: data["idToken"]["payload"]["email"],
            },
            []
          );

          resolve(data);
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);
          alert(err);

          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve({ code: "NEW_PASSWORD_REQUIRED" });
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    console.log("SETTING SUB: NOT-LOGGED-IN");

    if (user) {
      dispatch(
        {
          type: "RESET_COGNITO",
          payload: {},
        },
        []
      );

      user.signOut();
    }
  };

  const validateUser = async (Username, code) => {
    var userData = {
      Username: Username, // your username here
      Pool: Pool,
    };

    var cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        alert(JSON.stringify("ERROR "));
        return;
      }
      console.log("call result: " + result);
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        validateUser,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };
