import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ReportSaveButton({
  reportType,
  reportName,
  reportId,
  add_pov,
  update_pov,
}) {
  const [openSave, setOpenSave] = useState(false);
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [povName, setPovName] = useState("");

  useEffect(() => {}, []);

  const handleClickOpenSave = () => {
    setOpenSave(true);
  };
  const handleClickOpenSaveAs = () => {
    setOpenSaveAs(true);
  };

  const handleCancel = () => {
    setOpenSaveAs(false);
    setOpenSave(false);
  };

  const handleSave = () => {
    update_pov(reportId);
    setOpenSaveAs(false);
    setOpenSave(false);
  };

  const handleSaveAs = () => {
    add_pov(reportType, povName);
    setOpenSaveAs(false);
    setOpenSave(false);
  };

  return (
    <>
      {reportName !== "" ? (
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: 28, marginRight: "7px" }}
          onClick={() => handleClickOpenSave()}
          size="large"
        >
          {"Save"}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: 28, marginRight: "7px" }}
          onClick={() => handleClickOpenSaveAs()}
          size="large"
        >
          {"Save as"}
        </Button>
      )}

      <Dialog
        open={openSave}
        onClose={() => handleCancel()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Save Scenarios, Dates, Columns, Filters.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Update {reportName}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSave()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSaveAs}
        onClose={() => handleCancel()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Save Scenarios, Dates, Columns, Filters.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Save Report Point of View As:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="pov"
            fullWidth
            defaultValue={reportName}
            onChange={(e) => {
              setPovName(e.target.value);
              // console.log(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveAs()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
