import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
// layouts
import Layout0 from "src/layouts/Layout0";
import Layout1 from "src/layouts/Layout1";
import Layout2 from "src/layouts/Layout2";
//import Layout3 from "src/layouts/Layout3";
import Layout4 from "src/layouts/Layout4";

// login
import Login from "src/views/login/Login";
//import Test from "src/views/test/index";
import LoginTest from "src/views/login/LoginTest"; // Import used to check if you are logged in or not
import ReportView from "src/views/reporting/reportView";
import ReportGroups from "src/views/reporting/runReportGroups";
import ReportListings from "src/views/reporting/reportListing";
import MyReports from "src/views/reporting/myReports";
import SharedReports from "src/views/reporting/sharedReports";
import TableTest from "src/components/tables/tableExamples";
import UploadsListing from "src/views/uploads/uploadsListing";

import Home from "src/views/home/home_v1";
// workspaces
import Workspaces from "src/views/workspaces/Workspaces";

const Routes2 = () => {
  const { user, currentItem } = useSelector((state) => state);
  const { isAuthenticated } = user;

  return (
    <Routes>
      {isAuthenticated && currentItem.ws_id > 0 && (
        <>
          <Route path="workspace" element={<Layout2 />}>
            <Route path="stdreports" element={<ReportListings />} />
            <Route path="sharedreports" element={<SharedReports />} />
            <Route path="myreports" element={<MyReports />} />
            <Route path="favorites" element={<TableTest />} />
            <Route path="report" element={<ReportView />} />
            <Route path="reportGroups" element={<ReportGroups />} />
            <Route path="updates" element={<UploadsListing />} />
            <Route path="settings" element={<TableTest />} />
            <Route path="test" element={<TableTest />} />

            <Route path="home" element={<Home />} />
            <Route path="" element={<Home />} />
          </Route>
        </>
      )}
      {isAuthenticated && (
        <Route path="workspaces" element={<Layout1 />}>
          <Route path="spaces" element={<Workspaces />} />
        </Route>
      )}
      {!isAuthenticated && (
        <>
          <Route path="/" element={<Layout0 />}>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<LoginTest />} />
            <Route path="" element={<LoginTest />} />
          </Route>
        </>
      )}
    </Routes>
  );
};

export default Routes2;
