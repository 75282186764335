import React from "react";

import Routes from "src/routes";
import { Account } from "src/components/cognito/Account";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { t0, t1, t2, t3 } from "src/theme/theme";

const App = () => {
  const { themeId } = useSelector((state) => state);
  const tt = [t0, t1, t2, t3];

  const theme = useMemo(() => createTheme(tt[themeId]), [themeId]);
  return (
    <ThemeProvider theme={theme}>
      <Account>
        <Routes />
      </Account>
    </ThemeProvider>
  );
};

export default App;
