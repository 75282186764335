import { useState } from "react";
import store from "src/reducers/store";

const UseColumnPicker = () => {
  //const state = store.getState();
  const { currentItem, ws_settings } = store.getState();
  const { columns } = currentItem;

  const [items, setItems] = useState([
    { key: " ", value: " ", id: "0_Id", index: 0, picked: true },
  ]);

  const [staticItems, setStaticItems] = useState([
    { key: " ", value: " ", id: "0_Id", index: 0, picked: true },
  ]);

  const [changed, setChanged] = useState(false);

  const _getItems = () => {
    let mainItems = [];
    mainItems.push({
      key: "year",
      value: "Year",
      id: "1_Id",
      index: 1,
      picked: false,
      group: "date",
    });
    mainItems.push({
      key: "qtr",
      value: "Quarter",
      id: "2_Id",
      index: 2,
      picked: false,
      group: "date",
    });
    // mainItems.push({
    //   key: "ytd",
    //   value: "Year-to-Date",
    //   id: "3_Id",
    //   index: 3,
    //   picked: false,
    //   group: "date",
    // });
    mainItems.push({
      key: "month",
      value: "Month",
      id: "4_Id",
      index: 4,
      picked: false,
      group: "date",
    });
    mainItems.push({
      key: "scenario",
      value: "Scenario",
      id: "5_Id",
      index: 5,
      picked: false,
      group: "date",
    });
    mainItems.push({
      key: "entity",
      value: "Entity",
      id: "50_Id",
      index: 50,
      picked: false,
      group: "date",
    });
    mainItems.push({
      key: "entity_id",
      value: "Entity Id",
      id: "51_Id",
      index: 51,
      picked: false,
      group: "date",
    });
    mainItems.push({
      key: "dim1_id",
      value: ws_settings.dim[1] + " Id",
      id: "6_Id",
      index: 6,
      picked: false,
      group: "dim1",
    });
    mainItems.push({
      key: "dim1_code",
      value: ws_settings.dim[1] + " Code",
      id: "7_Id",
      index: 7,
      picked: false,
      group: "dim1",
    });
    mainItems.push({
      key: "dim1_attr1",
      value: ws_settings.dim[1] + " Name",
      id: "8_Id",
      index: 8,
      picked: false,
      group: "dim1",
    });
    mainItems.push({
      key: "dim1_attr2",
      value: ws_settings.dim[1] + " Group",
      id: "9_Id",
      index: 9,
      picked: false,
      group: "dim1",
    });
    // mainItems.push({
    //   key: "dim1_attr3",
    //   value: ws_settings.dim[1] + " Group",
    //   id: "10_Id",
    //   index: 10,
    //   picked: false,
    //   group: "dim1",
    // });
    // mainItems.push({
    //   key: "dim1_attr4",
    //   value: ws_settings.dim[1] + " Type",
    //   id: "10a_Id",
    //   index: 101,
    //   picked: false,
    //   group: "dim1",
    // });

    mainItems.push({
      key: "dim2_id",
      value: ws_settings.dim[2] + " id",
      id: "11_Id",
      index: 11,
      picked: false,
      group: "dim2",
    });
    mainItems.push({
      key: "dim2_code",
      value: ws_settings.dim[2] + " Code",
      id: "12_Id",
      index: 12,
      picked: false,
      group: "dim2",
    });
    mainItems.push({
      key: "dim2_attr1",
      value: ws_settings.dim[2] + " Name",
      id: "13_Id",
      index: 13,
      picked: false,
      group: "dim2",
    });
    mainItems.push({
      key: "dim2_attr2",
      value: ws_settings.dim[2] + " Group",
      id: "14_Id",
      index: 14,
      picked: false,
      group: "dim2",
    });
    // mainItems.push({
    //   key: "dim2_attr3",
    //   value: ws_settings.dim[2] + " Group",
    //   id: "15_Id",
    //   index: 15,
    //   picked: false,
    //   group: "dim2",
    // });
    // mainItems.push({
    //   key: "dim2_attr4",
    //   value: ws_settings.dim[2] + " Type",
    //   id: "15a_Id",
    //   index: 151,
    //   picked: false,
    //   group: "dim2",
    // });

    // mainItems.push({
    //   key: "dim3_id",
    //   value: ws_settings.dim[3] + " Id",
    //   id: "16_Id",
    //   index: 16,
    //   picked: false,
    //   group: "dim3",
    // });
    // mainItems.push({
    //   key: "dim3_code",
    //   value: ws_settings.dim[3] + " Code",
    //   id: "17_Id",
    //   index: 17,
    //   picked: false,
    //   group: "dim3",
    // });
    // mainItems.push({
    //   key: "dim3_attr1",
    //   value: ws_settings.dim[3] + " Name",
    //   id: "18_Id",
    //   index: 18,
    //   picked: false,
    //   group: "dim3",
    // });
    // mainItems.push({
    //   key: "dim3_attr2",
    //   value: ws_settings.dim[3] + " Description",
    //   id: "19_Id",
    //   index: 19,
    //   picked: false,
    //   group: "dim3",
    // });
    // mainItems.push({
    //   key: "dim3_attr3",
    //   value: ws_settings.dim[3] + " Group",
    //   id: "20_Id",
    //   index: 20,
    //   picked: false,
    //   group: "dim3",
    // });
    // mainItems.push({
    //   key: "dim3_attr4",
    //   value: ws_settings.dim[3] + " Type",
    //   id: "20a_Id",
    //   index: 201,
    //   picked: false,
    //   group: "dim3",
    // });

    setStaticItems([...mainItems]);

    mainItems.map((item) => {
      if (columns.includes(item.key)) {
        item.picked = true;
      }
    });

    let pickedItems = mainItems.filter((fitm) => fitm.picked === true);
    let unpickedItems = mainItems.filter(
      (fitem) => !pickedItems.includes(fitem)
    );
    let allItems1 = pickedItems.concat(unpickedItems);

    setItems(allItems1);
  };

  const _reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function handleDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const rr = _reorder(items, result.source.index, result.destination.index);
    setChanged(true);
    setItems(rr);
  }

  const saveChanges = () => {
    const newCols = items
      .filter((fitem) => fitem.picked)
      .map((mitem) => mitem.key);
    _updateColumns(newCols);
  };

  const _updateColumns = async (data) => {
    store.dispatch({
      type: "SET_CURRENT_COLUMNS",
      payload: data,
    });
    return "done";
  };

  const getItems = () => {
    _getItems();
  };

  const handleStaticCheckChange = (e, id) => {
    //alert("handleStaticCheckChange");
    const { checked } = e.target;
    console.log("checked", checked, id);
    const item = staticItems.find((fitem) => fitem.id === id);
    item.picked = checked;
    setStaticItems([...staticItems]);
    sortMe();
  };

  const sortMe = () => {
    // alert("handleCheckBoxChange");
    setChanged(true);
    // let tempCols = [...items];
    // tempCols[index]["picked"] = e.target.checked;

    let pickedItems = items.filter((fitm) => fitm.picked === true);
    let unpickedItems = items.filter((fitem) => !pickedItems.includes(fitem));

    let allItems = pickedItems.concat(unpickedItems);

    if (pickedItems.length === 0) {
      setChanged(false);
    }

    setItems(allItems);
  };

  const handleCheckBoxChange = (e, index) => {
    // alert("handleCheckBoxChange");
    setChanged(true);
    let tempCols = [...items];
    tempCols[index]["picked"] = e.target.checked;

    let pickedItems = tempCols.filter((fitm) => fitm.picked === true);
    let unpickedItems = tempCols.filter(
      (fitem) => !pickedItems.includes(fitem)
    );

    let allItems = pickedItems.concat(unpickedItems);

    if (pickedItems.length === 0) {
      setChanged(false);
    }

    setItems({ allItems });
  };

  return {
    getItems,
    handleDragEnd,
    saveChanges,
    handleCheckBoxChange,
    handleStaticCheckChange,
    changed,
    items,
    staticItems,
    columns,
  };
};

export default UseColumnPicker;
