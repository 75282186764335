import ExportButton from "src/components/saveAsCsv/ExportButton";
import FilterHeader from "src/components/filterHeader";
import useAcrossAccount from "./useAcrossAccounts";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";

function Index() {
  const {
    data,
    getData,
    columns,
    nbrFormat_2,
    actMap,
    handleClickCell,
    formatHeader,
  } = useAcrossAccount();

  useEffect(() => {
    getData();
  }, []);

  const handleClick = (index, row, col) => {
    console.log(index, row, col);
    console.log("ACTMAP", actMap[index]);
    handleClickCell(index, row, col);
  };

  return (
    <div>
      <FilterHeader />
      <div>
        <ExportButton
          title={"By Account Report"}
          data={data}
          refresh={getData}
          showFirstRow={true}
        />
      </div>
      <div>
        <TableContainer component={Paper} sx={{ height: "700px" }}>
          <PerfectScrollbar>
            <Table
              aria-label="simple table"
              size="small"
              style={{
                paddingBottom: "30px",
                border: "solid 1px",
              }}
            >
              <TableBody>
                <TableRow
                  sx={{
                    position: "sticky",
                    top: 0,
                    borderBottom: "solid 2px",
                    borderCollapse: "black",
                    backgroundColor: "#f5f5f9",
                  }}
                >
                  {columns.map((col, index) => (
                    <TableCell key={index + "sdfs2"}>
                      {formatHeader(index, col)}
                    </TableCell>
                  ))}
                </TableRow>

                {data.map((row, ind) => (
                  <TableRow key={ind}>
                    {columns.map((col, index) => (
                      <TableCell
                        style={{ padding: "15px" }}
                        onDoubleClick={() => handleClick(index, row, col)}
                        key={index}
                      >
                        {nbrFormat_2(index, row, col)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </TableContainer>
      </div>
      {/* <div style={{ margin: "30px" }}>{JSON.stringify(columns)}</div>*/}
      {/* <div style={{ margin: "30px" }}>{JSON.stringify(actMap)}</div>
      <div style={{ margin: "30px" }}>{JSON.stringify(data)}</div> */}
    </div>
  );
}

export default Index;
