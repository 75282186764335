import { useState } from "react";
import findMatches from "./findMatches";
import csv from "csv";

const useRecon = () => {
  const [data_a, setData_a] = useState([]);
  const [data_b, setData_b] = useState([]);
  const [columns] = useState([
    { title: "Match", field: "match" },
    { title: "Amount", field: "amount", numeric: true },
    { title: "Amount A", field: "amount_a", numeric: true },
    { title: "Amount B", field: "amount_b", numeric: true },
    { title: "Source", field: "name" },
    { title: "Line", field: "id" },
    { title: "Description", field: "label" },
  ]);
  const [busy, setBusy] = useState(false);
  const [rows, setRows] = useState([{}]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [checkAmount, setCheckAmount] = useState(0);
  const [matchCount, setMatchCount] = useState(1);
  const [aAmountTotal, setAAmountTotal] = useState(0);
  const [bAmountTotal, setBAmountTotal] = useState(0);
  const [diffAmountTotal, setDiffAmountTotal] = useState(0);
  const [noMatchArray, setNoMatchArray] = useState([]);
  //
  const [solutionIndex, setSolutionIndex] = useState(0);
  const [solution, setSolution] = useState([]);
  const [quickWins, setQuickWins] = useState([]);

  const pasteFile = async (e, a_b) => {
    e.preventDefault();

    let pasteData = e.target.value.trim();
    if (a_b === "a") {
      pasteData = "label_a" + "\t" + "amount_a" + "\n" + pasteData;
    } else {
      pasteData = "label_b" + "\t" + "amount_b" + "\n" + pasteData;
    }
    console.log("PASTE DATA: ", pasteData);
    //document.getElementById(a_b).value = "";

    let delim = "\t";

    // if (pasteData.includes("\t")) {
    //   //  console.log("is tab delimitted");
    //   pasteData = pasteData.replaceAll(",", "");
    //   // console.log("NO COMMA: ", pasteData);
    // }

    // if (pasteData.includes(",")) {
    //   console.log("is comma delimitted");
    //   delim = ",";
    // }

    csv.parse(
      pasteData,
      {
        columns: true,
        delimiter: delim,
      },
      (err, jdata) => {
        console.log("ERROR: ", err);
        console.log(jdata);
        // console.log(JSON.stringify(jdata, null, 2));

        try {
          let newcc = [];
          let colcc = {};

          Object.keys(jdata[0]).forEach((key) => {
            colcc.title = key;
            colcc.field = key;
            newcc.push(colcc);
            colcc = {};
          });

          if (a_b === "a") {
            setData_a(jdata);
            const aTot = jdata.reduce((a, b) => a + Number(b.amount_a), 0);
            setAAmountTotal(aTot);
            setDiffAmountTotal(aTot + bAmountTotal);
          }
          if (a_b === "b") {
            setData_b(jdata);
            const bTot = jdata.reduce((a, b) => a + Number(b.amount_b), 0);
            setBAmountTotal(bTot);
            setDiffAmountTotal(aAmountTotal + bTot);
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  const nbrFormat = (nbr, d = 2) => {
    try {
      return nbr.toLocaleString(undefined, {
        maximumFractionDigits: d,
        minimumFractionDigits: d,
      });
    } catch (error) {
      return 0;
    }
  };

  const dataCallback = (ids) => {
    setSelectionModel(ids);
    console.log("dataCallback", ids);
    //  setUpdateIds(data);
    let checkAmt = rows
      .filter((fitem) => ids.includes(fitem.id))
      .reduce(
        (a, b) =>
          b.name === "Data A" ? a - Number(b.amount) : a + Number(b.amount),
        0
      );
    setCheckAmount(checkAmt);
  };

  const tagMatch = () => {
    console.log("TAG MATCH");
    console.log(selectionModel);
    console.log(rows);
    const newRows = rows.map((item) => {
      if (selectionModel.includes(item.id)) {
        return { ...item, match: `Matched ${matchCount}` };
      } else {
        return item;
      }
    });
    setRows(newRows);
    setMatchCount(matchCount + 1);
  };

  const dataCallback1 = (data) => {
    console.log("dataCallback", data);
  };

  const compare = async () => {
    setBusy(true);
    console.log("COMPARE");
    const arr3 = [];

    let ind = 0;
    data_a.forEach((item, i) => {
      const obj = {
        name: "Data A",
        id: `A-${i}`,
        amount: Number(item.amount_a) * 1,
        amount_a: Number(item.amount_a),
        amount_b: 0,
        label: item.label_a,

        msg: "",
      };
      arr3.push(obj);
      ind = ind + 1;
    });

    data_b.forEach((item, i) => {
      const obj = {
        name: "Data B",
        id: `B-${i}`,
        amount: -Number(item.amount_b),
        amount_b: Number(item.amount_b),
        amount_a: 0,
        label: item.label_b,

        msg: "",
      };
      arr3.push(obj);
      ind = ind + 1;
    });

    const aTot = data_a.reduce((a, b) => a + Number(b.amount_a), 0);
    const bTot = data_b.reduce((a, b) => a + Number(b.amount_b), 0);
    const diff1 = aTot + bTot;

    const diff = Math.round((diff1 + Number.EPSILON) * 100) / 100;
    //const diff = diff1.toFixed(2);

    setAAmountTotal(aTot);
    setBAmountTotal(bTot);
    setDiffAmountTotal(diff);

    arr3.sort((a, b) => a.amount - b.amount);
    arr3.unshift({
      name: "Data A",
      id: `A-A`,
      amount: Number(0),
      amount_a: nbrFormat(0),
      amount_b: 0,
      label: "dummy",
      msg: "",
    });

    arr3.push({
      name: "Data B",
      id: `B-B`,
      amount: Number(0),
      amount_a: Number(0),
      amount_b: Number(0),
      label: "dummy",
      msg: "",
    });

    console.log(arr3);
    const rowLength = arr3.length;
    const rows2 = arr3.map((row, i) => {
      if (i === 0) {
        return { ...row, match: "" };
      } else if (i === rowLength - 1) {
        return { ...row, match: "" };
      } else {
        if (
          row.amount === arr3[i - 1].amount ||
          row.amount === arr3[i + 1].amount
        ) {
          return { ...row, match: "" };
        } else if (
          row.name === arr3[i - 1].name &&
          row.amount === arr3[i - 1].amount
        ) {
          return { ...row, match: "Repeat" };
        } else if (
          row.name === arr3[i + 1].name &&
          row.amount === arr3[i + 1].amount
        ) {
          return { ...row, match: "Repeat" };
        } else {
          return { ...row, match: "No Match" };
        }
      }
    });

    console.log(rows2);

    const rows3 = rows2.filter(
      (fitem) => fitem.id !== "A-A" && fitem.id !== "B-B"
    );

    setRows(rows3);

    // get the set of numbers that equal the diffAmountTotal

    const noMatch = rows3
      .filter((fitem) => fitem.match === "No Match")
      .map((x) => {
        return {
          ...x,
          amount: x.name === "Data B" ? -Number(x.amount) : Number(x.amount),
        };
      });

    //
    setNoMatchArray(noMatch);

    //
    setSolutionIndex(0);
    setSolution([]);

    console.log("noMatch", noMatch);
    console.log("DIFFAMOUNT TOTAL", diff);
    setBusy(false);
  };

  const findAnswers = async () => {
    const newMatches = await findMatches(noMatchArray, diffAmountTotal);
    if (newMatches.length) {
      setSolution(
        noMatchArray.filter((fitem) =>
          newMatches[solutionIndex].includes(fitem.id)
        )
      );
    }
    console.log("SOLUTION", solution);
    console.log("NEW MATCHES", newMatches);
  };

  return {
    tagMatch,
    dataCallback,
    noMatchArray,
    data_a,
    data_b,
    aAmountTotal,
    bAmountTotal,
    compare,
    columns,
    rows,
    quickWins,
    diffAmountTotal,
    solution,
    busy,
    nbrFormat,
    pasteFile,
    findAnswers,
    dataCallback1,
    selectionModel,
    checkAmount,
  };
};

export default useRecon;
