import store from "src/reducers/store";
import { useContext } from "react";
import { AccountContext } from "src/components/cognito/Account";

const _API_getData_test = async (url = "", data = {}, accessToken) => {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

const UseRunAPI = () => {
  const { currentItem } = store.getState();
  const { getSession } = useContext(AccountContext);

  const RunAPI = async (new_json, awsURL) => {
    // const state = store.getState();
    // const wsd = state.currentItem;

    store.dispatch({
      type: "SET_BUSY_TRUE",
    });

    const { idToken } = await getSession();
    const { jwtToken } = idToken;

    let base_json = {
      output: "JSON",
      workSpaceId: currentItem.ws_id,
      entities: currentItem.entities,
      entity: currentItem.entity,
      version: currentItem.version,
      begDate: currentItem.beg,
      endDate: currentItem.end,
      versions: currentItem.versions,
      columns: currentItem.columns,
      accounts: currentItem.accounts,
      dim1: currentItem.dim1,
      dim2: currentItem.dim2,
      dim3: currentItem.dim3,
    };

    let json = {
      ...base_json,
      ...new_json,
    };
    console.log("CALL:", json);
    let bb = await _API_getData_test(awsURL, json, jwtToken);

    store.dispatch({
      type: "SET_BUSY_FALSE",
    });

    return bb;
  };

  return { RunAPI };
};

export default UseRunAPI;
