export const cols = [
  { value: "dept", label: "Department" },
  { value: "account", label: "Accounts" },
  { value: "actType", label: "Type" },
  { value: "jan", label: "Jan" },
  { value: "feb", label: "Feb" },
  { value: "mar", label: "Mar" },
  { value: "apr", label: "Apr" },
  { value: "may", label: "May" },
  { value: "jun", label: "Jun" },
  { value: "jul", label: "Jul" },
  { value: "aug", label: "Aug" },
  { value: "sep", label: "Sep" },
  { value: "oct", label: "Oct" },
  { value: "nov", label: "Nov" },
  { value: "dec", label: "Dec" },
];

export const data = [
  {
    dept: "dim1",
    account: "act1",
    actType: 1,
    jan: 58,
    feb: 252,
    mar: 95,
    apr: 58,
    may: 252,
    jun: 95,
    jul: 58,
    aug: 252,
    sep: 95,
    oct: 58,
    nov: 252,
    dec: 95,
  },
  {
    dept: "dim1",
    account: "act2",
    actType: 1,
    jan: 235,
    feb: 48,
    mar: 203,
    apr: 235,
    may: 48,
    jun: 203,
    jul: 235,
    aug: 48,
    sep: 203,
    oct: 235,
    nov: 48,
    dec: 203,
  },
  {
    dept: "dim1",
    account: "act3",
    actType: 1,
    jan: 102,
    feb: 294,
    mar: 245,
    apr: 102,
    may: 294,
    jun: 245,
    jul: 102,
    aug: 294,
    sep: 245,
    oct: 102,
    nov: 294,
    dec: 245,
  },
  {
    dept: "dim1",
    account: "act4",
    actType: 10,
    jan: 395,
    feb: 594,
    mar: 543,
    apr: 395,
    may: 594,
    jun: 543,
    jul: 395,
    aug: 594,
    sep: 543,
    oct: 395,
    nov: 594,
    dec: 543,
  },
  {
    dept: "dim1",
    account: "act5",
    actType: 1,
    jan: 269,
    feb: 223,
    mar: 230,
    apr: 269,
    may: 223,
    jun: 230,
    jul: 269,
    aug: 223,
    sep: 230,
    oct: 269,
    nov: 223,
    dec: 230,
  },
  {
    dept: "dim1",
    account: "act6",
    actType: 1,
    jan: 229,
    feb: 207,
    mar: 164,
    apr: 229,
    may: 207,
    jun: 164,
    jul: 229,
    aug: 207,
    sep: 164,
    oct: 229,
    nov: 207,
    dec: 164,
  },
  {
    dept: "dim1",
    account: "act7",
    actType: 1,
    jan: 223,
    feb: 228,
    mar: 190,
    apr: 223,
    may: 228,
    jun: 190,
    jul: 223,
    aug: 228,
    sep: 190,
    oct: 223,
    nov: 228,
    dec: 190,
  },
  {
    dept: "dim1",
    account: "act8",
    actType: 10,
    jan: 721,
    feb: 658,
    mar: 584,
    apr: 721,
    may: 658,
    jun: 584,
    jul: 721,
    aug: 658,
    sep: 584,
    oct: 721,
    nov: 658,
    dec: 584,
  },
  {
    dept: "dim1",
    account: "act9",
    actType: 1,
    jan: 172,
    feb: 56,
    mar: 87,
    apr: 172,
    may: 56,
    jun: 87,
    jul: 172,
    aug: 56,
    sep: 87,
    oct: 172,
    nov: 56,
    dec: 87,
  },
  {
    dept: "dim1",
    account: "act10",
    actType: 1,
    jan: 230,
    feb: 61,
    mar: 121,
    apr: 230,
    may: 61,
    jun: 121,
    jul: 230,
    aug: 61,
    sep: 121,
    oct: 230,
    nov: 61,
    dec: 121,
  },
  {
    dept: "dim1",
    account: "act11",
    actType: 1,
    jan: 156,
    feb: 130,
    mar: 52,
    apr: 156,
    may: 130,
    jun: 52,
    jul: 156,
    aug: 130,
    sep: 52,
    oct: 156,
    nov: 130,
    dec: 52,
  },
  {
    dept: "dim1",
    account: "act12",
    actType: 11,
    jan: 1116,
    feb: 1252,
    mar: 1127,
    apr: 1116,
    may: 1252,
    jun: 1127,
    jul: 1116,
    aug: 1252,
    sep: 1127,
    oct: 1116,
    nov: 1252,
    dec: 1127,
  },
  {
    dept: "dim2",
    account: "act1",
    actType: 1,
    jan: 66,
    feb: 238,
    mar: 230,
    apr: 66,
    may: 238,
    jun: 230,
    jul: 66,
    aug: 238,
    sep: 230,
    oct: 66,
    nov: 238,
    dec: 230,
  },
  {
    dept: "dim2",
    account: "act2",
    actType: 1,
    jan: 80,
    feb: 160,
    mar: 72,
    apr: 80,
    may: 160,
    jun: 72,
    jul: 80,
    aug: 160,
    sep: 72,
    oct: 80,
    nov: 160,
    dec: 72,
  },
  {
    dept: "dim2",
    account: "act3",
    actType: 1,
    jan: 230,
    feb: 99,
    mar: 47,
    apr: 230,
    may: 99,
    jun: 47,
    jul: 230,
    aug: 99,
    sep: 47,
    oct: 230,
    nov: 99,
    dec: 47,
  },
  {
    dept: "dim2",
    account: "act4",
    actType: 10,
    jan: 376,
    feb: 497,
    mar: 349,
    apr: 376,
    may: 497,
    jun: 349,
    jul: 376,
    aug: 497,
    sep: 349,
    oct: 376,
    nov: 497,
    dec: 349,
  },
  {
    dept: "dim2",
    account: "act5",
    actType: 1,
    jan: 76,
    feb: 207,
    mar: 183,
    apr: 76,
    may: 207,
    jun: 183,
    jul: 76,
    aug: 207,
    sep: 183,
    oct: 76,
    nov: 207,
    dec: 183,
  },
  {
    dept: "dim2",
    account: "act6",
    actType: 1,
    jan: 77,
    feb: 46,
    mar: 295,
    apr: 77,
    may: 46,
    jun: 295,
    jul: 77,
    aug: 46,
    sep: 295,
    oct: 77,
    nov: 46,
    dec: 295,
  },
  {
    dept: "dim2",
    account: "act7",
    actType: 1,
    jan: 289,
    feb: 237,
    mar: 177,
    apr: 289,
    may: 237,
    jun: 177,
    jul: 289,
    aug: 237,
    sep: 177,
    oct: 289,
    nov: 237,
    dec: 177,
  },
  {
    dept: "dim2",
    account: "act8",
    actType: 10,
    jan: 442,
    feb: 490,
    mar: 655,
    apr: 442,
    may: 490,
    jun: 655,
    jul: 442,
    aug: 490,
    sep: 655,
    oct: 442,
    nov: 490,
    dec: 655,
  },
  {
    dept: "dim2",
    account: "act9",
    actType: 1,
    jan: 293,
    feb: 88,
    mar: 46,
    apr: 293,
    may: 88,
    jun: 46,
    jul: 293,
    aug: 88,
    sep: 46,
    oct: 293,
    nov: 88,
    dec: 46,
  },
  {
    dept: "dim2",
    account: "act10",
    actType: 1,
    jan: 286,
    feb: 51,
    mar: 190,
    apr: 286,
    may: 51,
    jun: 190,
    jul: 286,
    aug: 51,
    sep: 190,
    oct: 286,
    nov: 51,
    dec: 190,
  },
  {
    dept: "dim2",
    account: "act11",
    actType: 1,
    jan: 98,
    feb: 231,
    mar: 22,
    apr: 98,
    may: 231,
    jun: 22,
    jul: 98,
    aug: 231,
    sep: 22,
    oct: 98,
    nov: 231,
    dec: 22,
  },
  {
    dept: "dim2",
    account: "act12",
    actType: 11,
    jan: 818,
    feb: 987,
    mar: 1004,
    apr: 818,
    may: 987,
    jun: 1004,
    jul: 818,
    aug: 987,
    sep: 1004,
    oct: 818,
    nov: 987,
    dec: 1004,
  },
  {
    dept: "dim3",
    account: "act1",
    actType: 1,
    jan: 130,
    feb: 28,
    mar: 275,
    apr: 130,
    may: 28,
    jun: 275,
    jul: 130,
    aug: 28,
    sep: 275,
    oct: 130,
    nov: 28,
    dec: 275,
  },
  {
    dept: "dim3",
    account: "act2",
    actType: 1,
    jan: 185,
    feb: 151,
    mar: 91,
    apr: 185,
    may: 151,
    jun: 91,
    jul: 185,
    aug: 151,
    sep: 91,
    oct: 185,
    nov: 151,
    dec: 91,
  },
  {
    dept: "dim3",
    account: "act3",
    actType: 1,
    jan: 110,
    feb: 170,
    mar: 42,
    apr: 110,
    may: 170,
    jun: 42,
    jul: 110,
    aug: 170,
    sep: 42,
    oct: 110,
    nov: 170,
    dec: 42,
  },
  {
    dept: "dim3",
    account: "act4",
    actType: 10,
    jan: 425,
    feb: 349,
    mar: 408,
    apr: 425,
    may: 349,
    jun: 408,
    jul: 425,
    aug: 349,
    sep: 408,
    oct: 425,
    nov: 349,
    dec: 408,
  },
  {
    dept: "dim3",
    account: "act5",
    actType: 1,
    jan: 48,
    feb: 53,
    mar: 36,
    apr: 48,
    may: 53,
    jun: 36,
    jul: 48,
    aug: 53,
    sep: 36,
    oct: 48,
    nov: 53,
    dec: 36,
  },
  {
    dept: "dim3",
    account: "act6",
    actType: 1,
    jan: 107,
    feb: 135,
    mar: 279,
    apr: 107,
    may: 135,
    jun: 279,
    jul: 107,
    aug: 135,
    sep: 279,
    oct: 107,
    nov: 135,
    dec: 279,
  },
  {
    dept: "dim3",
    account: "act7",
    actType: 1,
    jan: 141,
    feb: 271,
    mar: 23,
    apr: 141,
    may: 271,
    jun: 23,
    jul: 141,
    aug: 271,
    sep: 23,
    oct: 141,
    nov: 271,
    dec: 23,
  },
  {
    dept: "dim3",
    account: "act8",
    actType: 10,
    jan: 296,
    feb: 459,
    mar: 338,
    apr: 296,
    may: 459,
    jun: 338,
    jul: 296,
    aug: 459,
    sep: 338,
    oct: 296,
    nov: 459,
    dec: 338,
  },
  {
    dept: "dim3",
    account: "act9",
    actType: 1,
    jan: 244,
    feb: 229,
    mar: 236,
    apr: 244,
    may: 229,
    jun: 236,
    jul: 244,
    aug: 229,
    sep: 236,
    oct: 244,
    nov: 229,
    dec: 236,
  },
  {
    dept: "dim3",
    account: "act10",
    actType: 1,
    jan: 33,
    feb: 89,
    mar: 210,
    apr: 33,
    may: 89,
    jun: 210,
    jul: 33,
    aug: 89,
    sep: 210,
    oct: 33,
    nov: 89,
    dec: 210,
  },
  {
    dept: "dim3",
    account: "act11",
    actType: 1,
    jan: 59,
    feb: 211,
    mar: 252,
    apr: 59,
    may: 211,
    jun: 252,
    jul: 59,
    aug: 211,
    sep: 252,
    oct: 59,
    nov: 211,
    dec: 252,
  },
  {
    dept: "dim3",
    account: "act12",
    actType: 11,
    jan: 721,
    feb: 808,
    mar: 746,
    apr: 721,
    may: 808,
    jun: 746,
    jul: 721,
    aug: 808,
    sep: 746,
    oct: 721,
    nov: 808,
    dec: 746,
  },
  {
    dept: "dim4",
    account: "act1",
    actType: 1,
    jan: 146,
    feb: 178,
    mar: 198,
    apr: 146,
    may: 178,
    jun: 198,
    jul: 146,
    aug: 178,
    sep: 198,
    oct: 146,
    nov: 178,
    dec: 198,
  },
  {
    dept: "dim4",
    account: "act2",
    actType: 1,
    jan: 211,
    feb: 210,
    mar: 230,
    apr: 211,
    may: 210,
    jun: 230,
    jul: 211,
    aug: 210,
    sep: 230,
    oct: 211,
    nov: 210,
    dec: 230,
  },
  {
    dept: "dim4",
    account: "act3",
    actType: 1,
    jan: 202,
    feb: 231,
    mar: 78,
    apr: 202,
    may: 231,
    jun: 78,
    jul: 202,
    aug: 231,
    sep: 78,
    oct: 202,
    nov: 231,
    dec: 78,
  },
  {
    dept: "dim4",
    account: "act4",
    actType: 10,
    jan: 559,
    feb: 619,
    mar: 506,
    apr: 559,
    may: 619,
    jun: 506,
    jul: 559,
    aug: 619,
    sep: 506,
    oct: 559,
    nov: 619,
    dec: 506,
  },
  {
    dept: "dim4",
    account: "act5",
    actType: 1,
    jan: 274,
    feb: 193,
    mar: 59,
    apr: 274,
    may: 193,
    jun: 59,
    jul: 274,
    aug: 193,
    sep: 59,
    oct: 274,
    nov: 193,
    dec: 59,
  },
  {
    dept: "dim4",
    account: "act6",
    actType: 1,
    jan: 250,
    feb: 54,
    mar: 41,
    apr: 250,
    may: 54,
    jun: 41,
    jul: 250,
    aug: 54,
    sep: 41,
    oct: 250,
    nov: 54,
    dec: 41,
  },
  {
    dept: "dim4",
    account: "act7",
    actType: 1,
    jan: 217,
    feb: 23,
    mar: 150,
    apr: 217,
    may: 23,
    jun: 150,
    jul: 217,
    aug: 23,
    sep: 150,
    oct: 217,
    nov: 23,
    dec: 150,
  },
  {
    dept: "dim4",
    account: "act8",
    actType: 10,
    jan: 741,
    feb: 270,
    mar: 250,
    apr: 741,
    may: 270,
    jun: 250,
    jul: 741,
    aug: 270,
    sep: 250,
    oct: 741,
    nov: 270,
    dec: 250,
  },
  {
    dept: "dim4",
    account: "act9",
    actType: 1,
    jan: 83,
    feb: 58,
    mar: 197,
    apr: 83,
    may: 58,
    jun: 197,
    jul: 83,
    aug: 58,
    sep: 197,
    oct: 83,
    nov: 58,
    dec: 197,
  },
  {
    dept: "dim4",
    account: "act10",
    actType: 1,
    jan: 171,
    feb: 255,
    mar: 255,
    apr: 171,
    may: 255,
    jun: 255,
    jul: 171,
    aug: 255,
    sep: 255,
    oct: 171,
    nov: 255,
    dec: 255,
  },
  {
    dept: "dim4",
    account: "act11",
    actType: 1,
    jan: 142,
    feb: 211,
    mar: 133,
    apr: 142,
    may: 211,
    jun: 133,
    jul: 142,
    aug: 211,
    sep: 133,
    oct: 142,
    nov: 211,
    dec: 133,
  },
  {
    dept: "dim4",
    account: "act12",
    actType: 11,
    jan: 130,
    feb: 889,
    mar: 756,
    apr: 1300,
    may: 889,
    jun: 756,
    jul: 1300,
    aug: 889,
    sep: 756,
    oct: 130,
    nov: 889,
    dec: 756,
  },
];
