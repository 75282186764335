import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";

const useDimensions = () => {
  const [data, setData] = useState([{}]);
  const [currentDim, setCurrentDim] = useState("1");
  const [dims, setDims] = useState([
    { name: "dim1", value: "1" },
    { name: "dim2", value: "2" },
  ]);

  // [{"dim_id":"Dim-1","dim_code":"0000","attr_1":"0000 - No Department","attr_2":"G&A","attr_3":"","attr_4":null},
  const [columns] = useState([
    {
      field: "dim_id",
      title: "Internal ID",
    },
    { field: "dim_code", title: "Code" },
    { field: "attr_1", title: "Attribute 1" },
    { field: "attr_2", title: "Attribute 2" },
    { field: "attr_3", title: "Attribute 3" },
    { field: "attr_4", title: "Attribute 4" },
    { field: "last_update", title: "last_update" },
    { field: "update_user", title: "update_user" },
  ]);

  const { RunAPI } = UseRunAPI();

  const getData = async (data) => {
    let json = {
      area: "dimension",
      action: "get",
      currentDimension: currentDim,
    };

    const awsURL = process.env.REACT_APP_URL_SETTINGS;
    const response = await RunAPI(json, awsURL);
    console.log("RESPONSE", response);

    if (response["status"] === "error") {
      console.log("ERROR", response);
      setData([{}]);
      return "error";
    }
    setData(response["data"]);

    return "OK";
  };

  return {
    data,
    getData,
    currentDim,
    setCurrentDim,
    dims,
    setDims,
    columns,
  };
};

export default useDimensions;
