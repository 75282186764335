import { Chip, Typography, Card, CardContent, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const Index = ({ multiple = true }) => {
  const { currentItem, ws_scenarios } = useSelector((state) => state);

  const dispatch = useDispatch();

  const updateVersions = async (val) => {
    if (multiple) {
      let versions = currentItem.versions;

      if (currentItem.versions.includes(val)) {
        if (versions.length > 0) {
          versions.splice(versions.indexOf(val), 1);
          // console.log('REMOVE ITEM');
          dispatch({
            type: "SET_CURRENT_VERSIONS",
            payload: [...versions],
          });
        }
      } else {
        //versions.push(val.vers_id);
        dispatch({
          type: "SET_CURRENT_VERSIONS",
          payload: [...versions, val],
        });
      }
    } else {
      dispatch({
        type: "SET_CURRENT_VERSION",
        payload: val,
      });
    }
  };

  const deleteVersion = async (val) => {
    let versions = currentItem.versions;
    if (currentItem.versions.includes(val)) {
      if (versions.length > 1) {
        versions.splice(versions.indexOf(val), 1);
        dispatch({
          type: "SET_CURRENT_VERSIONS",
          payload: [...versions],
        });
      }
    }
  };

  return (
    <Card raised={true} sx={{ borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h6" id="tableTitle" sx={{ mr: 2 }} component="div">
          Scenarios
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ flexWrap: "wrap", gap: 2 }}
          spacing={2}
        >
          {multiple &&
            ws_scenarios
              .filter((fitem) => fitem.status > 0)
              .map((value, index) => {
                return (
                  <Chip
                    size="medium"
                    sx={{ mr: 1, mb: 1 }}
                    key={value.id}
                    label={value.name}
                    id={value.id}
                    clickable
                    variant={
                      currentItem.versions.includes(value.id)
                        ? "default"
                        : "outlined"
                    }
                    color="primary"
                    onDelete={() => deleteVersion(value.id)}
                    onClick={() => updateVersions(value.id)}
                  />
                );
              })}
          {!multiple &&
            ws_scenarios
              .filter((fitem) => fitem.status > 0)
              .map((value, index) => {
                return (
                  <Chip
                    sx={{ mr: 1, mb: 1 }}
                    size="medium"
                    key={value.id}
                    label={value.name}
                    id={value.id}
                    clickable
                    variant={
                      currentItem.version === value.id ? "default" : "outlined"
                    }
                    color="primary"
                    onClick={() => updateVersions(value.id)}
                  />
                );
              })}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Index;
