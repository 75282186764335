import useUploadAccounts from "./useLedgerLoad";
import LoadForm from "./LoadForm";

const LedgerLoad = () => {
  const {
    updateColumns,
    loadColumns,
    columns,
    loading,
    sheetName,
    workbook,
    rows,
    makeLoadData,
    loadData,
    returnData,
    vloadColumns,
    step,
    setStep,
    showFile,
    selectSheet,
    titles,
    journalTypes,
    setJouralTypes,
    currentJournalType,
    setCurrentJournalType,
    currencyTypes,
    currentCurrencyType,
    setCurrentCurrencyType,
    currentEntity,
    setCurrentEntity,
    ws_entities,
    setEntities,
    journalDesc,
    setJournalDesc,
    journalName,
    setJournalName,
  } = useUploadAccounts();

  return (
    <div>
      <LoadForm
        updateColumns={updateColumns}
        loadColumns={loadColumns}
        columns={columns}
        loading={loading}
        sheetName={sheetName}
        workbook={workbook}
        rows={rows}
        makeLoadData={makeLoadData}
        loadData={loadData}
        returnData={returnData}
        vloadColumns={vloadColumns}
        selectSheet={selectSheet}
        step={step}
        setStep={setStep}
        showFile={showFile}
        titles={titles}
        journalTypes={journalTypes}
        currentJournalType={currentJournalType}
        setCurrentJournalType={setCurrentJournalType}
        currencyTypes={currencyTypes}
        currentCurrencyType={currentCurrencyType}
        setCurrentCurrencyType={setCurrentCurrencyType}
        currentEntity={currentEntity}
        setCurrentEntity={setCurrentEntity}
        ws_entities={ws_entities}
        setEntities={setEntities}
        journalDesc={journalDesc}
        setJournalDesc={setJournalDesc}
        journalName={journalName}
        setJournalName={setJournalName}
      />
    </div>
  );
};

export default LedgerLoad;
