import { Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Index = ({ callBackup, value, options }) => {
  const [add, setAdd] = useState(1);
  const [item, setItem] = useState("");
  const [newItem, setNewItem] = useState("");
  const [optionsInternal, setOptionsInternal] = useState([
    "Item 1",
    "Item 2",
    "Item 3",
  ]);

  useEffect(() => {
    if (value) {
      setItem(value);
    }
    if (options) {
      setOptionsInternal(options);
    }
  }, []);

  const handleChange = (event) => {
    let val = event.target.value;
    if (val === "Add") {
      console.log("Add");
      setAdd(3);
    } else {
      setItem(val);
      setAdd(1);
      if (callBackup) {
        callBackup(val);
      }
    }
  };

  const handleSave = () => {
    setItem(newItem);
    setAdd(1);
    if (callBackup) {
      callBackup(newItem);
    }
  };

  const handleCancel = () => {
    setItem(value);
    setAdd(1);
  };

  const handleUpdateChange = (event) => {
    setNewItem(event.target.value);
  };

  return (
    <div>
      {add === 1 && (
        <div>
          <Button
            sx={{ m: 1, height: "26px" }}
            size="small"
            onClick={() => setAdd(2)}
            variant="outlined"
          >
            {item}
          </Button>
        </div>
      )}
      {add === 2 && (
        <div flex>
          <Select
            sx={{ width: "120px" }}
            labelId="demo-select-small"
            value={item}
            size="small"
            onChange={handleChange}
          >
            <MenuItem sx={{ fontWeight: "600" }} key="Add" value="Add">
              Add New
            </MenuItem>
            {optionsInternal.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <CloseIcon size="small" sx={{ ml: 1 }} onClick={() => setAdd(1)} />
        </div>
      )}
      {add === 3 && (
        <div flex>
          <TextField
            sx={{
              ml: 1,
              height: "20px",
              maxHeight: "20px",
              width: "80px",
              fontSize: "12px",
            }}
            size="small"
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => handleUpdateChange(e)}
          />
          <Button
            sx={{ ml: 1, height: "26px" }}
            size="small"
            onClick={() => handleSave()}
            variant="outlined"
          >
            Save
          </Button>
          <Button
            sx={{ ml: 1, height: "26px" }}
            size="small"
            onClick={() => handleCancel()}
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default Index;
