import { Container, Grid } from "@mui/material";
import { useEffect } from "react";

import useReports from "src/views/reporting/useReports";
import DashboardReportPicker from "src/views/reporting/reportView/DashboardReportPicker";
import ReportPanel from "src/views/reporting/reportView/ReportPanel";

const ReportView = ({ navigate }) => {
  const {
    report_type,
    report_id,
    userReports,
    reportPickNoNavigate,
    reportPickStd,
  } = useReports(navigate);

  return (
    <div>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <DashboardReportPicker
              userReports={userReports}
              currentReportId={report_id}
              reportPick={reportPickNoNavigate}
              reportPickStd={reportPickStd}
            />
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <ReportPanel currentReportType={report_type} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ReportView;
