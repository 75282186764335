const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object

  const nbrFormat = nbr => {
    try {
      if (isNaN(nbr)) {
        return nbr;
      } else {
        let nbrFrmt =
          ' ' +
          nbr.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          });

        return nbrFrmt === null ? '' : nbrFrmt;
      }
    } catch (error) {
      return nbr === null ? '' : nbr;
    }
  };

  var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

  var CSV = '';

  //This condition will generate the Label/Header
  if (ShowLabel) {
    let row = '';

    //This loop will extract the label from 1st index of on array
    for (let index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ',';
    }

    //    row = row.slice(0, -2);
    row = row.slice(0, -1);

    //append Label row with line break
    CSV += row + '\r\n';
  }

  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    let row = '';

    //2nd loop will extract each column and convert it in string comma-seprated
    for (let index in arrData[i]) {
      if (
        ![
          'indent',
          'sort',
          'formula',
          'act_type',
          'act_id',
          'userFlag',
          'formula_code',
          'nbr_format',
          'check'
        ].includes(index)
      ) {
        row += '"' + nbrFormat(arrData[i][index]) + '",';
      }
    }

    row.slice(0, row.length - 1);
    //row.slice(0, row.length);

    //add a line break after each row
    CSV += row + '\r\n';
  }

  if (CSV === '') {
    alert('Invalid data');
    return;
  }

  //Generate a file name
  var fileName = 'MyReport_';
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, '_');

  //Initialize file format you want csv or xls
  var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement('a');
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden';
  link.download = fileName + '.csv';

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default JSONToCSVConvertor;
