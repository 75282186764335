import ColumPicker from "src/components/columnPicker";
import ScenarioPicker from "src/components/scenarioPicker";
import BegEndMonths from "src/components/monthsPicker/Months_v1/BegEndMonths";
import EntityPicker from "src/components/entityPicker";
//import CollapseSection from "src/components/filterHeader/collapse";
import Filters from "src/components/filterHeader/filters";
import { Grid } from "@mui/material";

function Index({
  showThousand,
  showMissing,
  multipleScenario,
  multipleEntity,
  columnPicker = true,
}) {
  return (
    <div style={{ padding: "10px" }}>
      <Grid container spacing={1}>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <BegEndMonths />
        </Grid>
        {columnPicker && (
          <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
            <ColumPicker
              showMissing={showMissing}
              showThousand={showThousand}
            />
          </Grid>
        )}
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Filters />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <ScenarioPicker multiple={multipleScenario} />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <EntityPicker multiple={multipleEntity} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Index;
