import * as React from "react";

import PropTypes from "prop-types";
//import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
//import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import PerfectScrollbar from "react-perfect-scrollbar";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, i) => (
          <TableCell
            sx={
              i === 0
                ? {
                    colorx: "white",
                    pl: 2,
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                  }
                : {
                    colorx: "white",
                    pr: 2,
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                  }
            }
            key={column.field + i}
            align={column.numeric ? "right" : "left"}
            padding={column.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === column.field ? order : false}
          >
            <TableSortLabel
              style={{ color: "white", accentColor: "white", hover: "white" }}
              active={orderBy === column.field}
              direction={orderBy === column.field ? order : "asc"}
              onClick={createSortHandler(column.field)}
            >
              {column.title}
              {orderBy === column.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
};

const EnhancedTableToolbar = (props) => {
  const { handleSearch, searchable, title } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>

      {searchable ? (
        <TextField
          id="input-with-icon-textfield"
          type="search"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  searchable: PropTypes.bool.isRequired,
};

const AccountPickerTable = ({
  rows,
  columns,
  dataCallback,
  title,
  width = 600,
  height = 450,
  selectField = "name",
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchable, setSearchable] = React.useState(true);
  //const [searchColumn, setSearchColumn] = React.useState("act_id");

  React.useEffect(() => {
    rows.forEach((row) => {
      let bb = "";
      columns.forEach((item) => {
        bb = bb + String(row[item.field]);
      });
      row["searchMe01"] = bb.toLowerCase();
    });
  }, [columns, rows]);

  const handleSearch = (val) => {
    console.log(val);
    setSearchTerm(val);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (col, row) => {
    if (dataCallback) {
      dataCallback(col, row);
    }
  };

  const isSelected = (selectField) => selected.indexOf(selectField) !== -1;

  const nbrFormat = (nbr, d = 2) => {
    try {
      return nbr.toLocaleString(undefined, {
        maximumFractionDigits: d,
        minimumFractionDigits: d,
      });
    } catch (error) {
      return 0;
    }
  };

  return (
    <Box>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleSearch={handleSearch}
        searchable={searchable}
        title={title}
      />
      <Paper sx={{ width: "100%", overflow: "hidden", mt: 3 }}>
        <TableContainer>
          <PerfectScrollbar>
            <div style={{ height: height }}>
              <Table stickyHeader aria-labelledby="tableTitle" size="small">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .filter((fitem) => {
                      try {
                        if (searchTerm === "") {
                          return fitem;
                        } else if (
                          fitem["searchMe01"].includes(searchTerm.toLowerCase())
                        ) {
                          return fitem;
                        }
                      } catch (error) {
                        return fitem;
                      }
                    })
                    .map((row, index) => {
                      const isItemSelected = isSelected(row[selectField]);
                      //const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={"table-88-" + index}
                            selected={isItemSelected}
                            style={{
                              height: "40px",
                            }}
                          >
                            {columns.map((col, ind) => {
                              return (
                                <TableCell
                                  onClick={(event) => handleClick(col, row)}
                                  component="th"
                                  id={col + "-" + ind + "-" + index}
                                  scope="row"
                                  sx={
                                    col.numeric
                                      ? { textAlign: "right", pr: 2 }
                                      : { textAlign: "left", pl: 2 }
                                  }
                                  key={"table-99-" + ind}
                                >
                                  {col.numeric
                                    ? nbrFormat(row[col.field])
                                    : row[col.field]}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </>
                      );
                    })}

                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={"ddd"}
                    style={{
                      height: "40px",
                    }}
                  >
                    {columns.map((col, ind) => {
                      return (
                        <TableCell
                          component="th"
                          id={col + "-" + ind + "-" + 99}
                          scope="row"
                          sx={
                            col.numeric
                              ? { textAlign: "right", pr: 2 }
                              : { textAlign: "left", pl: 2 }
                          }
                          key={"table-99-" + ind}
                        >
                          {col.numeric
                            ? nbrFormat(
                                rows
                                  .filter((fitem) => {
                                    try {
                                      if (searchTerm === "") {
                                        return fitem;
                                      } else if (
                                        fitem["searchMe01"].includes(
                                          searchTerm.toLowerCase()
                                        )
                                      ) {
                                        return fitem;
                                      }
                                    } catch (error) {
                                      return fitem;
                                    }
                                  })
                                  .reduce((a, b) => a + b[col["field"]], 0)
                              )
                            : ""}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default AccountPickerTable;
