import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
import * as XLSX from "xlsx";
import store from "src/reducers/store";

const useUploadAccounts = () => {
  const [workbook, setWorkbook] = useState({});
  const [sheetName, setSheetName] = useState("");
  const [columns, setColumns] = useState([{}]);
  const [rows, setRows] = useState([{}]);
  const [returnData, setReturnData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [loadData, setLoadData] = useState(false);
  const [currentJournalType, setCurrentJournalType] = useState(1);
  const [currentCurrencyType, setCurrentCurrencyType] = useState("USD");
  const [currentEntity, setCurrentEntity] = useState(1);
  const [journalDesc, setJournalDesc] = useState("FY23 LRP V3");
  const [journalName, setJournalName] = useState("FY23 LRP V3");

  const { ws_entities } = store.getState();

  const { RunAPI } = UseRunAPI();

  const [titles, setTitles] = useState([
    "Pick a Ledger Type",
    "Step 1/6 Load Your Dimension Master File and Choose a Sheet",
    "Step 2/6 Map Your Sheet Columns",
    "Step 3/6 Your Sheet Data",
    "Step 4/6 Validate Your Sheet Data",
    "Step 5/6 Check For Errors",
    "Step 6/6 Load Your Data",
  ]);

  // this will be data from redux

  function ExcelDateToJSDate(date) {
    //    let bb = new Date(Math.round((date - 25569) * 86400 * 1000));
    let bb = new Date(Math.round((date - 25568) * 86400 * 1000));
    let newDate = new Date(bb);

    let dd =
      newDate.getFullYear() +
      "-" +
      ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"][
        newDate.getMonth()
      ] +
      "-" +
      [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ][newDate.getDate() - 1];

    if (dd) {
      return date;
    } else {
      return date;
    }
  }

  // function ExcelDateToJSDate(serial) {
  //   var utc_days = Math.floor(serial - 25569);
  //   var utc_value = utc_days * 86400;
  //   var date_info = new Date(utc_value * 1000);

  //   var fractional_day = serial - Math.floor(serial) + 0.0000001;

  //   var total_seconds = Math.floor(86400 * fractional_day);

  //   var seconds = total_seconds % 60;

  //   total_seconds -= seconds;

  //   var hours = Math.floor(total_seconds / (60 * 60));
  //   var minutes = Math.floor(total_seconds / 60) % 60;

  //   return new Date(
  //     date_info.getFullYear(),
  //     date_info.getMonth(),
  //     date_info.getDate(),
  //     hours,
  //     minutes,
  //     seconds
  //   );
  // }

  const [currencyTypes, setCurrencyTypes] = useState(["USD", "AUD", "GBP"]);

  const [journalTypes] = useState([
    { name: "Miscellaneous", value: "1" },
    { name: "Planning Upload", value: "2" },
    { name: "Payroll", value: "3" },
    { name: "Billing", value: "4" },
    { name: "QuickBooks", value: "5" },
  ]);

  const [vloadColumns] = useState([
    { field: "jv_line", default: "", title: "Jv Line" },
    { field: "date", default: "", title: "Date" },
    { field: "act_code", default: "", title: "Account Code" },
    { field: "MSG", default: "", title: "Error Message" },
    { field: "dim1", default: "", title: "Dimension 1" },
    { field: "dim2", default: "", title: "Dimension 2" },
    { field: "dim3", default: "", title: "Dimension 3" },
    { field: "db_amt", default: "", title: "Debit Amount" },
    { field: "cr_amt", default: "", title: "Credit Amount" },
    { field: "amount", default: "", title: "Amount", numeric: true },
    { field: "input_source", default: "", title: "Input Source" },
    { field: "jv_line_desc", default: "", title: "JV Line Description" },
    { field: "desc_2", default: "", title: "Desc2" },
  ]);

  const [loadColumns, setLoadColumns] = useState([
    {
      field: "",
      load_name: "date",
      title: "Date",
      required: true,
      similar: [
        "Month",
        "month",
        "MONTH",
        "Period",
        "period",
        "PERIOD",
        "Date",
        "date",
        "DATE",
      ],
    },
    {
      field: "",
      load_name: "act_code",
      title: "Account Code",
      required: true,
      similar: [
        "act_code",
        "act_code",
        "ACT_CODE",
        "account",
        "Account",
        "ACCOUNT",
        "account_code",
        "Account_Code",
        "Account Number",
        "ACCOUNT_CODE",
      ],
    },
    {
      field: "",
      load_name: "dim1",
      title: "Dim 1 Code",
      required: false,
      similar: [
        "Dim 1 Code",
        "dim 1 code",
        "DIM 1 CODE",
        "dim1",
        "Segment 1",
        "segment 1",
        "SEGMENT 1",
        "SEG 1",
        "seg 1",
        "SEG1",
      ],
    },
    {
      field: "",
      load_name: "dim2",
      title: "Dim 2 code",
      required: false,
      similar: [
        "dim 2 code",
        "dim2",
        "DIM 2 CODE",
        "Segment 2",
        "segment 2",
        "SEGMENT 2",
        "SEG 2",
        "seg 2",
        "SEG2",
      ],
    },
    {
      field: "",
      load_name: "dim3",
      title: "Dim 3 Code",
      required: false,
      similar: [
        "Dim 3 Code",
        "dim 3 code",
        "DIM 3 CODE",
        "dim3",
        "Segment 3",
        "segment 3",
        "SEGMENT 3",
        "SEG 3",
        "seg 3",
        "SEG3",
      ],
    },
    {
      field: "",
      load_name: "db_amt",
      title: "Debit Amount",
      required: false,
      similar: [
        "Debit Amount",
        "debit amount",
        "DEBIT AMOUNT",
        "Debit",
        "debit",
        "DEBIT",
      ],
    },
    {
      field: "",
      load_name: "cr_amt",
      title: "Credit Amount",
      required: false,
      similar: [
        "Credit Amount",
        "credit amount",
        "CREDIT AMOUNT",
        "Credit",
        "credit",
        "CREDIT",
      ],
    },
    {
      field: "",
      load_name: "amount",
      title: "Amount",
      numeric: true,
      required: true,
      similar: ["Amount", "amount", "AMOUNT", "Amt", "amt", "AMT"],
    },

    {
      field: "",
      load_name: "input_source",
      title: "Input Source",
      required: false,
      similar: [
        "Input Source",
        "input source",
        "INPUT SOURCE",
        "source",
        "Source",
        "SOURCE",
      ],
    },
    {
      field: "",
      load_name: "jv_line_desc",
      title: "JV Line Description",
      required: false,
      similar: [
        "JV Line Description",
        "jv line description",
        "JV LINE DESCRIPTION",
        "Description",
        "description",
        "DESCRIPTION",
      ],
    },
    {
      field: "",
      load_name: "desc_2",
      title: "Description 2",
      required: false,
      similar: ["Desc2", "Desc_2", "desc_2", "desc2"],
    },
  ]);

  const LedgerAPI = async (data, action) => {
    try {
      setLoading(true);
      let json = {
        area: "ledger",
        action: action,
        journalType: currentJournalType,
        currencyType: currentCurrencyType,
        entity: currentEntity,
        journalDesc: journalDesc,
        journalName: journalName,
        updateRow: data.filter((f) => f.amount < 0 || f.amount > 0),
      };

      const awsURL = process.env.REACT_APP_URL_LOAD;
      const response = await RunAPI(json, awsURL);
      console.log("response", response);

      setReturnData(response["data"]);

      console.log("RESPONSE BY ACCOUNT:", response);
      setLoading(false);
      return "OK";
    } catch (error) {
      setReturnData({ MSG_OVERALL: "Error", MSG: error.message });

      return error;
    }
  };

  //================================================================================================
  //======================= Form stuff below =====================================
  //================================================================================================
  //================================================================================================

  const showFile = (e) => {
    setLoading(true);
    const f = e.target.files[0];
    console.log("f", f.name);
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbookRaw = XLSX.read(bstr, { type: "binary" });
      setWorkbook(workbookRaw);
      setLoading(false);
    };

    reader.readAsBinaryString(f);
    //setLoading(false);
  };

  const makeLoadData = (action) => {
    const LData = [];
    rows.forEach((row) => {
      const newRow = {};
      loadColumns.forEach((col) => {
        newRow[col.load_name] = row[col.field];
      });
      LData.push(newRow);
    });
    setLoadData(LData);
    LedgerAPI(LData, action);
    return LData;
  };

  const _makeMatch = (cols) => {
    loadColumns.forEach((g, i) => {
      g.similar.forEach((t) => {
        const index = cols.findIndex((s) => s.field === t);
        if (index > -1) {
          console.log(cols[index].field);
          loadColumns[i].field = cols[index].field;
        }
      });
    });
  };

  const updateColumns = (newField, i) => {
    console.log(newField + " " + i);

    let cc = [...loadColumns];
    cc[i].field = newField;

    //setAllRequired(rr === 0);

    setLoadColumns(cc);
  };

  const selectSheet = (sheet) => {
    // choose sheet
    try {
      setSheetName(sheet);
      //
      var data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
        header: 1,
      });
      //

      var cc = data[0].map((x) => {
        let c = {};
        c.field = x;
        c.title = x;
        return c;
      });

      //console.log(data);
      //setSelectedSheet(data);
      setColumns(cc);

      _makeMatch(cc);

      let nRows = [];
      console.log("WORKSHEET DATA", data);

      data
        .filter((fitem) => fitem.amount !== 0)
        .forEach((row, index) => {
          if (index > 0 && row.length > 3) {
            let r = {};
            data[0].forEach((col, index) => {
              if (["Date", "date"].includes(col)) {
                r[col] = ExcelDateToJSDate(row[index]);
              } else {
                r[col] = row[index];
              }
            });
            nRows.push(r);
          }
        });

      setRows(nRows);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    showFile,
    selectSheet,
    updateColumns,
    makeLoadData,
    loadColumns,
    loadData,
    columns,
    loading,
    sheetName,
    workbook,
    rows,
    returnData,
    vloadColumns,
    step,
    setStep,
    titles,
    journalTypes,
    currentJournalType,
    setCurrentJournalType,
    currencyTypes,
    currentCurrencyType,
    setCurrentCurrencyType,
    currentEntity,
    setCurrentEntity,
    ws_entities,
    journalDesc,
    setJournalDesc,
    journalName,
    setJournalName,
  };
};

export default useUploadAccounts;
