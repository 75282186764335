// import useUploadAccounts from "./useRecon";
// import LoadForm from "./LoadForm";

import { Grid, TextField, Button, Typography } from "@mui/material";
import ExportButton from "src/components/saveAsCsv/ExportButton";
import SimpleSelectTable from "src/components/tables/simpleSelectTable/SimpleSelectTable";
import SimpleTable from "src/components/tables/simpleTable/SimpleTable";
import CircularProgress from "@mui/material/CircularProgress";
import useRecon from "./useRecon";

const Index = () => {
  const {
    data_a,
    dataCallback,
    noMatchArray,
    aAmountTotal,
    bAmountTotal,
    compare,
    nbrFormat,
    columns,
    rows,
    quickWins,
    diffAmountTotal,
    solution,
    busy,
    pasteFile,
    findAnswers,
    dataCallback1,
    selectionModel,
    checkAmount,
    tagMatch,
  } = useRecon();

  return (
    <div style={{ marginBottom: "40px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            {nbrFormat(aAmountTotal)}
          </Typography>
          <TextField
            id="a"
            label="Paste Here"
            multiline
            fullWidth={true}
            rows={10}
            onChange={(e) => pasteFile(e, "a")}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            {nbrFormat(bAmountTotal)}
          </Typography>
          <TextField
            id="b"
            label="Paste Here"
            multiline
            fullWidth={true}
            rows={10}
            onChange={(e) => pasteFile(e, "b")}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log("A: ", data_a);
              compare();
            }}
          >
            Compare
          </Button>
          {noMatchArray.length < 25 && (
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="primary"
              onClick={() => {
                findAnswers();
              }}
            >
              Find
            </Button>
          )}
          {busy && <CircularProgress color="secondary" sx={{ ml: 1 }} />}
          <Typography variant="h6" gutterBottom sx={{ marginTop: 1 }}>
            {`Difference ${nbrFormat(diffAmountTotal)}`}
          </Typography>

          <br></br>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {solution.length > 0 && (
          <Grid item xs={12}>
            <SimpleTable
              title="Quick Wins"
              rows={solution}
              height={"100%"}
              columns={columns}
              dataCallback={dataCallback1}
              tableKey="id"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SimpleSelectTable
            title="Compare Entries"
            rows={rows.filter((row) => row.match === "No Match")}
            height={"100%"}
            columns={columns}
            dataCallback={dataCallback1}
            selectionModel={selectionModel}
            tableKey="id"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "100px" }}>
          <Grid item xs={4}>{`Check Amount: ${nbrFormat(checkAmount)}`}</Grid>
          <div>
            <ExportButton
              title={"By Account Report"}
              data={rows}
              refresh={compare}
              showFirstRow={true}
            />
          </div>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                tagMatch();
              }}
            >
              Tag Matched
            </Button>
          </Grid>
          <SimpleSelectTable
            title="Compare Entries"
            rows={rows}
            height={"100%"}
            columns={columns}
            dataCallback={dataCallback}
            selectionModel={selectionModel}
            tableKey="id"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
