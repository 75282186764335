import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";

const ChartTest = ({
  title = "",
  rows,
  colorMap = "pink",
  columns,
  height = 500,
  type = "line",
  options,
}) => {
  const [series, setSeries] = useState([
    {
      name: "",
      data: [0],
    },
  ]);

  const [categories, setCategories] = useState([""]);

  useEffect(() => {
    if (rows) {
      const data = rows.map((row) => row["fat"]);
      const data1 = rows.map((row) => row["protein"]);
      setSeries([
        { name: "Name", data },
        { name: "Name1", data1 },
      ]);
    }
    setCategories(rows.map((row) => row["name"]));
  }, [rows]);

  const theme = useTheme();

  const optionsx = {
    xaxis: {
      categories: categories,
    },
    colors: [theme.palette.primary.main, theme.palette.primary.main],
  };
  //

  return (
    <div>
      <ReactApexChart options={options} />
    </div>
  );
};

export default ChartTest;
