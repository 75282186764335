import SimpleTable from "src/components/tables/simpleTable/SimpleTable";
import useDimensions from "./useDimensions";
import ExportButton from "src/components/saveAsCsv/ExportButton";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";

const Segments = () => {
  const { data, getData, currentDim, setCurrentDim, dims, setDims, columns } =
    useDimensions();

  const handleChange = (event) => {
    setCurrentDim(event.target.value);
    getData();
  };

  const dataCallback = () => {
    console.log("DATA CALLBACK");
  };

  useEffect(() => {
    getData();
  }, [currentDim]);

  return (
    <div>
      <Box sx={{ minWidth: 120, width: 300, margin: "10px" }}>
        <FormControl fullWidth>
          <Select
            disabled={false}
            id="uy6"
            value={currentDim}
            onChange={handleChange}
            size="small"
          >
            {dims.map((item, index) => (
              <MenuItem key={"dimnbr" + index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <ExportButton
        data={data}
        title="Dimensions"
        refresh={getData}
        showFirstRow={true}
      />
      <SimpleTable
        title="Dimension Table"
        rows={data}
        columns={columns}
        dataCallback={dataCallback}
        height={700}
      />
      {/* <div sx={{ m: 3 }}>{JSON.stringify(data)}</div>
      <div sx={{ m: 3 }}>{JSON.stringify(currentDim)}</div> */}
    </div>
  );
};

export default Segments;
