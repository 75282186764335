import ByAccountView from "src/actions/1_ByAccountReport";
import AcrossAccountView from "src/actions/2_AcrossAccountView";
import LedgerReport1 from "src/actions/3_LedgerReport";
import LedgerUpoadListingNew from "src/actions/5_LedgerUploadListngNew";
import LedgerReportDesc from "src/actions/6_LedgerReportDesc2";
import LedgerReportProject from "src/actions/7_LedgerReportDesc2Project";
import LedgerLoad from "src/actions/5005_LedgerLoad";
import LoadAccounts from "src/actions/5002_uploadAccounts";
import LoadDimensions from "src/actions/5003_uploadDimensions";
import ExcelLoaderQBOJV from "src/actions/5006_ExcelLoader_QBOJV";
import DimensionReport from "src/actions/5004_DimensionReport";
import AccountSettings from "src/actions/5001_Account";
import TableTest from "src/actions/9001_tableExamples";
import ReconTest from "src/actions/9002_ReconTool";

//
//import LedgerUpoadListing from "src/views/reporting/reports/LedgerUploadListng";
// import UploadJournalFromExcel from "src/views/uploads/uploads/UploadJournaFromExcel";
// import LoadData from "src/views/uploads/uploads/UploadGLData";

const ReportPanel = ({ currentReportType = 1 }) => {
  return (
    <div style={{ height: "100%" }}>
      {currentReportType === 1 && <ByAccountView />}
      {currentReportType === 2 && <AcrossAccountView />}
      {currentReportType === 3 && <LedgerReport1 />}
      {currentReportType === 5 && <LedgerUpoadListingNew />}
      {currentReportType === 6 && <LedgerReportDesc />}
      {currentReportType === 7 && <LedgerReportProject />}
      {currentReportType === 5005 && <LedgerLoad />}
      {currentReportType === 5002 && <LoadAccounts />}
      {currentReportType === 5003 && <LoadDimensions />}
      {currentReportType === 5006 && <ExcelLoaderQBOJV />}
      {currentReportType === 5004 && <DimensionReport />}
      {currentReportType === 5001 && <AccountSettings />}
      {currentReportType === 9001 && <TableTest />}
      {currentReportType === 9002 && <ReconTest />}
    </div>
  );
};

export default ReportPanel;
