import { useEffect } from "react";
import UserDataLogic from "src/components/cognito/UserDataLogic";

const LoginTest = () => {
  const { runMeCheck } = UserDataLogic();

  useEffect(() => {
    runMeCheck();
  }, []);

  return <></>;
};

export default LoginTest;
