import {
  Button,
  List,
  ListItem,
  LinearProgress,
  Box,
  MenuItem,
  Select,
} from "@mui/material";
import * as Excel from "exceljs";
import * as FileSaver from "file-saver";

import BegEndMonths from "src/components/monthsPicker/Months_v1/BegEndMonths";
import useReports from "src/views/reporting/useReports";
import useByAccountView from "../reports/ByAccountReport/useByAccountView";
import useAcrossAccount from "../reports/AcrossAccountView/useAcrossAccounts";
import { useState } from "react";

import { useEffect } from "react";

const ReportView = ({ navigate }) => {
  const { data } = useReports(navigate);
  const { getData_grp } = useByAccountView();
  const { getData_across } = useAcrossAccount();
  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [currentGroup, setCurrentGroup] = useState("");

  useEffect(() => {
    const sort = "report_group";
    let grp = [];
    let lastGrp = "";
    data.map((row) => {
      if (row[sort] !== lastGrp) {
        grp.push(row[sort]);
      }
      lastGrp = row[sort];
    });
    setGroupList(grp);
  }, [data]);

  const _reportSetVariables = (item) => {
    try {
      let cc = JSON.parse(item.report_spec);

      if (cc.versions === undefined) {
        cc.versions = [];
      }
      if (cc.accounts === undefined) {
        cc.accounts = [];
      }
      if (cc.columns === undefined) {
        cc.columns = [];
      }
      if (cc.dim1 === undefined) {
        cc.dim1 = [];
      }
      if (cc.dim2 === undefined) {
        cc.dim2 = [];
      }
      if (cc.dim3 === undefined) {
        cc.dim3 = [];
      }

      cc["report_id"] = item.report_id;
      cc["report_name"] = item.rep_name;
      cc["report_type"] = item.report_type;
      cc["report_dashboard"] = item.report_dashboard;

      return cc;
    } catch (error) {
      console.log("error", error);
      return {};
    }
  };

  const get_data_type_1 = async (spec) => {
    const removeArray = [
      "indent",
      "sort",
      "formula",
      "act_type",
      "act_id",
      "userFlag",
      "formula_code",
      "nbr_format",
      "check",
      "searchMe01",
    ];
    //
    const JSONData = await getData_grp(spec);
    //
    const cols = Object.keys(JSONData[0]).filter(function (el) {
      return !removeArray.includes(el);
    });

    console.log("COLUMNS:", cols);

    let rr = [];
    let rows = [];

    // if (true) {
    //   rows.push(cols);
    // }

    JSONData.map((row) => {
      rr = [];
      cols.map((col) => rr.push(row[col]));
      rows.push(rr);
    });

    return rows;
  };

  const get_data_type_2 = async (spec) => {
    const JSONData = await getData_across(spec);
    let rows = [];
    let header = Object.keys(JSONData[0]);
    rows.push(header);
    JSONData.map((row) => {
      let rr = [];
      header.map((col) => rr.push(row[col]));
      rows.push(rr);
    });

    return rows;
  };

  const runReports = async () => {
    setLoading(true);
    console.log("runReports");
    let workbook = new Excel.Workbook();
    let tempName = null;

    const subSet = await data.filter(
      (fitem) =>
        fitem.report_group === currentGroup &&
        [1, 2].includes(fitem.report_type)
    );
    console.log("Current Group:", currentGroup);
    console.log("subSet", subSet);

    const totalCnt = subSet.length;
    let rows = [];

    let index = 0;
    for await (const item of subSet) {
      index = index + 1;
      let spec = _reportSetVariables(item);
      console.log("SPEC: ", index, spec);

      if (spec.report_type === 1) {
        rows = await get_data_type_1(spec);
      } else if (spec.report_type === 2) {
        rows = await get_data_type_2(spec);
      } else {
        console.log("ERROR: report_type not found");
      }
      console.log("ROWS", index, rows);

      //=============================================
      tempName = item.rep_name.replace(/ /g, "_");
      const worksheet = workbook.addWorksheet(tempName, {
        properties: { tabColor: { argb: "89CFF0" } },
      });

      worksheet.addRows(rows);
    }

    if (totalCnt === index) {
      let fileName = "downloadReport.xlsx";
      workbook.xlsx.writeBuffer().then(function (buffer) {
        // done buffering
        const data = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(data, fileName);
      });
      setLoading(false);
    }
  };

  const handleSelectChange = (event) => {
    setCurrentGroup(event.target.value);
    console.log("handleSelectChange", event.target.value);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <BegEndMonths />
      <Box sx={{ width: "100%", bgc: "grey", m: 3 }}>
        {loading && <LinearProgress />}
      </Box>
      <Select
        sx={{ width: "120px", marginRight: "5px" }}
        labelId="demo-select-small"
        size="small"
        onChange={handleSelectChange}
      >
        {groupList.map((item, index) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <Button variant="contained" onClick={() => runReports()}>
        Run Reports
      </Button>
      <List dense={true}>
        {data
          .filter(
            (fitem) =>
              [1, 2].includes(fitem.report_type) &
              (fitem.report_group === currentGroup)
          )
          .map((item, index) => (
            <ListItem>{item.rep_name}</ListItem>
          ))}
      </List>
    </div>
  );
};

export default ReportView;
