import { Card, CardContent, Typography, Stack } from "@mui/material";
import AccountsPicker from "src/components/accountPicker";
import DimensionPicker from "src/components/dimensionPicker";

function Filters() {
  return (
    <Card raised={true} sx={{ height: "100%", width: "100%", borderRadius: 2 }}>
      <CardContent sx={{}}>
        <Typography variant="h6" id="tableTitle" sx={{ mr: 2 }} component="div">
          Filters
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ flexWrap: "wrap", gap: 2 }}
          spacing={2}
        >
          <AccountsPicker />
          <DimensionPicker dimNbr={1} />
          <DimensionPicker dimNbr={2} />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Filters;
