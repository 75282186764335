import { Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import useReports from "src/views/reporting/useReports";
import ReportPanel from "./ReportPanel";
import ReportSaveButton from "./ReportSaveButton";

const ReportView = ({ navigate }) => {
  const {
    report_type,
    report_name,
    report_id,
    reportTypeName,
    add_pov,
    update_pov,
  } = useReports(navigate);

  useEffect(() => {}, []);

  return (
    <div style={{ marginTop: "20px" }}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {report_name !== "" && (
            <Grid item lg={8} sm={8} xl={8} xs={8}>
              <Typography variant="h2" component="h3">
                {report_name}
              </Typography>
            </Grid>
          )}
          {report_name === "" && (
            <Grid item lg={8} sm={8} xl={8} xs={8}>
              <Typography variant="h4">
                {reportTypeName(report_type)}
              </Typography>
            </Grid>
          )}

          <Grid item lg={3} sm={3} xl={3} xs={3} sx={{ textAlign: "right" }}>
            <ReportSaveButton
              reportType={report_type}
              reportId={report_id}
              reportName={report_name}
              add_pov={add_pov}
              update_pov={update_pov}
            />
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <ReportPanel currentReportType={report_type} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ReportView;
