import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
import store from "src/reducers/store";

const useReports = (navigate) => {
  const state = store.getState();
  const { currentItem, reportList, userReports, sharedReports, report_groups } =
    state;
  const { report_type, report_name, report_id, report_dashboard } = currentItem;
  const [data, setData] = useState(userReports);
  const { RunAPI } = UseRunAPI();

  const add_pov = async (reportType, reportName) => {
    // console.log('ADD POV');
    try {
      let json = {
        area: "user_pov",
        action: "add_pov",
        povName: reportName,
        reportType: reportType,
      };
      const awsURL = process.env.REACT_APP_URL_USERDATA;
      const response = await RunAPI(json, awsURL);

      store.dispatch({
        type: "POV_UPDATE",
        payload: response.data,
      });
    } catch (error) {
      alert(error.message);
      console.log("ERROR");
      console.log(error);
    }
  };

  const update_pov = async (report_id) => {
    // console.log('ADD POV');
    try {
      let json = {
        area: "user_pov",
        action: "update_pov",
        povId: report_id,
      };
      const awsURL = process.env.REACT_APP_URL_USERDATA;
      const response = await RunAPI(json, awsURL);

      // console.log('UPDATE POV DATA', response.data);
      store.dispatch({
        type: "POV_UPDATE",
        payload: response.data,
      });
    } catch (error) {
      alert(error.message);
      console.log("ERROR");
      console.log(error);
    }
  };

  const delete_pov = async (povId) => {
    try {
      let json = {
        area: "user_pov",
        action: "delete_pov",
        povId: povId,
      };
      const awsURL = process.env.REACT_APP_URL_USERDATA;
      const response = await RunAPI(json, awsURL);
      setData(response["data"]);
      store.dispatch({
        type: "POV_UPDATE",
        payload: response.data,
      });
    } catch (error) {
      setData([
        {
          rep_name: " ",
          report_spec: " ",
          report_type: 0,
          favorite: null,
        },
      ]);
    }
  };

  const favorite_pov = async (row) => {
    // console.log('DELETE POV DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD' + pov);
    let action = row.favorite ? "unfavorite_pov" : "favorite_pov";

    try {
      let json = {
        area: "user_pov",
        action: action,
        povId: row.report_id,
      };
      const awsURL = process.env.REACT_APP_URL_USERDATA;
      const response = await RunAPI(json, awsURL);
      setData(response["data"]);
      store.dispatch({
        type: "POV_UPDATE",
        payload: response.data,
      });
    } catch (error) {
      setData([
        {
          report_id: " ",
          report_spec: " ",
          report_type: 0,
          favorite: null,
        },
      ]);
    }
  };

  const share_pov = async (row) => {
    // console.log('DELETE POV DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD' + pov);
    let action = row.share_report ? "unShare_pov" : "share_pov";

    try {
      let json = {
        area: "user_pov",
        action: action,
        povId: row.report_id,
      };
      const awsURL = process.env.REACT_APP_URL_USERDATA;
      const response = await RunAPI(json, awsURL);
      setData(response["data"]);
      store.dispatch({
        type: "POV_UPDATE",
        payload: response.data,
      });
    } catch (error) {
      setData([
        {
          report_id: " ",
          report_spec: " ",
          report_type: 0,
          favorite: null,
        },
      ]);
    }
  };

  const update_group = async (row, value) => {
    // console.log('DELETE POV DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD' + pov);
    //let action = row.share_report ? "unShare_pov" : "share_pov";

    try {
      let json = {
        area: "user_pov",
        action: "update_report_group",
        reportGroup: value,
        povId: row.report_id,
      };
      const awsURL = process.env.REACT_APP_URL_USERDATA;
      const response = await RunAPI(json, awsURL);
      setData(response["data"]);
      store.dispatch({
        type: "POV_UPDATE",
        payload: response.data,
      });
    } catch (error) {
      setData([
        {
          report_id: " ",
          report_spec: " ",
          report_type: 0,
          favorite: null,
        },
      ]);
    }
  };

  const _reportSetVariables = async (item) => {
    //
    console.log("SPEC: ", item.report_spec);
    //
    try {
      let cc = JSON.parse(item.report_spec);

      if (cc.versions === undefined) {
        cc.versions = [];
      }
      if (cc.accounts === undefined) {
        cc.accounts = [];
      }
      if (cc.cols === undefined) {
        cc.cols = [];
      }
      if (cc.dim1 === undefined) {
        cc.dim1 = [];
      }
      if (cc.dim2 === undefined) {
        cc.dim2 = [];
      }
      if (cc.dim3 === undefined) {
        cc.dim3 = [];
      }

      cc["report_id"] = item.report_id;
      cc["report_name"] = item.rep_name;
      cc["report_type"] = item.report_type;
      cc["report_dashboard"] = item.report_dashboard;

      await store.dispatch({
        type: "POV_OPEN",
        payload: cc,
      });
      //
    } catch (error) {
      console.log("error", error);
    }
  };

  const reportTypeName = (report_type) => {
    try {
      return reportList
        .filter((fitem) => fitem.report_type === report_type)
        .map((fitem) => fitem.title)[0];
    } catch (error) {
      return " ";
    }
  };

  const reportPickStd = async (item) => {
    try {
      let cc = {};
      cc["report_id"] = "";
      cc["report_name"] = "";
      cc["report_type"] = item.report_type;
      cc["report_dashboard"] = false;

      await store.dispatch({
        type: "POV_OPEN_STD",
        payload: cc,
      });
      //
      navigate("/workspace/report");
      //
    } catch (error) {
      console.log("error", error);
    }
  };

  const reportPick = async (item) => {
    item.report_dashboard = false;
    await _reportSetVariables(item);
    navigate("/workspace/report");
  };

  const reportPickNoNavigate = async (item) => {
    item.report_dashboard = true;
    await _reportSetVariables(item);
  };

  const reportPickDB = async (item) => {
    item.report_dashboard = true;
    await _reportSetVariables(item);
    navigate("/workspace/report");
  };

  return {
    add_pov,
    update_pov,
    delete_pov,
    favorite_pov,
    reportPick,
    reportPickStd,
    reportPickDB,
    reportTypeName,
    reportPickNoNavigate,
    reportList,
    data,
    report_type,
    report_name,
    report_id,
    report_dashboard,
    userReports,
    sharedReports,
    report_groups,
    share_pov,
    update_group,
  };
};

export default useReports;
