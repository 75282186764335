import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";

const Layout0 = (props) => {
  const { tabLink, title, level } = props;

  return (
    <>
      <TopBar tabLink={tabLink} title={title} level={level} />
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Layout0;
