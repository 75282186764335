import React from "react";
import useTableTest from "./useTableTest";
import SimpleTable from "src/components/tables/simpleTable/SimpleTable";
import SimpleSelectTable from "src/components/tables/simpleSelectTable/SimpleSelectTable";
import TableDialog from "src/components/tables/simpleDialogTable/TableDialog";
import SimpleBarChart from "src/components/charts/simpleBarChart";

import FilterHeader from "src/components/filterHeader";
import ReactTest1 from "src/components/tables/expandTable";
//
import { useSelector } from "react-redux";

function Index() {
  const { rows, columns } = useTableTest();
  const [selectionModel, setSelectionModel] = React.useState([]);

  const { currentItem, ws_accounts, ws_settings } = useSelector(
    (state) => state
  );

  const dataCallback = (data) => {
    //console.log("dataCallback", data);
    setSelectionModel(data);
  };

  return (
    <div style={{ padding: "30px" }}>
      <ReactTest1 />
      dd
      <div style={{ padding: "10px" }}>
        <TableDialog
          buttonTitle="Open Table Dialog"
          title="Simple Dialog Table"
          rows={rows}
          columns={columns}
          dataCallback={dataCallback}
        />
      </div>
      <div style={{ width: "800px" }}>
        <SimpleTable
          title="Simple Table"
          rows={rows}
          columns={columns}
          dataCallback={dataCallback}
        />{" "}
      </div>
      <div style={{ paddingTop: "30px" }}>
        <SimpleSelectTable
          title="Simple Select Table"
          rows={rows}
          columns={columns}
          dataCallback={dataCallback}
        />{" "}
      </div>
      <div style={{ paddingTop: "30px" }}>
        <SimpleBarChart
          title="Simple Select Table"
          rows={rows}
          columns={columns}
          dataCallback={dataCallback}
        />{" "}
      </div>
      {/*       <div style={{ padding: "30px" }}>
        <p>Selection Model: {JSON.stringify(selectionModel, null, 2)}</p>
      </div>
      <div style={{ padding: "30px" }}>
        ws_settings: {JSON.stringify(ws_settings)}
      </div>
      <div style={{ padding: "30px" }}>
        ws_accounts: {JSON.stringify(ws_accounts)}
      </div>
      <div style={{ padding: "30px" }}>
        currentItem: {JSON.stringify(currentItem)}
      </div> */}
    </div>
  );
}

export default Index;
