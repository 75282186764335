import * as Excel from "exceljs";
import * as FileSaver from "file-saver";
import store from "src/reducers/store";

const JSONToXLSXConvertor = async (
  JSONData,
  ReportTitle,
  ShowLabel,
  tableColumns = []
) => {
  console.log("JSON DATA EXCEL:", JSONData);
  // COLUMNS
  const state = store.getState();
  const { columns } = state.currentItem;
  let cols = [];
  let hdr = [];

  const removeArray = [
    "indent",
    "sort",
    "formula",
    "act_type",
    "act_id",
    "userFlag",
    "formula_code",
    "nbr_format",
    "check",
    "searchMe01",
  ];

  if (tableColumns.length > 0) {
    cols = tableColumns.map((item) => item.field);
    hdr = tableColumns.map((item) => item.title);
  } else {
    cols = Object.keys(JSONData[0]).filter(function (el) {
      return !removeArray.includes(el);
    });
    hdr = Object.keys(JSONData[0]).filter(function (el) {
      return !removeArray.includes(el);
    });
  }

  console.log("COLUMNS:", cols);

  let rr = [];
  let rows = [];

  if (ShowLabel) {
    rows.push(hdr);
  }
  //

  JSONData.map((row) => {
    rr = [];
    cols.map((col) => rr.push(row[col]));
    rows.push(rr);
  });

  console.log("ROWS", rows);

  let workbook = new Excel.Workbook();

  var worksheet = workbook.addWorksheet("My Sheet", {
    properties: { tabColor: { argb: "89CFF0" } },
  });

  worksheet.addRows(rows);

  JSONData.map((item, i) => {
    if (item.indent > 2) {
      worksheet.getRow(i + 1).font = {
        bold: true,
      };
    }
  });

  console.log("COLUMNS:", columns.length);

  for (let i = 0; i < columns.length; i++) {
    worksheet.getRow(i + 1).font = {
      bold: true,
    };
  }

  worksheet.getColumn(1).width = 20;

  cols.map((col, i) => {
    if (i > 0) {
      worksheet.getColumn(i + 1).numFmt = "#,##0.00";
      worksheet.getColumn(i + 1).width = 15;
    }
  });

  let fileName = "downloadReport.xlsx";
  //  const excelBuffer: any = workbook.xlsx.writeBuffer();

  workbook.xlsx.writeBuffer().then(function (buffer) {
    // done buffering
    const data = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(data, fileName);
  });
};

export default JSONToXLSXConvertor;
