import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";

import SimpleSelectTable from "src/components/tables/simpleSelectTable/SimpleSelectTable";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AccountsPicker = () => {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([{}]);
  const [label, setLabel] = useState("");
  const { currentItem, ws_accounts, actType } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    let cc = ws_accounts.map((item, index) => {
      item.name = item.act_id;
      item.tt = actType[item.type];
      return item;
    });
    setRows(cc);

    setSelectionModel(currentItem.accounts);
  }, [currentItem.accounts]);

  useEffect(() => {
    let bb = {};
    if (currentItem.accounts.length === 1) {
      bb = ws_accounts.find((item) => item.act_id === currentItem.accounts[0]);
      setLabel("Account  = " + bb.name2);
    } else if (currentItem.accounts.length > 1) {
      setLabel(currentItem.accounts.length + " Accounts Selected");
    } else {
      setLabel("All Accounts Included");
    }
  }, [currentItem.accounts]);

  const [columns, setColumns] = useState([
    {
      field: "name2",
      numeric: false,
      disablePadding: true,
      title: "Name",
    },
    {
      field: "tt",
      numeric: false,
      disablePadding: true,
      title: "Account Type",
    },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDataCallback = (data) => {
    console.log("handlePicked", data);
    setSelectionModel(data);
  };

  const handleClear = (data) => {
    console.log("handle clear");
    dispatch(
      {
        type: "SET_CURRENT_ACCOUNTS",
        payload: [],
      },
      []
    );
  };

  const handleSave = () => {
    dispatch(
      {
        type: "SET_CURRENT_ACCOUNTS",
        payload: selectionModel,
      },
      []
    );
    setOpen(false);
  };

  return (
    <>
      <Chip
        sx={{ mr: 2 }}
        variant={currentItem.accounts.length > 0 ? "filled" : "outlined"}
        label={label}
        color="primary"
        onDelete={handleClear}
        onClick={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Account Selection
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <SimpleSelectTable
            title="Accounts Selection"
            rows={rows}
            columns={columns}
            dataCallback={handleDataCallback}
            selectionModel={currentItem.accounts}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default AccountsPicker;
