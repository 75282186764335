import useLedgerUploadListing from "./useLedgerUploadListing";
import ExportButton from "src/components/saveAsCsv/ExportButton";
import SimpleSelectTable from "src/components/tables/simpleSelectTable/SimpleSelectTable";
import { Button, Select, Box, MenuItem, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import BegEndMonths from "src/components/monthsPicker/Months_v1/BegEndMonths";
import ScenarioPicker from "src/components/scenarioPicker";
import EntityPicker from "src/components/entityPicker";
import CollapseSection from "src/components/filterHeader/collapse";

const Index = () => {
  const {
    getData,
    data,
    columns,
    ws_scenarios,
    updateIds,
    setUpdateIds,
    updateEntity,
    currentItem,
    updateVersion,
    loadCube,
    selectionModel,
    setSelectionModel,
  } = useLedgerUploadListing();

  const dataCallback = (data) => {
    setUpdateIds(data);
    console.log("dataCallback", data);
  };

  const handleUpdateVerion = (val) => {
    updateVersion(val);
  };

  const handleUpdateCube = () => {
    loadCube();
  };

  useEffect(() => {
    updateEntity(1);
    getData();
  }, [
    currentItem.entity,
    currentItem.version,
    currentItem.beg,
    currentItem.end,
  ]);

  return (
    <div style={{ padding: "10px", marginBottom: "400px" }}>
      <CollapseSection>
        <Grid container spacing={3}>
          <Grid item>
            <BegEndMonths />
          </Grid>
          <Grid item>
            <ScenarioPicker multiple={false} />
          </Grid>
          <Grid item>
            <Button
              size="small"
              sx={{ ml: 2, p: 1 }}
              variant="outlined"
              onClick={() => handleUpdateCube()}
            >
              Update Reporting
            </Button>
          </Grid>
        </Grid>
      </CollapseSection>

      <ExportButton
        title={"Ledger Listing Report"}
        data={data}
        refresh={getData}
        showFirstRow={true}
        tableColumns={columns}
      />
      <SimpleSelectTable
        title="Journal Entries"
        rows={data}
        height={"100%"}
        columns={columns}
        dataCallback={dataCallback}
        selectionModel={selectionModel}
        tableKey="jv_id_period"
      />
      {/* <div sx={{ m: 30 }}>{JSON.stringify(updateIds)}</div>
      <div sx={{ m: 30 }}>{JSON.stringify(selectionModel)}</div> */}
    </div>
  );
};

export default Index;
