import { useSelector, useDispatch } from "react-redux";
//import {useState, useEffect} from 'react'
import { Chip, Button } from "@mui/material";

const Index = ({ showThousand, showMissing }) => {
  const { currentItem, test1 } = useSelector((state) => state);

  const dispatch = useDispatch();

  const thousands = () => {
    dispatch({
      type: "SET_THOUSANDS",
      payload: !currentItem.thousands,
    });
  };

  const updateMissing = () => {
    dispatch({
      type: "SET_MISSING",
      payload: !currentItem.missing,
    });
    // dispatch({
    //   type: "SET_THEME",
    //   payload: "",
    // });
  };
  // const updateTheme = (nbr) => {
  //   dispatch({
  //     type: "SET_THEME",
  //     payload: nbr,
  //   });
  // };

  return (
    <>
      {showThousand && (
        <Chip
          sx={{ ml: 6 }}
          size="medium"
          key={"ssxx"}
          label={currentItem["thousands"] ? "In Thousands" : "In Dollars"}
          id={"sdfsdfsdfse"}
          clickable
          variant={currentItem["thousands"] ? "default" : "outlined"}
          color="primary"
          onClick={() => thousands()}
        />
      )}
      {showMissing && (
        <Chip
          size="medium"
          sx={{ ml: 2 }}
          key={"ssxrrx"}
          label={
            currentItem["missing"]
              ? "exclude zero balance"
              : "include zero balance"
          }
          id={"sdfsdfsssdfse"}
          clickable
          variant={currentItem["missing"] ? "default" : "outlined"}
          color="primary"
          onClick={() => updateMissing()}
        />
      )}
    </>
  );
};

export default Index;
