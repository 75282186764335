import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState, useEffect, Fragment } from "react";

import { data, cols } from "./data";

const Row = ({ row, childRows, columns }) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      {console.log("COLUMNS", columns)}
      <TableRow style={{ borderBottom: "solid lightgrey 2px" }}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        {columns.map((column) => (
          <TableCell>{row[column.value]}</TableCell>
        ))}
      </TableRow>
      {open &&
        childRows.map((childRow) => (
          <TableRow>
            <TableCell> </TableCell>
            {columns.map((column) => (
              <TableCell>{childRow[column.value]}</TableCell>
            ))}
          </TableRow>
        ))}
    </Fragment>
  );
};

const Index = () => {
  const [open, setOpen] = useState(false);

  const [columns, setColumns] = useState(cols);
  const [rows, setRows] = useState([{}]);

  // make a id for each row in data
  const dataWithId = data.map((item, index) => {
    const id = index + 1;
    return { ...item, id };
  });

  // create a parent child relationship with dataWithId when actType is 1 and parent actType is 10
  const dataWithParent = dataWithId.map((item) => {
    if (item.actType === 1) {
      try {
        // parent id is find the next row with actType 10
        let parent = dataWithId.filter(
          (x) => x.id > item.id && x.actType === 10
        )[0];

        return { ...item, parent: parent.id };
      } catch (error) {
        return { ...item };
      }
    } else {
      return item;
    }
  });

  const dataWithParent2 = dataWithParent.map((item) => {
    if (item.actType === 10) {
      try {
        // parent id is find the next row with actType 10
        let parent = dataWithId.filter(
          (x) => x.id > item.id && x.actType === 11
        )[0];

        return { ...item, parent: parent.id };
      } catch (error) {
        return { ...item };
      }
    } else {
      return item;
    }
  });

  const dataWithParent3 = dataWithParent2.map((item) => {
    if (item.actType === 11) {
      return { ...item, parent: 0 };
    } else {
      return item;
    }
  });

  useEffect(() => {
    setColumns(cols);
    setRows(dataWithParent3);
  }, []);

  return (
    <div>
      <div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell key="dsfddsee"> </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .filter((x) => x.parent === 0)
                .map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    columns={columns}
                    childRows={rows.filter((x) => row.id === x.parent)}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div sx={{ m: 3 }}>
        <p>
          {JSON.stringify(
            rows.filter((x) => x.id < 20),
            2,
            null
          )}
        </p>
      </div>
      <div sx={{ m: 3 }}>{JSON.stringify(columns)}</div>
    </div>
  );
};

export default Index;
