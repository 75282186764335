import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "src/components/cognito/Account";
import UserDataLogic from "src/components/cognito/UserDataLogic";

import * as Yup from "yup";

const Login = () => {
  const navigate = useNavigate();
  const [msg, setMsg] = useState({});
  const { authenticate } = useContext(AccountContext);
  //const pwrdLink = `https://nibea-10202.auth.us-west-2.amazoncognito.com/forgotPassword?client_id=3ufqvh2462c3hvgdhoot8ichlp&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://localhost:5000/login`;
  const [showPassword, setSetShowPassword] = useState(false);
  const { runMeCheck } = UserDataLogic();

  const logMeIn = async (values) => {
    const { email, password } = values;

    try {
      let data = await authenticate(email, password);
      console.log("DATA", data);
      if (data.code === "NEW_PASSWORD_REQUIRED") {
        navigate("/loginchange");
      } else {
        console.log("authenticate", data);
        runMeCheck();
      }
    } catch (error) {
      setMsg("Email or Password incorrect");
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (values) => {
              await new Promise((resolve) => setTimeout(resolve, 500));

              logMeIn(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography style={{ color: "red" }} variant="h2">
                    {msg.message}
                  </Typography>
                </Box>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h3">
                    Log in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Log into Planning!
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box my={2} style={{ display: "flex" }}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    variant="outlined"
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setSetShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </Box>

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Sign Up limited to private beta users only.
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
