import { useEffect } from "react";
import useLedgerReport from "./useLedgerReport";
import ExportButton from "src/components/saveAsCsv/ExportButton";
import FilterHeader from "src/components/filterHeader";
import SimpleTable from "src/components/tables/simpleTable/SimpleTable";
import { Button, Box } from "@mui/material";

const LedgerReport = () => {
  const { columns, data, getData, reportPickStd } = useLedgerReport();

  useEffect(() => {
    console.log("ByAccountTable useEffect");
    getData();
  }, []);

  const dataCallback = (data) => {
    console.log("dataCallback", data);
  };

  return (
    <div>
      <FilterHeader
        multipleEntity={true}
        multipleScenario={false}
        columnPicker={false}
      />

      <Box display="flex">
        <ExportButton
          title={"Ledger Report"}
          data={data}
          refresh={getData}
          showFirstRow={true}
          tableColumns={columns}
        />

        <Button
          size="small"
          sx={{ ml: 10, height: 40, marginTop: 1 }}
          variant="contained"
          onClick={reportPickStd}
        >
          Back
        </Button>
      </Box>
      <SimpleTable
        title="Ledger Detail"
        rows={data}
        columns={columns}
        dataCallback={dataCallback}
        selectField="jvKey"
      />
    </div>
  );
};

export default LedgerReport;
