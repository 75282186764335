import Init from "src/utils/getInitials";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
} from "@mui/material";

import { red } from "@mui/material/colors";

const WorkspaceCard = (props) => {
  const { item, getUserAndWsData } = props;

  return (
    <Card key={item.ws_id + "_card"} raised={true}>
      <CardContent onClick={() => getUserAndWsData(item.ws_id)}>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar sx={{ m: 4, bgcolor: red[500], width: 56, height: 56 }}>
            {Init(item.ws_desc)}
          </Avatar>
          <Button variant="outlined" color="primary">
            {item.ws_desc}{" "}
          </Button>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          key={item.ws_id + "open"}
          onClick={() => getUserAndWsData(item.ws_id)}
        >
          Open
        </Button>
      </CardActions>
    </Card>
  );
};

export default WorkspaceCard;
