import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fab } from "@mui/material";
import { useState } from "react";

const Index = (props) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div>
      <Fab
        size="small"
        color="primary"
        sx={{ m: 1 }}
        onClick={() => setCollapse(!collapse)}
      >
        {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Fab>
      {!collapse && props.children}
    </div>
  );
};

export default Index;
