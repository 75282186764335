import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material/";

import FavoriteIcon from "@mui/icons-material/Star";
import FavoriteBorderIcon from "@mui/icons-material/StarBorder";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate } from "react-router-dom";
import useReports from "src/views/reporting/useReports";

const ReportListings = () => {
  const navigate = useNavigate();

  const {
    delete_pov,
    favorite_pov,
    reportPick,
    reportPickDB,
    reportPickStd,
    reportTypeName,
    reportList,
    userReports,
    data,
  } = useReports(navigate);

  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleDeletePOV = () => {
    delete_pov(selectedRow.report_id);
    setOpen(false);
  };

  const handleDeletePOVConfirm = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PerfectScrollbar>
      <div>
        <Card>
          <CardHeader
            title="Standard Reports"
            style={{
              marginTop: "1px",
              paddingVertical: "4px",
            }}
          />
          <CardContent>
            <TableContainer>
              <Table stickyHeader aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>type</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {reportList.map((row, i) => (
                    <TableRow
                      key={"keyAA" + i}
                      hover={true}
                      onClick={() => reportPickStd(row)}
                    >
                      <TableCell>{row.title}</TableCell>
                      {process.env.REACT_APP_MODE === "TEST" && (
                        <TableCell>{row.report_type}</TableCell>
                      )}
                      <TableCell>{row.category}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    </PerfectScrollbar>
  );
};

export default ReportListings;
