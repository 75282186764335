import AccountTable from "./AccountTable";
import useAccounts from "./useAccounts";
import ExportButton from "src/components/saveAsCsv/ExportButton";

const AccountSettings = () => {
  const {
    data,
    setData,
    open,
    setOpen,
    selectedRow,
    setSelectedRow,
    rowData,
    setRowData,
    getData,
    dataSort,
    actTypeOptions,
  } = useAccounts();

  return (
    <div>
      <ExportButton
        data={data.map((item) => {
          return {
            line: item.line,
            "Account Name": item.act_name,
            "Account Number": item.act_number,
            "Account Type": item.act_type_b,
            "Account Id": item.act_ID,
            Formula: item.formula,
          };
        })}
        title="Accounts"
        refresh={getData}
        showFirstRow={true}
      />
      <AccountTable
        open={open}
        setOpen={setOpen}
        rowData={rowData}
        setRowData={setRowData}
        data={data}
        setData={setData}
        dataSort={dataSort}
        getData={getData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        actTypeOptions={actTypeOptions}
      />
    </div>
  );
};

export default AccountSettings;
