import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
import { useSelector, useDispatch } from "react-redux";
import store from "src/reducers/store";

const useAcrossAccount = () => {
  const { currentItem } = store.getState();
  const [data, setData] = useState([{ Account: "No Data" }]);
  const [columns, setColumns] = useState([]);
  const [actMap, setActMap] = useState([]);
  const { RunAPI } = UseRunAPI();
  const dispatch = useDispatch();

  const getData_across = async (obj) => {
    try {
      let json = {
        area: "dataout",
        action: "get_data",
        versions: obj.versions,
        dim1: obj.dim1,
        dim2: obj.dim2,
        dim3: obj.dim3,
        accounts: obj.accounts,
        columns: obj.columns,
        report_id: obj.report_id,
      };

      const awsURL = process.env.REACT_APP_URL_DATAOUT;
      const response = await RunAPI(json, awsURL);

      console.log("RESPONSE BY ACCOUNT:", response);
      //setActMap(response["actMap"]);
      let ndata = response["data"];
      //   let cols = Object.keys(ndata[0]);
      //   setColumns(cols);
      //   setData(ndata);
      return ndata;
    } catch (error) {
      console.error(error);
      return [{ Account: "No Data" }];
    }
  };

  const getData = async () => {
    try {
      let json = {
        area: "dataout",
        action: "get_data",
      };

      const awsURL = process.env.REACT_APP_URL_DATAOUT;
      const response = await RunAPI(json, awsURL);

      console.log("RESPONSE BY ACCOUNT:", response);
      setActMap(response["actMap"]);
      let ndata = response["data"];
      let cols = Object.keys(ndata[0]);
      setColumns(cols);
      setData(ndata);
    } catch (error) {
      console.error(error);
    }
  };

  const nbrFormat = (row, col, thousands) => {
    let nbr = row[col];
    try {
      if (thousands) {
        nbr = nbr / 1000;
        return nbr.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      } else {
        return nbr.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      }
    } catch (error) {
      return 0;
    }
  };

  const nbrFormat_3 = (nbr, d = 2) => {
    try {
      return nbr.toLocaleString(undefined, {
        maximumFractionDigits: d,
        minimumFractionDigits: d,
      });
    } catch (error) {
      return 0;
    }
  };

  const nbrFormat_2 = (ind, row, col) => {
    if (ind < currentItem.columns.length) {
      return (
        <div style={{ textAlign: "left", width: "150px" }}>{row[col]}</div>
      );
    }

    try {
      let nbr = row[col];
      let fnbr = nbr.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      return <div style={{ textAlign: "right" }}>{fnbr}</div>;
    } catch (error) {
      return <div style={{ textAlign: "right" }}> </div>;
    }
  };

  const formatHeader = (ind, col) => {
    try {
      if (ind < currentItem.columns.length) {
        return <div style={{ textAlign: "left", width: "150px" }}>{col}</div>;
      } else {
        return <div style={{ textAlign: "right" }}>{col}</div>;
      }
    } catch (error) {
      return <div style={{ textAlign: "right" }}>{col} </div>;
    }
  };

  const handleClickCell = (index, row, col) => {
    console.log("CLICKED COL:", col);

    if (index < currentItem.columns.length) {
      return;
    }

    let act = actMap[index];
    dispatch(
      {
        type: "SET_CURRENT_ACCOUNTS",
        payload: [act],
      },
      []
    );

    let cc = {};
    cc["report_id"] = "";
    cc["report_name"] = "";
    cc["report_type"] = 3;
    cc["report_dashboard"] = false;

    dispatch({
      type: "POV_OPEN_STD",
      payload: cc,
    });
  };

  return {
    data,
    getData,
    columns,
    nbrFormat,
    nbrFormat_2,
    actMap,
    handleClickCell,
    getData_across,
    formatHeader,
  };
};

export default useAcrossAccount;
