const INITIALIZE = {
  currentItem: {
    ws_id: -1,
    ws_desc: "Work Space",
    dim1: [],
    dim2: [],
    dim3: [],
    dim4: [],
    versions: [0],
    entities: [1, 2, 3, 4],
    version: 0,
    entity: 1,
    accounts: [],
    beg: "2023-01",
    end: "2023-12",
    columns: ["scenario"],
    report_name: "",
    report_id: "",
    report_type: -1,
    report_dashboard: false,
    report_group: "",
    thousands: 0,
    missing: 0,
    collapseSection: false,
  },
  user: {
    email: "",
    adm: 0,
    isAuthenticated: false,
    userName: "",
    custName: "",
  },
  workspaces: [
    { ws_id: 0, ws_desc: " ", group_name: "", item_name: "", adm: 0 },
  ],
  ws_settings: {
    year: "Year",
    qtr: "Quarter",
    ytd: "YTD/YTG",
    period: "Month",
    scenario: "Scenario",
    entity: "Entity",
    dim: { 1: "", 2: "", 3: "", 4: "" },
    img_url: "",
  },
  ws_entities: [
    { id: 0, name: "Management Company" },
    { id: 1, name: "Holdings" },
  ],
  ws_accounts: [{ id: 0, name: "", type: 0, name2: "" }],
  actType: {
    1: "Asset",
    2: "Liability",
    3: "Owner Equity",
    4: "Revenue",
    5: "Expense",
    6: "Formula",
    11: "Sub-Total",
    12: "Total",
  },
  ws_dim: {
    1: [{ id: 0, dim_code: "", attr1: "", attr2: "", attr3: "", attr4: "" }],
    2: [{ id: 0, dim_code: "", attr1: "", attr2: "", attr3: "", attr4: "" }],
    3: [{ id: 0, dim_code: "", attr1: "", attr2: "", attr3: "", attr4: "" }],
  },
  ws_scenarios: [{ name: "", id: 1, status: 1 }],
  report_groups: [
    { group_id: "psuyet", group_name: "QBR Reports" },
    { group_id: "yshfqp", group_name: "Quartely  Reports" },
  ],
  userReports: [
    {
      report_id: "",
      rep_name: "",
      report_spec: "",
      report_group: "",
      report_type: 0,
    },
  ],
  sharedReports: [
    {
      report_id: "",
      rep_name: "",
      report_spec: "",
      report_type: 0,
      update_user: "",
    },
  ],
  reportList: [
    {
      title: "By Account Report",
      report_type: 1,
      category: "Report",
    },
    {
      title: "Across Account Report",
      report_type: 2,
      category: "Report",
    },
    {
      title: "Journal Detail Report",
      report_type: 3,
      category: "Report",
    },
    {
      title: "** New Upload Ledger Listings Report",
      report_type: 5,
      category: "Report",
    },
    {
      title: "Vendor Report",
      report_type: 6,
      category: "Report",
    },
    {
      title: "Vendor Report With Projects",
      report_type: 7,
      category: "Report",
    },

    {
      title: "Upload Journal from Excel",
      report_type: 5005,
      category: "Upload",
    },
    {
      title: "Upload Account Configuration from Excel",
      report_type: 5002,
      category: "Upload",
    },
    {
      title: "Upload Dimension Configuration from Excel",
      report_type: 5003,
      category: "Upload",
    },
    {
      title: "Format QBO Journal File from Quickbooks",
      report_type: 5006,
      category: "Format",
    },

    {
      title: "Dimension Settings",
      report_type: 5004,
      category: "Setting Report",
    },
    {
      title: "Report - Accounts",
      report_type: 5001,
      category: "Setting Report",
    },
    {
      title: "Table Test Template",
      report_type: 9001,
      category: "Test",
    },
    {
      title: "Recon Test",
      report_type: 9002,
      category: "Test",
    },
  ],
  uploadList: [
    { title: "Upload Report1", page_type: 101 },
    { title: "Upload Report2", page_type: 102 },
  ],
  busy: false,
  fab: true,
  themeId: 0,
  currentChatStream: {
    Chatid: 0,
    entities: [],
    accounts: [],
    dim1: [],
    dim2: [],
    scenarios: [],
    columns: [],
    actions: [],
    begDate: "2301",
    endData: "2312",
  },
  currentMessages: [
    {
      outputType: "systemOutputText",
      content: "Welcome to Chat",
      sections: {
        entities: [],
        accounts: [],
        dim1: [],
        dim2: [],
        scenarios: [],
        actions: [],
      },
    },
  ],

  metaData: {}, // for chat lookups
};

const userDataReducer = (state = INITIALIZE, action) => {
  console.log("ACTION TYPE: ", action.type);
  console.log("ACTION PAYLOAD: ", action.payload);

  switch (action.type) {
    case "WS_LIST_UPDATE":
      state.workspaces = action.payload["data"];
      state.user.userName = action.payload["userName"];
      return {
        ...state,
      };
    case "WS_SETTINGS_UPDATE":
      // user
      state.user.userName = action.payload["userName"];
      state.user.email = action.payload["userEmail"];
      // workspace settings
      state.ws_settings.dim[1] = action.payload["data"]["ws_settings"]["dim1"];
      state.ws_settings.dim[2] = action.payload["data"]["ws_settings"]["dim2"];
      state.ws_settings.dim[3] = action.payload["data"]["ws_settings"]["dim3"];
      state.ws_settings.dim[4] = action.payload["data"]["ws_settings"]["dim4"];
      state.ws_settings.img_url =
        action.payload["data"]["ws_settings"]["img_url"];
      // currentItems
      state.currentItem.ws_id = action.payload["data"]["ws_settings"]["ws_id"];
      state.currentItem.ws_desc =
        action.payload["data"]["ws_settings"]["ws_desc"];
      // ws_items
      state.ws_accounts = action.payload["data"]["ws_metadata"]["accounts"];
      state.ws_dim[1] = action.payload["data"]["ws_metadata"]["dim1"];
      state.ws_dim[2] = action.payload["data"]["ws_metadata"]["dim2"];
      state.ws_dim[3] = action.payload["data"]["ws_metadata"]["dim3"];
      state.ws_scenarios = action.payload["data"]["ws_metadata"]["scenario"];
      // userReports
      state.userReports = action.payload["data"]["userReports"];
      state.sharedReports = action.payload["data"]["sharedReports"];
      // enitities
      state.ws_entities = action.payload["data"]["entities"];

      return {
        ...state,
      };
    case "SET_BEG_DATE":
      state.currentItem["beg"] = action.payload;
      return {
        ...state,
      };
    case "SET_END_DATE":
      state.currentItem["end"] = action.payload;
      return {
        ...state,
      };
    case "SET_DATES":
      state.currentItem["beg"] = action.payload.beg;
      state.currentItem["end"] = action.payload.end;
      return {
        ...state,
      };
    case "ADD_CHAT_MESSAGE":
      state.currentMessages.push(action.payload);
      return {
        ...state,
      };

    case "UPDATE_CHAT_STREAM":
      state.currentChatStream = action.payload;
      return {
        ...state,
      };

    case "SET_CURRENT_VERSIONS":
      state.currentItem["versions"] = action.payload;
      return {
        ...state,
      };
    case "SET_CURRENT_ENTITIES":
      state.currentItem["entities"] = action.payload;
      return {
        ...state,
      };
    case "SET_CURRENT_VERSION":
      state.currentItem["version"] = action.payload;
      return {
        ...state,
      };
    case "SET_CURRENT_ENTITY":
      state.currentItem["entity"] = action.payload;
      return {
        ...state,
      };
    case "UPDATE_CURRENT_DIMENSION":
      let dimNbr = action.payload.dimNbr;
      let dimName = { 1: "dim1", 2: "dim2", 3: "dim3", 4: "dim4" }[dimNbr];
      state.currentItem[dimName] = action.payload.value;
      return {
        ...state,
      };
    case "SET_CURRENT_WS_ID":
      state.currentItem["ws_id"] = action.payload;
      return {
        ...state,
      };
    case "SET_CURRENT_COLUMNS":
      state.currentItem["columns"] = action.payload;
      return {
        ...state,
      };
    case "SET_CURRENT_ACCOUNTS":
      state.currentItem["accounts"] = action.payload;
      return {
        ...state,
      };
    case "SET_CURRENT_DIM1":
      state.currentItem["dim1"] = action.payload;
      return {
        ...state,
      };
    case "SET_CURRENT_DIM2":
      state.currentItem["dim2"] = action.payload;
      return {
        ...state,
      };

    case "POV_OPEN_UPLOAD":
      state.currentItem["report_name"] = action.payload.report_name;
      state.currentItem["report_id"] = action.payload.report_id;
      state.currentItem["report_type"] = action.payload.report_type;
      state.currentItem["report_dashboard"] = action.payload.report_dashboard;

      return {
        ...state,
      };
    case "POV_OPEN_STD":
      state.currentItem["report_name"] = action.payload.report_name;
      state.currentItem["report_id"] = action.payload.report_id;
      state.currentItem["report_type"] = action.payload.report_type;
      state.currentItem["report_dashboard"] = action.payload.report_dashboard;
      return {
        ...state,
      };

    case "POV_OPEN":
      state.currentItem["columns"] = action.payload.columns;
      state.currentItem["accounts"] = action.payload.accounts;
      state.currentItem["dim1"] = action.payload.dim1;
      state.currentItem["dim2"] = action.payload.dim2;
      state.currentItem["dim3"] = action.payload.dim3;
      state.currentItem["versions"] = action.payload.versions;
      state.currentItem["report_name"] = action.payload.report_name;
      state.currentItem["report_id"] = action.payload.report_id;
      state.currentItem["report_type"] = action.payload.report_type;
      state.currentItem["report_dashboard"] = action.payload.report_dashboard;
      return {
        ...state,
      };
    case "POV_UPDATE":
      state.userReports = action.payload;
      return {
        ...state,
      };
    case "SET_ENTITIES_VERSIONS":
      state.currentChatStream["entities"] = action.payload;
      return {
        ...state,
      };
    case "UPDATE_CHAT_FILTERS":
      console.log("UPDATE_CHAT_FILTERS", action.payload);
      state.currentChatStream[action.payload.dim] = action.payload.value;
      return {
        ...state,
      };
    case "SET_CHAT_DIM1":
      state.currentChatStream["dim1"] = action.payload;
      return {
        ...state,
      };
    case "SET_CHAT_DIM2":
      state.currentChatStream["dim2"] = action.payload;
      return {
        ...state,
      };
    case "SET_CHAT_COLUMNS":
      state.currentChatStream["columns"] = action.payload;
      return {
        ...state,
      };
    case "SET_CHAT_BEGDATE":
      state.currentChatStream["begDate"] = action.payload;
      return {
        ...state,
      };
    case "SET_CHAT_ENDDATE":
      state.currentChatStream["endDate"] = action.payload;
      return {
        ...state,
      };
    case "SET_CHAT_VERSIONS":
      state.currentChatStream["versions"] = action.payload;
      return {
        ...state,
      };
    case "SET_COGNITO":
      state.user.email = action.payload;
      state.user.isAuthenticated = true;
      return {
        ...state,
      };
    case "SET_METADATA":
      state.metaData = action.payload;
      return {
        ...state,
      };
    case "RESET_COGNITO":
      state.user.email = "none";
      state.user.isAuthenticated = false;
      return {
        ...state,
      };

    case "SET_BUSY_TRUE":
      state.busy = true;
      return {
        ...state,
      };

    case "SET_BUSY_FALSE":
      state.busy = false;
      return {
        ...state,
      };

    case "SET_THOUSANDS":
      state.currentItem["thousands"] = action.payload;
      return {
        ...state,
      };
    case "SET_MISSING":
      state.currentItem["missing"] = action.payload;
      return {
        ...state,
      };
    case "SET_FAB":
      state.fab = action.payload;
      return {
        ...state,
      };

    case "SET_COLLAPSE":
      state.currentItem["collapseSection"] = action.payload;
      return {
        ...state,
      };
    case "SET_THEME":
      state["themeId"] = action.payload;
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
};

export default userDataReducer;
