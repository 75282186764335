import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import SuppressMissing from "src/components/suppressMissing";
import Stack from "@mui/material/Stack";

import ColumnPicker from "./ColumnPicker";
import ColumnPickerDrag from "./ColumnPickerDrag";
import UseColumnPicker from "./useColumnPicker";

function TableDialog({ title }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {
    getItems,
    handleDragEnd,
    handleCheckBoxChange,
    saveChanges,
    changed,
    items,
    staticItems,
    columns,
    handleStaticCheckChange,
  } = UseColumnPicker();

  useEffect(() => {
    try {
      getItems();
    } catch (error) {
      console.log("ERROR TT:", error);
    }
  }, []);

  const handleSave = () => {
    saveChanges();
    handleClose();
  };

  return (
    <div>
      <Card raised={true} sx={{ borderRadius: 2 }}>
        <CardContent>
          <Typography
            variant="h6"
            id="tableTitle"
            sx={{ mr: 2 }}
            component="div"
          >
            Columns
          </Typography>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Chip
              sx={{ mr: 2 }}
              label="Column Picker"
              color="primary"
              onClick={() => handleClickOpen()}
            />
            <SuppressMissing showMissing={true} />
          </Stack>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>Sort Columns</DialogTitle>
        <DialogContent sx={{ p: 6 }}>
          <Box display="flex">
            <ColumnPickerDrag
              title={title}
              items={items.filter((fitem) => fitem.picked === true)}
              handleDragEnd={handleDragEnd}
              handleStaticCheckChange={handleStaticCheckChange}
            />
            <ColumnPicker
              title={title}
              items={staticItems.filter((item) =>
                ["date"].includes(item.group)
              )}
              handleStaticCheckChange={handleStaticCheckChange}
            />
            <ColumnPicker
              title={title}
              items={staticItems.filter((item) =>
                ["dim1"].includes(item.group)
              )}
              handleStaticCheckChange={handleStaticCheckChange}
            />
            <ColumnPicker
              title="fgfff"
              items={staticItems.filter((item) =>
                ["dim2"].includes(item.group)
              )}
              handleStaticCheckChange={handleStaticCheckChange}
            />
            <ColumnPicker
              title="fgfff"
              items={staticItems.filter((item) =>
                ["dim3"].includes(item.group)
              )}
              handleStaticCheckChange={handleStaticCheckChange}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Update</Button>
        </DialogActions>
        {/*  <div>{JSON.stringify(staticItems)}</div>*/}
      </Dialog>
    </div>
  );
}

export default TableDialog;
