import { Box, Button } from "@mui/material";
import JSONToCSVConvertor from "./JSONToCSVConvertor";
import JSONToXLSXConvertor from "./JSONToXLSXConvertor";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const ExportButton = ({ data, title, refresh, showFirstRow, tableColumns }) => {
  const { busy } = useSelector((state) => state);
  return (
    <Box display="flex" flexDirection="row" alignItems="stretch" padding={1}>
      <Button variant="outlined" onClick={() => refresh()}>
        Refresh
      </Button>
      <Button
        variant="outlined"
        onClick={() => JSONToCSVConvertor(data, title, showFirstRow)}
      >
        csv
      </Button>
      <Button
        variant="outlined"
        onClick={() =>
          JSONToXLSXConvertor(data, title, showFirstRow, tableColumns)
        }
      >
        Excel
      </Button>
      {busy && <CircularProgress color="secondary" sx={{ ml: 1 }} />}
    </Box>
  );
};

export default ExportButton;
