import SimpleTable from "src/components/tables/simpleTable/SimpleTable";
import React, { useEffect } from "react";

const AccountTable = ({
  setOpen,
  setRowData,
  getData,
  data,
  selectedRow,
  setSelectedRow,
  actTypeOptions,
}) => {
  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Line",
      field: "line",
    },
    {
      title: "Account Name",
      field: "act_name",
    },
    {
      title: "name",
      field: "name",
    },
    {
      title: "Account Type",
      field: "act_type_b",
    },
    {
      title: "Account Number",
      field: "act_number",
    },

    {
      title: "Account Id",
      field: "act_ID",
    },

    {
      title: "Formula",
      field: "formula",
    },
    {
      title: "Update User",
      field: "update_user",
    },

    {
      title: "Last Update",
      field: "last_update_f",
    },
  ];

  const [selectionModel, setSelectionModel] = React.useState([]);

  const dataCallback = (data) => {
    //console.log("dataCallback", data);
    setSelectionModel(data);
  };

  return (
    <div style={{ marginRight: "10px" }}>
      <SimpleTable
        title="Accounts"
        rows={data}
        columns={columns}
        dataCallback={dataCallback}
        height="700px"
      />

      <div style={{ padding: "30px" }}>
        {/* {<p>{JSON.stringify(selectionModel, null, 2)}</p>} */}
      </div>
    </div>
  );
};

export default AccountTable;
