import useUploadDimensions from "./useUploadDimensions";
import LoadForm from "./LoadForm";

const UploadDimensions = () => {
  const {
    updateColumns,
    loadColumns,
    columns,
    loading,
    sheetName,
    workbook,
    rows,
    makeLoadData,
    loadData,
    returnData,
    vloadColumns,
    step,
    setStep,
    showFile,
    selectSheet,
    titles,
    dimensions,
    setCurrentDimension,
    currentDimension,
  } = useUploadDimensions();

  return (
    <div>
      <LoadForm
        updateColumns={updateColumns}
        loadColumns={loadColumns}
        columns={columns}
        loading={loading}
        sheetName={sheetName}
        workbook={workbook}
        rows={rows}
        makeLoadData={makeLoadData}
        loadData={loadData}
        returnData={returnData}
        vloadColumns={vloadColumns}
        selectSheet={selectSheet}
        step={step}
        setStep={setStep}
        showFile={showFile}
        titles={titles}
        dimensions={dimensions}
        setCurrentDimension={setCurrentDimension}
        currentDimension={currentDimension}
      />
    </div>
  );
};

export default UploadDimensions;
