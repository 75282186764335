import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
import { useDispatch, useSelector } from "react-redux";

const useLedgerReport = () => {
  const dispatch = useDispatch();

  const [columns] = useState([
    { field: "act_name", title: "Account Name" },
    { field: "act_number", title: "Account Number" },
    { field: "year_month", title: "Month" },
    { field: "entity_desc", title: "Entity" },
    { field: "jv_desc", title: "Journal Description" },
    { field: "dim1_code", title: "Dept Code" },
    { field: "dim1_name", title: "Department" },
    { field: "dim2_code", title: "Prog Code" },
    { field: "dim2_name", title: "Program" },
    { field: "desc_2", title: "Vendor" },
    { field: "jv_line_desc", title: "JV Line Desc" },
    { field: "currency_code", title: "Curr" },
    { field: "avg_rate", title: "Rate", numeric: true },
    { field: "amount", title: "Amt Input", numeric: true },
    { field: "amount2", title: "Amt Report", numeric: true },
    { field: "date", title: "Date" },
  ]);
  const [data, setData] = useState([{}]);
  const { RunAPI } = UseRunAPI();

  const getData = async () => {
    try {
      let json = {
        area: "ledger",
        action: "get_level1_data",
      };

      const awsURL = process.env.REACT_APP_URL_DATAOUT;
      const response = await RunAPI(json, awsURL);
      console.log("response", response);

      let bb = response.data;

      bb = bb.map((item) => {
        return {
          ...item,
          date: item.date.substring(0, 10),
          create_date: item.create_date.substring(0, 10),
          //avg_rate: nbrFormat(item.avg_rate, 4),
        };
      });

      //sum amount
      let sumAmount = bb.reduce((a, b) => a + b.amount, 0);
      //sum amount2
      let sumAmount2 = bb.reduce((a, b) => a + b.amount2, 0);
      //sum avg_rate
      //let sumAvgRate = bb.reduce((a, b) => a + b.avg_rate, 0);
      //add total row to bb
      bb.push({
        act_name: "Total",
        act_number: "",
        year_month: "",
        entity_desc: "",
        jv_desc: "",
        dim1_code: "",
        dim1_name: "",
        dim2_code: "",
        dim2_name: "",
        dim3_code: "",
        jv_line_desc: "",
        currency_code: "",
        avg_rate: "",
        amount: sumAmount,
        amount2: sumAmount2,
        date: "",
      });

      // bb = bb.map((item) => {
      //   return {
      //     ...item,
      //     amount: nbrFormat(item.amount),
      //     amount2: nbrFormat(item.amount2),
      //   };
      // });

      setData(bb);
    } catch (error) {
      console.log("error", error);
      setData([{}]);
    }
  };

  // const nbrFormat = (nbr, d = 2) => {
  //   try {
  //     return nbr.toLocaleString(undefined, {
  //       maximumFractionDigits: d,
  //       minimumFractionDigits: d,
  //     });
  //   } catch (error) {
  //     return 0;
  //   }
  // };

  const reportPickStd = async () => {
    console.log("reportPickStd");
    try {
      let cc = {};
      cc["report_id"] = "";
      cc["report_name"] = "";
      cc["report_type"] = 1;
      cc["report_dashboard"] = false;

      await dispatch({
        type: "POV_OPEN_STD",
        payload: cc,
      });
      //
      // navigate("/workspace/report");
      //
    } catch (error) {
      console.log("error", error);
    }
  };

  return {
    columns,
    data,
    getData,
    reportPickStd,
  };
};

export default useLedgerReport;
