import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
//import store from 'src/reducers/store';

const useAccounts = () => {
  //const state = store.getState();
  //const { currentItem } = state;
  const [sortOpen, setSortOpen] = useState(false);
  //
  const [data, setData] = useState([
    {
      id: 0,
      act_name: "",
      act_id: 0,
      act_type: 0,
      act_indent: 0,
      calcAndFormula: "",
      formula: "",
      formula_code: "",
      calcAmtAct: 0,
      calcRateAct: 0,
      act_group: 0,
      total_flag: 0,
    },
  ]);

  const [rowData, setRowData] = useState({
    id: 0,
    act_name: "",
    act_id: 0,
    act_type: 0,
    act_indent: 0,
    formula: "",
    formula_code: "",
    calcAmtAct: 0,
    calcRateAct: 0,
    act_group: 0,
    total_flag: 0,
  });

  // form errors
  //

  const [newFormula, setNewFormula] = useState("");
  const [current_position, setCurrent_position] = useState(0);
  const [new_postion, setNew_postion] = useState(0);

  // button action
  //const [action, setAction] = useState('noAction');
  const [open, setOpen] = useState(false);
  const [openLoad, setOpenLoad] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  //
  const { RunAPI } = UseRunAPI();

  const updateAccount = async () => {
    let row = rowData;

    //
    if (row.act_type == 1) {
      _updateAccount(row);
    } else if (row.act_type == 2) {
      _updateAccount(row);
    } else if (row.act_type == 3) {
      _updateAccount(row);
    } else if (row.act_type == 4) {
      _updateAccount(row);
    } else if (row.act_type == 5) {
      let calcAmtName = data
        .filter((itm) => itm.act_id == row.calcAmtAct)
        .map((item) => item);
      let calcRateName = data
        .filter((itm) => itm.act_id == row.calcRateAct)
        .map((item) => item);
      let calcNames =
        calcAmtName[0].act_name + " * " + calcRateName[0].act_name;

      //  console.log('CALC NAMES: ', calcNames);
      row["calculation"] = calcNames;

      _updateAccount(row);
    } else if (row.act_type == 7) {
      _updateAccount(row);
    } else if (row.act_type == 8) {
      _updateAccount(row);
    } else if (row.act_type == 9) {
      _updateAccount(row);
    } else if (row.act_type == 6) {
      row["formula"] = newFormula;
      row["formula_code"] = _convertFormula(newFormula);
      _updateAccount(row);
    } else {
      alert("NO ACCOUNT TYPE");
    }

    return "DONE";
  };

  const buttonAction = async (action) => {
    if (action === "newAct") {
      await addAccount();
    } else if (action === "editAct") {
      setOpen(true);
    }
  };

  const getData = async () => {
    try {
      let json = {
        action: "get",
        area: "account",
      };
      const awsURL = process.env.REACT_APP_URL_SETTINGS;
      const response = await RunAPI(json, awsURL);
      console.log("RESPONSE: ", response);
      let bb = response["data"].map((item) => {
        let dd = item.last_update;
        let ee = dd.split("T");
        let ff = ee[0];
        let gg = ff.split("-");
        let hh = gg[1] + "/" + gg[2] + "/" + gg[0];

        item.name = item.act_ID;
        item.last_update_f = hh;
        return item;
      });
      //
      setData(bb);
      // console.log('THIS IS RESPONSE NEW');
      // console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const addAccount = async () => {
    try {
      let json = {
        action: "add",
        area: "account",
      };
      const awsURL = process.env.REACT_APP_URL_SETTINGS;
      const response = await RunAPI(json, awsURL);
      //   console.log('THIS IS RESPONSE NEW', response['data']);
      const formCalc = (item) => {
        if (item.act_type == 5) {
          return item.calculation;
        } else if (item.act_type == 6) {
          return item.formula;
        } else {
          return " ";
        }
      };

      let bb = response["data"].map((item) => {
        item.id = item.act_id;
        item.calcAndFormula = formCalc(item);
        item.group = actGroup[item.act_group];
        item.style = indentLevel[item.act_indent];
        return item;
      });

      setData(bb);
      // console.log('THIS IS RESPONSE NEW');
      // console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const addAccountsBulk = async (data) => {
    try {
      let json = {
        action: "bulk_add",
        area: "account",
        updateRow: data,
      };
      const awsURL = process.env.REACT_APP_URL_SETTINGS;
      const response = await RunAPI(json, awsURL);
      console.log("THIS IS RESPONSE NEW", response["data"]);
    } catch (error) {
      console.error(error);
    }
  };

  const dataSort = async () => {
    try {
      let json = {
        action: "sort",
        area: "account",
        updateRow: {
          fromIndex: current_position,
          toIndex: new_postion,
        },
      };
      const awsURL = process.env.REACT_APP_URL_SETTINGS;
      const response = await RunAPI(json, awsURL);
      //  console.log('THIS IS RESPONSE NEW', response['data']);
      const formCalc = (item) => {
        if (item.act_type == 5) {
          return item.calculation;
        } else if (item.act_type == 6) {
          return item.formula;
        } else {
          return " ";
        }
      };

      let bb = response["data"].map((item) => {
        item.id = item.act_id;
        item.calcAndFormula = formCalc(item);
        item.group = actGroup[item.act_group];
        item.style = indentLevel[item.act_indent];
        return item;
      });

      setData(bb);
    } catch (error) {
      console.error(error);
    }
  };

  const archiveAccount = async () => {};

  const _updateAccount = async (updateRow) => {
    // console.log('UPDATE ACCOUNT');
    // console.log('hhhh', updateRow);

    try {
      let json = {
        action: "update",
        area: "account",
        updateRow: updateRow,
      };
      const awsURL = process.env.REACT_APP_URL_SETTINGS;
      const response = await RunAPI(json, awsURL);
      //   console.log('THIS IS RESPONSE NEW', response['data']);

      const formCalc = (item) => {
        if (item.act_type == 5) {
          return item.calculation;
        } else if (item.act_type == 6) {
          return item.formula;
        } else {
          return " ";
        }
      };

      let bb = response["data"].map((item) => {
        item.id = item.act_id;
        item.calcAndFormula = formCalc(item);
        item.group = actGroup[item.act_group];
        item.style = indentLevel[item.act_indent];
        return item;
      });

      setData(bb);
    } catch (error) {
      console.error(error);
    }
  };

  const _convertFormula = (formula) => {
    let newFormula = formula.toLowerCase();
    data.forEach((item) => {
      // newFormula = newFormula.replace(item.act_name.toLowerCase(), item.act_id);
      newFormula = newFormula.replace(
        "[" + item.act_name.toLowerCase() + "]",
        "[" + item.act_id + "]"
      );
      newFormula = newFormula.replace(
        "[" + item.act_name.toLowerCase() + "]",
        "[" + item.act_id + "]"
      );
      newFormula = newFormula.replace(
        "[" + item.act_name.toLowerCase() + "]",
        "[" + item.act_id + "]"
      );
      newFormula = newFormula.replace(
        "[" + item.act_name.toLowerCase() + "]",
        "[" + item.act_id + "]"
      );
      newFormula = newFormula.replace(
        "[" + item.act_name.toLowerCase() + "]",
        "[" + item.act_id + "]"
      );
    });
    return newFormula;
  };

  const batchLoadAccounts = (data) => {};

  const actGroup = {
    1: "Revenue",
    2: "Costs",
    3: "Expense",
    4: "Headcount",
    5: "Asset",
    6: "Liability",
    7: "Owner Equity",
    8: "Positive is Favorable",
    9: "Negative is Favorable",
  };

  const actGroupLookup = {
    revenue: 1,
    costs: 2,
    expense: 3,
    headcount: 4,
    asset: 5,
    liability: 6,
    "owner equity": 7,
    "positive is favorable": 8,
    "negative is favorable": 9,
  };

  const indentLevel = {
    1: "No Indent",
    2: "Indent",
    3: "More Indent",
    4: "Most Indented",
  };

  const actTypeOptions = {
    1: "Amount",
    2: "Rate",
    3: "Head Count",
    4: "Global Rate ",
    5: "Driver",
    6: "Formula",
    7: "Balance Sheet",
    8: "Sub Total",
    9: "Grand Total",
  };

  const actTypeLookup = {
    "amount account": 1,
    "rate account": 2,
    "head count": 3,
    "global rate": 4,
    driver: 5,
    formula: 6,
    "balance sheet": 7,
    "sub total": 8,
    "grand total": 9,
  };

  const actTypeOptions2 = {
    1: "Amount Account - (for direct input of amounts)",
    2: "Rate Account (used for storing rates)",
    3: "Head Count (used for storing FTE Headcount)",
    4: "Global Rate - (rates by month for whole model)",
    5: "Calculed Account - (Amount Accounts * Rate Accounts",
    6: "Formula - (Organise Account Outline Rollups)",
    7: "Balance Account (used for storing Balances)",
  };

  return {
    data,
    setData,
    open,
    setOpen,
    selectedRow,
    setSelectedRow,
    rowData,
    setRowData,
    addAccount,
    getData,
    updateAccount,
    dataSort,
    archiveAccount,
    actGroup,
    indentLevel,
    actTypeOptions,
    actTypeOptions2,
    newFormula,
    setNewFormula,
    buttonAction,
    openLoad,
    setOpenLoad,
    batchLoadAccounts,
    actTypeLookup,
    actGroupLookup,
    addAccountsBulk,
    sortOpen,
    setSortOpen,
    current_position,
    setCurrent_position,
    new_postion,
    setNew_postion,
  };
};

export default useAccounts;
