import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
import * as XLSX from "xlsx";

const useUploadAccounts = () => {
  const [workbook, setWorkbook] = useState({});
  const [sheetName, setSheetName] = useState("");
  const [columns, setColumns] = useState([{}]);
  const [rows, setRows] = useState([{}]);
  const [returnData, setReturnData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [loadData, setLoadData] = useState(false);

  const { RunAPI } = UseRunAPI();

  const [titles, setTitles] = useState([
    "Pick a Dimension",
    "Step 1/6 Load Your Dimension Master File and Choose a Sheet",
    "Step 2/6 Map Your Sheet Columns",
    "Step 3/6 Your Sheet Data",
    "Step 4/6 Validate Your Sheet Data",
    "Step 5/6 Check For Errors",
    "Step 6/6 Update the Dimension",
  ]);

  // this will be data from redux

  const [dimensions, setDimensions] = useState([
    { name: "Dim1", value: "1" },
    { name: "Dim2", value: "2" },
  ]);

  const [currentDimension, setCurrentDimension] = useState(0);

  const [vloadColumns] = useState([
    { field: "line", title: "Line" },
    {
      field: "dim_code",
      title: "Dimension Code",
    },
    {
      field: "MSG_OVERALL",
      title: "Line Status",
    },
    {
      field: "msg",
      title: "Error Message",
    },
    {
      field: "dim_id",
      title: "Internal Id",
    },
    {
      field: "dim_desc1",
      title: "Description-1",
    },
    {
      field: "dim_desc2",
      title: "Description-2",
    },
    {
      field: "dim_desc3",
      title: "Description-3",
    },
  ]);

  const [loadColumns, setLoadColumns] = useState([
    {
      field: "",
      load_name: "line",
      title: "Line",
      required: false,
      similar: ["Line", "line", "LINE"],
    },
    {
      field: "",
      load_name: "dim_code",
      title: "Code",
      required: true,
      similar: [
        "code",
        "Code",
        "CODE",
        "name",
        "Name",
        "NAME",
        "Dimension Code",
      ],
    },
    {
      field: "",
      load_name: "desc1",
      title: "Description 1",
      required: false,
      similar: ["desc1", "Desc1", "DESC1", "Description 1", "Description-1"],
    },
    {
      field: "",
      load_name: "desc2",
      title: "Description 2",
      required: false,
      similar: ["desc2", "Desc2", "DESC2", "Description 2", "Description-2"],
    },
    {
      field: "",
      load_name: "desc3",
      title: "Description 3",
      required: false,
      similar: ["desc3", "Desc3", "DESC3", "Description 3", "Description-3"],
    },
    {
      field: "",
      load_name: "dim_id",
      title: "Id",
      required: true,
      similar: ["id", "Id", "ID", "Internal Storage  Id"],
    },
  ]);

  const accountDimAPI = async (data, action) => {
    try {
      setLoading(true);
      let json = {
        area: "dimension",
        action: action,
        currentDimension: currentDimension,
        updateRow: data,
      };

      const awsURL = process.env.REACT_APP_URL_SETTINGS;
      const response = await RunAPI(json, awsURL);
      console.log("response", response);

      setReturnData(response["data"]);

      console.log("RESPONSE BY ACCOUNT:", response);
      setLoading(false);
      return "OK";
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      return "ERROR";
    }
  };

  //================================================================================================
  //======================= Form stuff below =====================================
  //================================================================================================
  //================================================================================================

  const showFile = (e) => {
    setLoading(true);
    const f = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbookRaw = XLSX.read(bstr, { type: "binary" });
      setWorkbook(workbookRaw);
      setLoading(false);
    };

    reader.readAsBinaryString(f);
    //setLoading(false);
  };

  const makeLoadData = (action) => {
    const LData = [];
    rows.forEach((row) => {
      const newRow = {};
      loadColumns.forEach((col) => {
        newRow[col.load_name] = row[col.field];
      });
      LData.push(newRow);
    });
    setLoadData(LData);
    accountDimAPI(LData, action);
    return LData;
  };

  const _makeMatch = (cols) => {
    loadColumns.forEach((g, i) => {
      g.similar.forEach((t) => {
        const index = cols.findIndex((s) => s.field === t);
        if (index > -1) {
          console.log(cols[index].field);
          loadColumns[i].field = cols[index].field;
        }
      });
    });
  };

  const updateColumns = (newField, i) => {
    console.log(newField + " " + i);

    let cc = [...loadColumns];
    cc[i].field = newField;

    //setAllRequired(rr === 0);

    setLoadColumns(cc);
  };

  const selectSheet = (sheet) => {
    // choose sheet
    try {
      setSheetName(sheet);
      //
      var data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
        header: 1,
      });
      //

      var cc = data[0].map((x) => {
        let c = {};
        c.field = x;
        c.title = x;
        return c;
      });

      //console.log(data);
      //setSelectedSheet(data);
      setColumns(cc);

      _makeMatch(cc);

      let nRows = [];

      data.forEach((row, index) => {
        if (index > 0 && index < 1001) {
          let r = {};
          data[0].forEach((col, index) => {
            r[col] = row[index];
          });
          nRows.push(r);
        }
      });

      setRows(nRows);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    showFile,
    selectSheet,
    updateColumns,
    makeLoadData,
    loadColumns,
    loadData,
    columns,
    loading,
    sheetName,
    workbook,
    rows,
    returnData,
    vloadColumns,
    step,
    setStep,
    titles,
    dimensions,
    setCurrentDimension,
    currentDimension,
  };
};

export default useUploadAccounts;
