// find all the combinations of the array
// that sum to the checkNumber
const findCombinations = async (arr, sum) => {
  let combinations = [];
  let combination = [];
  let combinationItem = [];
  let combinationItems = [];

  let index = 0;
  let total = 0;

  findCombinationsRecursive(
    arr,
    sum,
    index,
    total,
    combination,
    combinations,
    combinationItem,
    combinationItems
  );
  return combinationItems;
};

const findCombinationsRecursive = (
  arr,
  sum,
  index,
  total,
  combination,
  combinations,
  combinationItem,
  combinationItems
) => {
  if (total === sum) {
    combinations.push(combination.slice());
    combinationItems.push(combinationItem.slice());
    return;
  }

  for (let i = index; i < arr.length; i++) {
    combination.push(arr[i].amount);
    combinationItem.push(arr[i].id);

    findCombinationsRecursive(
      arr,
      sum,
      i + 1,
      total + arr[i].amount,
      combination,
      combinations,
      combinationItem,
      combinationItems
    );
    combination.pop();
    combinationItem.pop();
  }
};

const findMatches = async (shortArray, checkNumber) => {
  // check if array is less than 25
  // make array of objects only need amount and id
  // find combinations and return choices
  if (shortArray.length < 25) {
    console.log("length of total array", shortArray.length);
    const array_nbrs = shortArray.map((x) => {
      return { id: x.id, amount: x.amount };
    });
    const sumAmount = array_nbrs.reduce((a, b) => a + b.amount, 0);
    console.log("SUM ARRAY:", sumAmount);
    console.log("array_nbrs", array_nbrs);
    return await findCombinations(array_nbrs, checkNumber);
  } else {
    return [[]];
  }
};

export default findMatches;
