import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material/";

import FavoriteIcon from "@mui/icons-material/Star";
import FavoriteBorderIcon from "@mui/icons-material/StarBorder";
import ShareIcon from "@mui/icons-material/Share";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useNavigate } from "react-router-dom";
import useReports from "src/views/reporting/useReports";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SharedReports from "src/views/reporting/sharedReports";
import ReportListings from "src/views/reporting/reportListing";
import AddSelect from "src/components/addSelector/addSelect";

const Index = () => {
  const navigate = useNavigate();

  const {
    delete_pov,
    favorite_pov,
    reportPick,
    reportTypeName,
    data,
    share_pov,
    update_group,
  } = useReports(navigate);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [groupList, setGroupList] = useState([]);
  const [sort, setSort] = useState("report_group");
  const [screenNbr, setScreenNbr] = useState(1);
  //const [groups, setGroups] = useState([]);

  useEffect(() => {
    let grp = [];
    let lastGrp = "";
    data.map((row) => {
      if (row[sort] !== lastGrp) {
        grp.push(row[sort]);
      }
      lastGrp = row[sort];
    });
    setGroupList(grp);
  }, [data, sort]);

  const handleDeletePOV = () => {
    delete_pov(selectedRow.report_id);
    setOpen(false);
  };

  const handleDeletePOVConfirm = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeView = (view) => {
    if (view === 1) {
      setScreenNbr(1);
      setSort("report_group");
    } else if (view === 2) {
      setScreenNbr(1);
      setSort("report_type");
    } else if (view === 3) {
      setScreenNbr(2);
      setSort(view);
    } else if (view === 4) {
      setScreenNbr(3);
      setSort(view);
    } else {
      setScreenNbr(1);
      setSort("report_group");
    }
  };

  const handleGroupChange = (row, value) => {
    console.log("handleGroupChange", value, row);
    update_group(row, value);
  };

  return (
    <PerfectScrollbar>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Delete {selectedRow.rep_name} Report.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleDeletePOV()} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          <div flex>
            <Button
              sx={{ m: 2 }}
              variant={
                sort === "report_group" && screenNbr === 1
                  ? "contained"
                  : "outlined"
              }
              onClick={() => handleChangeView(1)}
            >
              My Reports by Group
            </Button>
            <Button
              sx={{ m: 2 }}
              variant={
                sort === "report_type" && screenNbr === 1
                  ? "contained"
                  : "outlined"
              }
              onClick={() => handleChangeView(2)}
            >
              My Reports By Type
            </Button>
            <Button
              sx={{ m: 2 }}
              variant={screenNbr === 2 ? "contained" : "outlined"}
              onClick={() => handleChangeView(3)}
            >
              Standard Reports
            </Button>
            <Button
              sx={{ m: 2 }}
              variant={screenNbr === 3 ? "contained" : "outlined"}
              onClick={() => handleChangeView(4)}
            >
              All Shared Reports
            </Button>
          </div>
          {screenNbr === 1 && (
            <div>
              {groupList.map((grp, i) => (
                <Card>
                  <CardHeader
                    title={sort === "report_type" ? reportTypeName(grp) : grp}
                    size="small"
                    style={{
                      marginTop: "1px",
                      paddingVertical: "0px",
                    }}
                  />
                  <CardContent>
                    <TableContainer>
                      <Table
                        stickyHeader
                        aria-label="simple table"
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "300px" }}>Name</TableCell>
                            <TableCell sx={{ width: "300px" }}>Group</TableCell>
                            <TableCell sx={{ width: "100px" }} align="left">
                              My Home Page
                            </TableCell>
                            <TableCell sx={{ width: "100px" }}>Share</TableCell>
                            <TableCell sx={{ width: "100px" }}>
                              Remove
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {data
                            .filter((fitem) => fitem[sort] === grp)
                            .map((row, i) => (
                              <TableRow key={"KEYBB" + i} hover={true}>
                                <TableCell
                                  sx={{ width: "300px" }}
                                  onClick={() => reportPick(row)}
                                >
                                  {row.rep_name}
                                </TableCell>

                                <TableCell sx={{ width: "200px" }}>
                                  <AddSelect
                                    value={row.report_group}
                                    options={groupList}
                                    callBackup={(value) =>
                                      handleGroupChange(row, value)
                                    }
                                  />
                                </TableCell>

                                <TableCell sx={{ width: "100px" }}>
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    aria-label="add to shopping cartj"
                                    onClick={() => favorite_pov(row)}
                                  >
                                    {row.favorite === 1 ? (
                                      <FavoriteIcon />
                                    ) : (
                                      <FavoriteBorderIcon />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell sx={{ width: "100px" }}>
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    aria-label="add to shopping cartj"
                                    onClick={() => share_pov(row)}
                                  >
                                    {row.share_report === 1 ? (
                                      <ShareIcon />
                                    ) : (
                                      <ShareIcon sx={{ opacity: "0.3" }} />
                                    )}
                                  </IconButton>
                                </TableCell>

                                <TableCell sx={{ width: "100px" }}>
                                  <IconButton
                                    sx={{ w: 4 }}
                                    size="small"
                                    color="primary"
                                    aria-label="add to shopping cartj"
                                    onClick={() => handleDeletePOVConfirm(row)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
          {screenNbr === 2 && (
            <div>
              <ReportListings />
            </div>
          )}
          {screenNbr === 3 && (
            <div>
              <SharedReports />
            </div>
          )}
        </div>
      </div>
    </PerfectScrollbar>
  );
};

export default Index;
