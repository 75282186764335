import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Typography from "@mui/material/Typography";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Items = ({ item, handleStaticCheckChange, index }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <ListItem
          sx={{
            rm: 20,
            width: 280,
            height: 37,
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DragIndicatorIcon />
          <Checkbox
            checked={item.picked}
            onChange={(e) => handleStaticCheckChange(e, item.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography variant="body2" color="text.secondary">
            {item.value}
          </Typography>
        </ListItem>
      )}
    </Draggable>
  );
};

const ColumPickerDrag = ({ handleDragEnd, handleStaticCheckChange, items }) => {
  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: "240px",
          border: "solid 1px #e0e0e0",
          bgcolor: "background.paper",
        }}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <Items
                    key={item.key}
                    item={item}
                    index={index}
                    handleStaticCheckChange={handleStaticCheckChange}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </>
  );
};

export default ColumPickerDrag;
