import { useState } from "react";
import UseRunAPI from "src/components/UseRunAPI";
import * as XLSX from "xlsx";

const useUploadAccounts = () => {
  const [workbook, setWorkbook] = useState({});
  const [sheetName, setSheetName] = useState("");
  const [columns, setColumns] = useState([{}]);
  const [rows, setRows] = useState([{}]);
  const [returnData, setReturnData] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [loadData, setLoadData] = useState(false);

  const { RunAPI } = UseRunAPI();

  const [titles, setTitles] = useState([
    "Step 1/6 Load Your Account Master File and Choose Sheet",
    "Step 2/6 Map Your Sheet Columns",
    "Step 3/6 Your Sheet Data",
    "Step 4/6 Validate Your Sheet Data",
    "Step 5/6 Check For Errors",
    "Step 6/6 Update the Account Dimension",
  ]);

  const [vloadColumns] = useState([
    { field: "line", title: "Line" },
    {
      field: "act_name",
      title: "act_name",
    },
    {
      field: "msg_status",
      title: "msg_status",
    },
    {
      field: "msg",
      title: "msg",
    },
    {
      field: "act_id",
      title: "act_id",
    },
    {
      field: "act_number",
      title: "act_number",
    },
    {
      field: "formula",
      title: "formula",
    },
  ]);

  const [loadColumns, setLoadColumns] = useState([
    {
      field: "",
      load_name: "line",
      title: "Line",
      required: false,
      similar: ["Line", "line", "LINE"],
    },
    {
      field: "",
      load_name: "act_name",
      title: "Account Name",
      required: true,
      similar: ["Account Name"],
    },
    {
      field: "",
      load_name: "act_number",
      title: "Account Number",
      required: true,
      similar: ["Account Number"],
    },

    {
      field: "",
      load_name: "act_type_desc",
      title: "Account Type",
      required: true,
      similar: ["Account Type"],
    },
    {
      field: "",
      load_name: "formula",
      title: "Formula",
      required: false,
      similar: ["formula", "Formula"],
    },
    {
      field: "",
      load_name: "act_id",
      title: "Acount Id",
      required: false,
      similar: ["Account Id", "act_id", "Internal Id", "Internal ID"],
    },
  ]);

  const accountDimAPI = async (data, action) => {
    setLoading(true);
    let json = {
      area: "account",
      action: action,
      updateRow: data,
    };

    const awsURL = process.env.REACT_APP_URL_SETTINGS;
    const response = await RunAPI(json, awsURL);

    const nData = response["data"].map((item) => {
      let nItem = {};
      nItem.line = item.line;
      nItem.name = item.act_id;
      nItem.act_id = item.act_id;
      nItem.act_name = item.act_name;
      nItem.act_number = item.act_number;
      nItem.msg = item.MSG;
      nItem.msg_status = item.MSG_OVERALL;
      nItem.formula = item.formula;

      return nItem;
    });

    setReturnData(nData);

    console.log("RESPONSE BY ACCOUNT:", response);
    setLoading(false);
    return "OK";
  };

  //================================================================================================
  //======================= Form stuff below =====================================
  //================================================================================================
  //================================================================================================

  const showFile = (e) => {
    setLoading(true);
    const f = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbookRaw = XLSX.read(bstr, { type: "binary" });
      setWorkbook(workbookRaw);
      setLoading(false);
    };

    reader.readAsBinaryString(f);
    //setLoading(false);
  };

  const makeLoadData = (action) => {
    const LData = [];
    rows.forEach((row) => {
      const newRow = {};
      loadColumns.forEach((col) => {
        newRow[col.load_name] = row[col.field];
      });
      LData.push(newRow);
    });
    setLoadData(LData);
    accountDimAPI(LData, action);
    return LData;
  };

  const _makeMatch = (cols) => {
    loadColumns.forEach((g, i) => {
      g.similar.forEach((t) => {
        const index = cols.findIndex((s) => s.field === t);
        if (index > -1) {
          console.log(cols[index].field);
          loadColumns[i].field = cols[index].field;
        }
      });
    });
  };

  const updateColumns = (newField, i) => {
    console.log(newField + " " + i);

    let cc = [...loadColumns];
    cc[i].field = newField;

    //setAllRequired(rr === 0);

    setLoadColumns(cc);
  };

  const selectSheet = (sheet) => {
    // choose sheet
    try {
      setSheetName(sheet);
      //
      var data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
        header: 1,
      });
      //

      var cc = data[0].map((x) => {
        let c = {};
        c.field = x;
        c.title = x;
        return c;
      });

      //console.log(data);
      //setSelectedSheet(data);
      setColumns(cc);

      _makeMatch(cc);

      let nRows = [];

      data.forEach((row, index) => {
        if (index > 0 && index < 400) {
          let r = {};
          data[0].forEach((col, index) => {
            r[col] = row[index];
          });
          nRows.push(r);
        }
      });

      setRows(nRows);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    showFile,
    selectSheet,
    updateColumns,
    makeLoadData,
    loadColumns,
    loadData,
    columns,
    loading,
    sheetName,
    workbook,
    rows,
    returnData,
    vloadColumns,
    step,
    setStep,
    titles,
  };
};

export default useUploadAccounts;
