import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";

import SimpleSelectTable from "src/components/tables/simpleSelectTable/SimpleSelectTable";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { title, onClose } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {title}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DimensionPicker = ({ dimNbr = 1 }) => {
  const { currentItem, ws_dim, ws_settings } = useSelector((state) => state);
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([{}]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [label, setLabel] = useState("");
  const [currentDimName, setCurrentDimName] = useState("dim1");

  const dispatch = useDispatch();

  //const { inputActs } = userData.user;

  const [cDimNames] = useState({ 1: "dim1", 2: "dim2", 3: "dim3", 4: "dim4" });

  useEffect(() => {
    setCurrentDimName(cDimNames[dimNbr]);
    console.log("dimNbr", dimNbr);
    console.log("currentDimName", currentDimName);

    let cc = [{}];
    //if (dimNbr === 1) {
    cc = ws_dim[dimNbr].map((item) => {
      item.name = item.id;
      return item;
    });
    //}
    setRows(cc);
  }, [
    dimNbr,
    currentItem.dim1,
    currentItem.dim2,
    currentItem.dim3,
    currentItem.dim4,
  ]);

  //---------------------------------

  const [columns] = useState([
    {
      field: "dim_code",
      numeric: false,
      disablePadding: true,
      title: ws_settings.dim[dimNbr] + " Code",
    },
    {
      field: "attr_1",
      numeric: false,
      disablePadding: false,
      title: ws_settings.dim[dimNbr] + " Name",
    },
    {
      field: "attr_2",
      numeric: false,
      disablePadding: false,
      title: ws_settings.dim[dimNbr] + " Group",
    },

    {
      field: "dim_id",
      numeric: false,
      disablePadding: true,
      title: "ID",
    },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    dispatch(
      {
        type: "UPDATE_CURRENT_DIMENSION",
        payload: { value: selectionModel, dimNbr: dimNbr },
      },
      []
    );
    setOpen(false);
  };

  const handleClear = () => {
    dispatch(
      {
        type: "UPDATE_CURRENT_DIMENSION",
        payload: { value: [], dimNbr: dimNbr },
      },
      []
    );
  };

  const handleDataCallback = (data) => {
    console.log("handlePicked", data);
    setSelectionModel(data);
  };

  useEffect(() => {
    let dimValue = [];
    dimValue = currentItem[cDimNames[dimNbr]];
    console.log("dimValue", cDimNames[dimNbr], dimValue);
    //
    let bb = {};
    if (dimValue.length === 1) {
      bb = ws_dim[dimNbr].find((item) => item.id === dimValue[0]);
      setLabel(ws_settings.dim[dimNbr] + " = " + bb.dim_code);
    } else if (dimValue.length > 1) {
      setLabel(dimValue.length + " " + ws_settings.dim[dimNbr] + " Selected");
    } else {
      setLabel("All " + ws_settings.dim[dimNbr] + " Included");
    }
  }, [currentItem[currentDimName]]);

  return (
    <>
      <Chip
        sx={{ mr: 2 }}
        variant={currentItem[currentDimName].length > 0 ? "filled" : "outlined"}
        label={label}
        color="primary"
        onDelete={handleClear}
        onClick={handleClickOpen}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          title={ws_settings.dim[dimNbr]}
        ></BootstrapDialogTitle>
        <DialogContent dividers>
          <SimpleSelectTable
            title={ws_settings.dim[dimNbr]}
            rows={rows}
            columns={columns}
            dataCallback={handleDataCallback}
            selectionModel={currentItem[currentDimName]}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default DimensionPicker;
