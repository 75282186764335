import { Button } from "@mui/material";
import { useEffect } from "react";

const DashboardReportPicker = ({
  userReports,
  reportPick,
  reportPickStd,
  currentReportId,
}) => {
  useEffect(() => {
    if (userReports.filter((dd) => dd.favorite === 1).length > 0) {
      reportPick(userReports.filter((dd) => dd.favorite === 1)[0]);
    } else {
      reportPickStd({ report_type: 1 });
    }
  }, [userReports]);

  return (
    <>
      {userReports
        .filter((dd) => dd.favorite === 1)
        .map((item) => (
          <Button
            key={item.rep_name}
            sx={{ m: 2, pl: 3, pr: 3 }}
            variant={
              currentReportId === item.report_id ? "contained" : "outlined"
            }
            onClick={() => reportPick(item)}
          >
            {item.rep_name}
          </Button>
        ))}
    </>
  );
};

export default DashboardReportPicker;
